import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";
import ImageUrls from '../../../../sharedFiles/imageUrls';

import EventComponent from '../../../../sharedComponents/eventcomponent/eventComponent';
import EventComponentStore from '../../../../sharedComponents/eventcomponent/eventComponentStore';
import { liveMatchRefreshTime } from '../../../../sharedFiles/helper';

const eventComponentStore = new EventComponentStore();

class Highlights extends Component {
	constructor(props) {
		super(props);
	}
	componentWillMount() {
		this.props.highlightsStore.pageId = this.props.pageId;
		this.props.highlightsStore.selectedSport = "";
		this.props.highlightsStore.getHighlightsSports();
		this.props.highlightsStore.mounted = true;
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
			this.props.highlightsStore.getHighlightsSports();
		}
	}
	componentDidMount() {
		var self = this;
		setTimeout(function reCallService() {
			if (self.props.highlightsStore.mounted) {
				self.props.highlightsStore.getHighlightsSports();
				setTimeout(reCallService, liveMatchRefreshTime);
			}
		}, liveMatchRefreshTime);
	}
	componentWillUnmount() {
		this.props.highlightsStore.mounted = false;
		this.props.highlightsStore.resetData();

	}

	render() {
		const { appStore, highlightsStore, betSlipStatusChange, componentType } = this.props;
		return (
			<div className="RDE-upcomingHighlights-content">
				<div className="RDE-highlightsSection">
					<div className="RDE-nav-pills mt-5 mb-5">
						<ul className="RDE-nav-pills-menu">
							{
								highlightsStore.sports.map((sport, index) => {
									return (
										<li key={"high_sport_" + index} onClick={highlightsStore.selectSport.bind(highlightsStore, sport)}>
											<div className={highlightsStore.selectedSport.id == sport.id ? "RDE-nav-pills-menu-item active" : "RDE-nav-pills-menu-item"}>
												{sport.name}
											</div>
										</li>
									)
								})}
						</ul>
					</div>
				</div>
				<div className="RDE-eventItemGroup">
					<div className="RDE-accordion-group">
						{
							!highlightsStore.showLoader && highlightsStore.highlightsData.map(function (event, eventIndex) {
								if (eventIndex < highlightsStore.itemsShow) {
									return (
									<Route key={"highlight_sport_" + eventIndex} render={(props) => <EventComponent {...props}
										appStore={appStore}
										event={event}
										eventIndex={eventIndex}
										highlightsStore={highlightsStore}
										eventComponentStore={eventComponentStore}
										componentType={componentType}
										sportId={highlightsStore.selectedSport.id}
										sportName={highlightsStore.selectedSport.name}
										betSlipStatusChange={betSlipStatusChange} />} />)
								}
							})
						}
					</div>
				</div>
				{highlightsStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
					Currently data is not available for this Sport
				</div> : ''}
				{highlightsStore.showLoader ? <div className="RDE-loader">
					<img src={ImageUrls.loaderIcon} alt="loading...!" />
				</div> : ''}

				{highlightsStore.highlightsData.length > highlightsStore.initialItemShow ? <div className="RDE-showMore">
					{highlightsStore.itemsShow < highlightsStore.highlightsData.length ? <div onClick={highlightsStore.showMore.bind(highlightsStore)}><span>Show more </span><i className="RDE-icon-down-arrow"></i></div> :
						<div onClick={highlightsStore.showLess.bind(highlightsStore)}><span>Show less </span><i className="RDE-icon-up-arrow"></i></div>}
				</div> : ''}
			</div>
		);
	}
}

export default observer(Highlights);
