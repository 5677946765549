import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppStore from './AppStore';
import * as serviceWorker from './serviceWorker';
import { Router, Route } from 'react-router-dom'
import history from './history'

const appStoreObj = new AppStore();
ReactDOM.render(
<Router history={history}>
	<Route render={(props)=><App {...props} appStore={appStoreObj}/>}/>
</Router>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
