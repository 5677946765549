import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { upcomingHighlights } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';
import { selectSelection } from '../../../../sharedFiles/helper';
import { getTimeZoneOffset } from '../../../../sharedFiles/helper';

class UpcomingStore {
    constructor() {
        extendObservable(this, {
            upcomingData: [],
            upcomingSports: [],
            eventData: [],
            appStore: {},
            selectedSport: "",
            selectedOption: null,
            showErrorMsg: false,
            showLoader: false,
            selections: [],
            itemsPerClick: 20,
            itemsShow: 20,
            initialItemShow: 20,
            isInitialLoad: true,
            mounted: true,
        })
    }

    /**
     * This method is called from component will mount when component is loaded,
     * in this method we will get the banners data.
     */
    getUpcomingEventData() {
      
        if ( this.selectedSport?.id && this.selectedSport.id !== null
            && this.selectedOption && this.selectedOption !== null) {
            this.showErrorMsg = false;
            this.showLoader = true;
            if (this.isInitialLoad) {
                this.showLoader = true;
                this.isInitialLoad = false;
            }
            var token = "";
            var cookeTocken = cookie.load('UTocken')
            if (cookeTocken && cookeTocken.length > 0) {
                token = cookeTocken;
            }
            apiCaller(upcomingHighlights.getUpcomingEvents + "?sportId=" + this.selectedSport.id + "&optionId=" + this.selectedOption + "&timeInterval=" + getTimeZoneOffset() + "&userToken=" + token, 'GET').then(response => {
                this.showLoader = false;
                if (response?.data?.sports?.length > 0) {
                    this.eventData = response.data.sports[0].events;
                    this.selections = selectSelection(this.eventData[0]);
                } else {
                    this.showErrorMsg = true;
                    this.eventData = [];
                }
            });
        } else {
            this.showErrorMsg = true
            this.showLoader = false;
            this.eventData = [];
        }

    }
    /**
    * This method is called when user clicks on sport in upcoming.
    * @param sport-{object} contains the sport information on which user clicks.
    */
    selectSport(sport) {
        if (sport && sport.hasOwnProperty('name')) {
            this.selectedSport = sport;
            this.showLoader = true;
            this.getUpcomingEventData();
        }
    }
    /**
    * This method is called when user clicks on sport in upcoming.
    * @param sport-{object} contains the sport information on which user clicks.
    */
    selectOption(optionId) {
        this.selectedOption = optionId;
        this.showLoader = true;
        this.getUpcomingEventData();
    }
    /**
    * This method is used to show more events
    */
    showMore() {
        this.itemsShow += this.itemsPerClick;
    }
    /**
    * This method is used to show less events
    */
    showLess() {
        this.itemsShow = this.initialItemShow;
    }
}

export default UpcomingStore;