import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

class Banner extends Component {
    componentWillMount() {
        this.props.bannerStore.pageId = this.props.pageId;
        this.props.bannerStore.getBannerData();
        this.props.bannerStore.appStore = this.props.appStore;
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.isLoggedIn != nextProps.isLoggedIn) {
            this.props.bannerStore.getBannerData();
      }
    }
    bannerButtonClick(button) {
        if (button.buttonText === "Tennis") {
            this.props.history.push('/sports/tennis/2')
        } else if (button.buttonText === "Football") {
            this.props.history.push('/sports/football/1')
        } else if (button.buttonText === "Horse Racing") {
            // this.props.history.push('/sports/cricket/4')
        }
    }
    render() {
        const { bannerStore, appStore } = this.props;
        const handleOnDragStart = e => e.preventDefault()
        const self = this;
       // console.log("hdfkdfjkdfh", bannerStore.bannerData);
        let bannerData = bannerStore.bannerData;
        return (
            <>
                <div className="RDE-home-alice-carousel">
                    {bannerStore.bannerData && bannerStore.bannerData.length > 0 ? <AliceCarousel
                        buttonsDisabled={true}
                        dotsDisabled={false}
                        autoPlay={true}
                        autoPlayInterval={20000}
                        stopAutoPlayOnHover={true}
                        mouseDragEnabled={true}
                        preventEventOnTouchMove={true}
                        onDragStart={handleOnDragStart}>
                        {bannerStore.bannerData.map(function (item, index) {
                            return (
                                <div className="RDE-mainBanner" onClick={bannerStore.clickBanner.bind(bannerStore)} key={"bannerItem_" + index} >
                                    <div className="RDE-mainBanner__content">
                                            {/* <div className="RDE-mainBanner__innerTxt">
                                                <div className="">{item.text}</div>
                                            </div> */}
                                               <img src={item.imageUrl} alt={"bannerItem_" + index} />
                                            {item.isButton && item.buttonType === 1 ? <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={appStore.showSignup.bind(appStore)}><span className="RDE-skewTxt">{item.buttonText}</span></button> :
                                                <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={self.bannerButtonClick.bind(self, item)}><span className="RDE-skewTxt">{item.buttonText}</span></button>}
                                        
                                    </div>
                                </div>
                            )
                        })}
                    </AliceCarousel> : ''}
                </div>
                <div className="RDE-mainHeader__marquee">
                     <marquee>Welcome to Xchange </marquee> 
                </div>
            </>
        )
    }
}
export default observer(Banner);
