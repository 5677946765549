
var imageURLs = {
    bannerBG : require('../assets/images/banner-bg.jpg'),
    checkBoxMark : require('../assets/images/banner-bg.jpg'),
    eventViewBannerBG : require('../assets/images/eventview-banner-bg.jpg'), 
    fancyIcon :  require('../assets/images/fancy.svg'),
    loaderIcon :  require('../assets/images/loader.svg'),
    loginBG : require('../assets/images/login-bg.jpg'),
    loginSignupClose : require('../assets/images/loginSignUp-close.svg'),
    poweredByFooterLogo : require('../assets/images/ne-logo.svg'),
    // rdLogo: require('../assets/images/RD-Exchange-logo.png'),
    rdLogo: require('../assets/images/fairbet-logo.svg'),
    teamLogoDefault : require('../assets/images/team-logo-default.png'),
    liveIcon: require('../assets/images/live-icon.svg'),
    // americanExchlogo: require('../assets/images/american-exchange-logo.svg'),
    gameXlogo: require('../assets/images/gamexyellow.svg'),
    xchangeLogo:require('../assets/images/xchange-logo.svg'),
}
  
export default imageURLs;
  