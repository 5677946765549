import { extendObservable } from 'mobx';
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";

class LeftMenuStore {
    constructor() {
        extendObservable(this, {
            sportsInfo: [],
            competitionsInfo: [],
            eventsInfo: [],
            sportAccordionIndex: [],
            compAccordionIndex: [],
        });
    }

    /**
     * This Method Can be Used to get the Sports Information.
    */
    getSportsInfo() {
        this.sportAccordionIndex = [];
        this.compAccordionIndex = [];
        apiCaller(masterPage.getLeftMenu).then(info => {
            if (info.data && info.data.hasOwnProperty("categories") && Array.isArray(info.data.categories) &&
                info.data.categories[0].hasOwnProperty("Sport") && Array.isArray(info.data.categories[0].Sport)) {
                this.sportsInfo = info.data.categories[0].Sport;
                this.getCompetitionsInfo(info.data.categories[0].Sport[0].id);
            }
        });
    }

    /**
     * This Method Can be Used to get the Competitions Information.
    */
    getCompetitionsInfo(sportId) {
        this.competitionsInfo = [];
        apiCaller(masterPage.getCompetitions + "?sportId=" + sportId).then(compInfo => {
            if (compInfo.data && compInfo.data.hasOwnProperty("leagues") && Array.isArray(compInfo.data.leagues))
                this.competitionsInfo = compInfo.data.leagues;
            this.getEventsInfo(compInfo.data.leagues[0].leagueId)
        });
    }

    /**
     * This Method Can be Used to get the Events Information.
    */
    getEventsInfo(leagueId) {
        this.eventsInfo = [];
        apiCaller(masterPage.getEvents + "?leagueId=" + leagueId).then(eventsResp => {
            if (eventsResp.data && eventsResp.data.hasOwnProperty("events") && Array.isArray(eventsResp.data.events)) {
                this.eventsInfo = eventsResp.data.events;
            }
        });
    }

    /**
      * this method is used to open the accordion.
      * @param {Number} index- contains index to open the accordion.
    */
    openAccordion(index, type, id) {
        if (type === "sport") {
            if (index >= 0) {
                var sportIndex = this.sportAccordionIndex.indexOf(index);
                if (sportIndex === -1) {
                    this.sportAccordionIndex = [];
                    this.sportAccordionIndex.push(index);
                    this.competitionsInfo = [];
                    this.getCompetitionsInfo(id);
                } else {
                    this.sportAccordionIndex.splice(sportIndex);
                }
            }
        }
        else if (type === "comp") {
            if (index >= 0) {
                var compIndex = this.compAccordionIndex.indexOf(index);
                if (compIndex === -1) {
                    this.compAccordionIndex = [];
                    this.compAccordionIndex.push(index);
                    this.eventsInfo = [];
                    this.getEventsInfo(id);
                } else {
                    this.compAccordionIndex.splice(compIndex);
                }
            }
        }
    }
}
export default LeftMenuStore;