import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';

class BalanceOverviewStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showErrorMsg:false,
      showLoader:false,
      itemsPerPage: 10,
      totalPages: 0,
      pageNumber: 0,
      accordionOpenIndex:[]
    });
  }
  /**
  * This method is used to get the balance overview data
  *
  */
  getBalanceOverview(){
    this.showErrorMsg = false;
    this.showLoader = true;
  	var token="";
  	var cookeTocken = cookie.load('UTocken');
  	if(cookeTocken && cookeTocken.length>0){
  	  token = cookeTocken;
  	}
  	apiCaller(profile.betBalanceOverview+"?userToken="+token+"&transactionType=bo&pageNumber="+this.pageNumber+"&pageSize="+this.itemsPerPage,'GET').then(response => {
      this.showLoader = false;
  	  if(response && response.data && response.data.hasOwnProperty('userTransList') &&
  	     response.data.userTransList && response.data.userTransList.length>0){
          this.totalPages = Math.ceil(response.data.userTransactionsCount/this.itemsPerPage);
  	      this.data = response.data.userTransList;
          var accInd=[]
          for(var i =0;i< this.data.length;i++){
            accInd.push(i)
          }
          this.accordionOpenIndex = accInd;
  	  }else{
        this.showErrorMsg = true;
      }
    });
  }
  handlePageChange(pager) {
    //debugger;
    //alert(pageNumber);
    this.accordionOpenIndex = [0];
    this.pageNumber = pager.selected;
    this.getBalanceOverview();
  }
}

export default BalanceOverviewStore;
