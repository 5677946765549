import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatDate } from '../../sharedFiles/helper';

class ScoreCard extends Component {
    componentDidMount() {
        const { sportId, eventId } = this.props;
        this.props.scoreCardStore.getScores(sportId, eventId);
    }
    componentWillUnmount() {
        if (this.props.scoreCardStore.scoreInterval) {
            clearInterval(this.props.scoreCardStore.scoreInterval);
        }
    }
    render() {
        const { scoreCardStore, sportId, eventId, eventInfo } = this.props;
        const { scoreData, showLoader } = this.props.scoreCardStore;
        return (
            <>
                {sportId === 4 && <div class="grid-middle-noGutter w-100 ">
                    <div class="col-6_xs-12">
                        {!showLoader ? (scoreData && scoreData.score ? <div class="RDE-eventView-scoreBoard-matchBox">
                            <div class="RDE-eventView-scoreBoard-matchBox-teamNames__header">
                                <div class="grid-middle-noGutter">
                                    <div class="col-6">
                                        <div class="RDE-eveneView-teamNames">
                                            <div class="">{scoreCardStore.scoreData.team1} *</div>
                                            <div class="RDE-eventView-scoreBoard-liveScore">{scoreCardStore.scoreData.score}/{scoreCardStore.scoreData.wicket} <span className="RDE-eventView-scoreBoard-overs">({scoreCardStore.scoreData.overs1})</span></div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="RDE-eveneView-teamNames RDE-eventView-teamNams-right">
                                            <div class="">{scoreCardStore.scoreData.team2}</div>
                                            <div class="RDE-eventView-scoreBoard-liveScore">{scoreCardStore.scoreData.score2}/{scoreCardStore.scoreData.wicket2} <span className="RDE-eventView-scoreBoard-overs">({scoreCardStore.scoreData.overs2})</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="RDE-eventView-RTB text-center">{scoreCardStore.scoreData.comment}</div>
                            <table class="table RDE-cricketTable">
                                <thead>
                                    <tr>
                                        <th width="50%" class="text-left">BATSMEN</th>
                                        <th>R</th>
                                        <th>B</th>
                                        <th>4s</th>
                                        <th>6s</th>
                                        <th>SR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{scoreCardStore.scoreData.p1} {scoreCardStore.scoreData.ap == "p1" && "*"}</td>
                                        <td>{scoreCardStore.scoreData.p1r}</td>
                                        <td>{scoreCardStore.scoreData.p1b}</td>
                                        <td>{scoreCardStore.scoreData.p14}</td>
                                        <td>{scoreCardStore.scoreData.p16}</td>
                                        <td>{((scoreCardStore.scoreData.p1r / scoreCardStore.scoreData.p1b) * 100).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>{scoreCardStore.scoreData.p2} {scoreCardStore.scoreData.ap == "p2" && "*"}</td>
                                        <td>{scoreCardStore.scoreData.p2r}</td>
                                        <td>{scoreCardStore.scoreData.p2b}</td>
                                        <td>{scoreCardStore.scoreData.p24}</td>
                                        <td>{scoreCardStore.scoreData.p26}</td>
                                        <td>{((scoreCardStore.scoreData.p2r / scoreCardStore.scoreData.p2b) * 100).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="RDE-eventView-bowlerInfo">
                                <div className="RDE-bowlerInfo">BOWLER: <span>{scoreCardStore.scoreData.b}</span></div>
                                <div className="RDE-bowlerInfo">LAST WKT: <span>{scoreCardStore.scoreData.lw == "" ? "N0 Wkt yet" : scoreCardStore.scoreData.lw}</span></div>
                            </div>
                            <div class="RDE-eventView-scoreBoard-matchBox__oversContent">
                                <div class="RDE-eventView-scoreBoard-matchBox__oversInfo">
                                    <div class="grid-noGutter">
                                    <div className="col-6 text-right pr-5">
                                        Current Over:
                                      </div>
                                        <div class="col-6">
                                            <ul className="RDE-eventView-oversBox">
                                                {
                                                    scoreCardStore.scoreData && scoreCardStore.scoreData.lastOver &&
                                                    scoreCardStore.scoreData.lastOver.map(function (ballScore, index) {
                                                        return <li className="RDE-eventView-oversBox__bg">{ballScore === '.' ? 0 : ballScore}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {/* <div class="col-6 text-right">
                                            <div class="RDE-eventView-runRate">Current RR:<span class="RDE-eventView-runRate-count"></span></div>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-matchBox__last3Overs__info text-center">
                                    <label class="RDE-eventView-last3Overs__text">Previous Overs:</label>
                                    {
                                        scoreCardStore.scoreData && scoreCardStore.scoreData.previousOvers &&
                                        scoreCardStore.scoreData.previousOvers.map(function (over, index) {
                                            return <ul className="RDE-eventView-last3Overs__score">
                                                {over.map(function (score) {
                                                    return <li className="score">{score === '.' ? 0 : score}</li>
                                                })}
                                            </ul>
                                        })
                                    }
                                    {/* <ul class="RDE-eventView-last3Overs__score">
                                        <li class="score">4</li>
                                        <li class="score">3</li>
                                        <li class="score">2</li>
                                        <li class="score">1</li>
                                        <li class="score">0</li>
                                        <li class="score">1</li>
                                    </ul>
                                    <ul class="RDE-eventView-last3Overs__score">
                                        <li class="score">4</li>
                                        <li class="score">3</li>
                                        <li class="score">2</li>
                                        <li class="score">1</li>
                                        <li class="score">0</li>
                                        <li class="score RDE-red">W</li>
                                    </ul>
                                    <ul class="RDE-eventView-last3Overs__score">
                                        <li class="score">WD</li>
                                        <li class="score">3</li>
                                        <li class="score">2</li>
                                        <li class="score">1</li>
                                        <li class="score">0</li>
                                        <li class="score">1</li>
                                    </ul> */}

                                </div>
                            </div>
                        </div>
                            : <div class="RDE-eventView-scoreBoard RDE-scoreCard-noData">
                                <div class="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.homeTeamName}</div>
                                    </div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-middle">
                                    <div class="RDE-eventView-kickOff">{formatDate(eventInfo.kickOffTime)}</div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-right RDE-awayTeam">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.awayTeamName}</div>
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                    <div class="col-6_xs-12">
                        <div className="RDE-iframeVideo">
                        <iframe allowfullscreen="" frameborder="0" height="236" marginwidth="0" mozallowfullscreen="" scrolling="no" webkitallowfullscreen="" width="100%"
                            src={"https://jeoad.com/santosh/graphics.php?ppk=" + eventId}></iframe>
                        </div>
                    </div>
                </div>
                }
                {sportId === 1 && <div class="grid-middle-noGutter w-100">
                    <div class="col-6_xs-12">
                        {!showLoader ? (scoreData && scoreData.score ? <div class="RDE-eventView-scoreBoard-matchBox RDE-eventView-scoreBoard-Football">
                            <div class="RDE-eventView-scoreBoard-matchBox-teamNames__header">
                                Live Score
                                    </div>
                            <div className="RDE-eventView-football-kickOff">Time Elapsed: <span>{scoreData.timeElapsed} : {scoreData.timeElapsedSeconds}</span> ({scoreData.matchStatus}) </div>
                            <table class="table RDE-cricketTable">
                                <thead>
                                    <tr>
                                        <th width="50%" class="text-left">Team</th>
                                        <th>Red</th>
                                        <th>Yellow</th>
                                        <th>Goal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{scoreData.score.home.name}</td>
                                        <td>{scoreData.score.home.numberOfRedCards}</td>
                                        <td>{scoreData.score.home.numberOfYellowCards}</td>
                                        <td>{scoreData.score.home.score}</td>
                                    </tr>
                                    <tr>
                                        <td>{scoreData.score.away.name}</td>
                                        <td>{scoreData.score.away.numberOfRedCards}</td>
                                        <td>{scoreData.score.away.numberOfYellowCards}</td>
                                        <td>{scoreData.score.away.score}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            : <div class="RDE-eventView-scoreBoard RDE-scoreCard-noData">
                                <div class="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.homeTeamName}</div>
                                    </div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-middle">
                                    <div class="RDE-eventView-kickOff">{formatDate(eventInfo.kickOffTime)}</div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-right RDE-awayTeam">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.awayTeamName}</div>
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                    <div class="col-6_xs-12">
                        <div className="RDE-iframeVideo">
                        <iframe allowfullscreen="" frameborder="0" height="200" marginwidth="0" mozallowfullscreen="" scrolling="no" webkitallowfullscreen="" width="100%"
                            src={"https://jeoad.com/santosh/graphics.php?ppk=" + eventId}></iframe>
                        </div>
                    </div>
                </div>
                }
                {sportId === 2 && <div class="grid-middle-noGutter w-100">
                    <div class="col-6_xs-12">
                        {!showLoader ? (scoreData && scoreData.score ?
                            <div class="RDE-eventView-scoreBoard-matchBox RDE-eventView-scoreBoard-Football">
                                <div class="RDE-eventView-scoreBoard-matchBox-teamNames__header">
                                    Live Score
                                    </div>

                                <div className="RDE-eventView-tennis-setsInfo">
                                    <div className="RDE-eventView-tennis-setScore-box">
                                        <div className="RDE-eventView-tennis-setScore">Set: <span>{scoreData.currentSet}</span></div>
                                        <div className="RDE-eventView-tennis-setScore">Game: <span>{scoreData.currentGame}</span></div>
                                        <div className="RDE-eventView-tennis-setScore">Point: <span>{scoreData.currentPoint}</span></div>
                                    </div>
                                    <div className="RDE-eventView-tennis-status">
                                        <div className="">Status: <span>{scoreData.matchStatus}</span></div>
                                    </div>
                                </div>

                                <table class="table RDE-cricketTable">
                                    <thead>
                                        <tr>
                                            <th width="25%" class="text-left">Player Name</th>
                                            <th>Set 1</th>
                                            <th>Set 2</th>
                                            <th>Set 3</th>
                                            <th>Set 4</th>
                                            <th>Set 5</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{scoreData.score.home.name}</td>
                                            <td>{scoreData.score.home.gameSequence[0] ? scoreData.score.home.gameSequence[0] : "-"}</td>
                                            <td>{scoreData.score.home.gameSequence[1] ? scoreData.score.home.gameSequence[1] : "-"}</td>
                                            <td>{scoreData.score.home.gameSequence[2] ? scoreData.score.home.gameSequence[2] : "-"}</td>
                                            <td>{scoreData.score.home.gameSequence[3] ? scoreData.score.home.gameSequence[3] : "-"}</td>
                                            <td>{scoreData.score.home.gameSequence[4] ? scoreData.score.home.gameSequence[4] : "-"}</td>
                                            <td>{scoreData.score.home.score}</td>
                                        </tr>
                                        <tr>
                                            <td>{scoreData.score.away.name}</td>
                                            <td>{scoreData.score.away.gameSequence[0] ? scoreData.score.away.gameSequence[0] : "-"}</td>
                                            <td>{scoreData.score.away.gameSequence[1] ? scoreData.score.away.gameSequence[1] : "-"}</td>
                                            <td>{scoreData.score.away.gameSequence[2] ? scoreData.score.away.gameSequence[2] : "-"}</td>
                                            <td>{scoreData.score.away.gameSequence[3] ? scoreData.score.away.gameSequence[3] : "-"}</td>
                                            <td>{scoreData.score.away.gameSequence[4] ? scoreData.score.away.gameSequence[4] : "-"}</td>
                                            <td>{scoreData.score.away.score}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : <div class="RDE-eventView-scoreBoard RDE-scoreCard-noData">
                                <div class="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.homeTeamName}</div>
                                    </div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-middle">
                                    <div class="RDE-eventView-kickOff">{formatDate(eventInfo.kickOffTime)}</div>
                                </div>
                                <div class="RDE-eventView-scoreBoard-right RDE-awayTeam">
                                    <div class="RDE-eventView-scoreBoard-teamDetails">
                                        <div class="RDE-eventView-scoreBoard-teamName">{eventInfo.awayTeamName}</div>
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                    <div class="col-6_xs-12">
                        <div className="RDE-iframeVideo">
                        <iframe allowfullscreen="" frameborder="0" height="200" marginwidth="0" mozallowfullscreen="" scrolling="no" webkitallowfullscreen="" width="100%"
                            src={"https://jeoad.com/santosh/graphics.php?ppk=" + eventId}></iframe>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }
}

export default observer(ScoreCard);