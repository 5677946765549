import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import HighlightEvents from '../highlightevents/highlightEvents';
import HighlightEventsStore from '../highlightevents/highlightEventsStore';
import imageUrls from '../../../sharedFiles/imageUrls';

const highlightEventsStore = new HighlightEventsStore();
class SportsLiveNow extends Component {
  componentWillMount() {
    if (this.props.match) {
      //   console.log(this.props.match);
      if (this.props.match.params.sportName && this.props.match.params.sportName !== '' && this.props.match.params.sportName !== null) {
        this.props.sportsLiveNowStore.sportName = this.props.sportName;
      }
      if (this.props.match.params.sportId && this.props.match.params.sportId !== '' && this.props.match.params.sportId !== null) {
        this.props.sportsLiveNowStore.sportId = this.props.sportId;
      }
    }
    this.props.sportsLiveNowStore.getLiveEvents();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match) {
      if (nextProps.match.params.sportName &&
        nextProps.match.params.sportName !== '' &&
        nextProps.match.params.sportName !== null &&
        this.props.sportsLiveNowStore.sportName !== nextProps.sportId) {
        this.props.sportsLiveNowStore.sportName = this.props.sportId;
      }
      if (this.props.match.params.sportId &&
        this.props.match.params.sportId !== '' &&
        this.props.match.params.sportId !== null &&
        this.props.sportsLiveNowStore.sportId !== nextProps.match.params.sportId) {
        this.props.sportsLiveNowStore.sportId = nextProps.match.params.sportId;
        // console.log("sportId==", nextProps.match.params.sportId)
        this.props.sportsLiveNowStore.getLiveEvents();
      }
    }
  }
  render() {
    const { sportsLiveNowStore, appStore, sportId, sportName } = this.props;
    let events = sportsLiveNowStore.liveEvents;
    return (
      <div className="RDE-liveNowSection">
        {!sportsLiveNowStore.showLoader && !sportsLiveNowStore.showErrorMsg ? <Route render={(props) => <HighlightEvents {...props} events={events}
          componentType='sportsLiveNow'
          appStore={appStore}
          highlightEventsStore={highlightEventsStore}
          sportId={sportId}
          sportName={sportName} />} /> : ""}
        {sportsLiveNowStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for this Sport.
        </div> : ''}
        {sportsLiveNowStore.showLoader ? <div className="RDE-loader">
          <img src={imageUrls.loaderIcon} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(SportsLiveNow);
