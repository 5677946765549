import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import { getLocalDate, openBetsFormatDate } from '../../../../sharedFiles/helper';
import { Collapse } from "react-bootstrap";
import PlacedBeteventDetails from "../placedbeteventdetails/placedbeteventdetails";
import PlacedBetEventDetailsStore from "../placedbeteventdetails/placedbeteventdetailsstore";

const placedBetEventDetailsStore = new PlacedBetEventDetailsStore();
class BalanceOverview extends Component {
    constructor(props) {
        super(props);
        this.handleEventClick = this.handleEventClick.bind(this);
    }
    componentWillMount() {
    }
    componentWillReceiveProps(nextProps) { }
    handleIconClick(store, index) {
        if (index >= 0) {
            var eventIndex = store.accordionOpenIndex.indexOf(index);
            if (eventIndex === -1) {
                store.accordionOpenIndex.push(index);
            } else {
                store.accordionOpenIndex.splice(eventIndex, 1);
            }
        }
    }
    handleEventClick(transaction) {
        this.props.balanceOverviewStore.showEventDetails = true;
        this.props.balanceOverviewStore.selectedEvent = transaction;
    }
    render() {
        const { data, component, store, balanceOverviewStore,selectedSport } = this.props;
        var self = this;
        return (
            <div className="RDE-DB-table">
                {!balanceOverviewStore.showEventDetails ? data.map(function (item, index) {
                    return (
                        <div className={store.accordionOpenIndex.indexOf(index) === -1 ? "RDE-accordion RDE-myBets collapsed" : "RDE-accordion RDE-myBets"}>
                            <div className='RDE-accordion-header' onClick={() => self.handleIconClick(store, index)}>
                                <div className="RDE-myBets__headerDetails">
                                    <div className="RDE-myBets__headerContent RDE-myBets__headerLeft">
                                        {/* <span className="RDE-headingText">Date:</span> */}
                                        <span className="RDE-labelRight-text">{getLocalDate(item.transactionDate)}</span>
                                    </div>
                                    <div className="RDE-myBets__headerContent RDE-myBets__headerRight text-right">
                                        {
                                            component==="PL"?<>
                                             <span className="RDE-headingText">Net Win:</span>
                                            <span className={"RDE-DB-txtHighlight RDE-DB-profit " + (item.balance >= 0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.balance.toFixed(2)}</span>
                                            </>:<>
                                             <span className="RDE-headingText">Balance:</span>
                                             <span className={"RDE-DB-txtHighlight"}>{item.balance.toFixed(2)}</span>
                                            </>
                                        }
                                       
                                    </div>
                                    {/* <div className="RDE-myBets__expandArrow" onClick={()=>self.handleIconClick(store,index)}>
                                            <i className="RDE-icon-down-arrow"></i>
                                        </div> */}
                                </div>
                            </div>

                            <Collapse in={store.accordionOpenIndex.indexOf(index) !== -1}>
                                <div className="RDE-accordion-body collapse">
                                    <div className="RDE-myBets__content">
                                        <div className="RDE-myBets__contentBox">
                                            <div className="RDE-myBets__contentBox-left">
                                                <div className="RDE-label" onClick={() => self.handleEventClick(item)}><a>{item.eventName}</a></div>
                                                {/* <span className="RDE-labelRight-text">{item.debitAmount > 0 ? item.debitAmount : '-'}</span> */}
                                                {component === "AS" ? <div className="RDE-label">Transaction Type: <span className="">{item.transactionType}</span></div> :
                                                    <>
                                                        <div className="RDE-label">Start time: <span className="">{openBetsFormatDate(item.betPlacedDate)}</span></div>
                                                        <div className="RDE-label">Settled time: <span className="">{openBetsFormatDate(item.transactionDate)}</span></div>
                                                  </>
                                                }
                                            </div>
                                            <div className="RDE-myBets__contentBox-right text-right">
                                                {component === "AS" ? <><span className="RDE-label">P&amp;L:</span>
                                                    <span className={"RDE-DB-txtHighlight RDE-DB-profit " + (item.profitLossAmount >= 0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.profitLossAmount}</span>
                                                </> :
                                                    <><div className="RDE-label">Comm: <span className="">{item.profitLossAmount}</span></div>
                                                    <div className="RDE-label">Net Win: <span className={"RDE-DB-txtHighlight " + (item.balance>=0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.balance}</span></div></>
                                                    }
                                            </div>
                                        </div>
                                        {/* <div className="RDE-myBets__contentBox">
                                            <div className="RDE-myBets__contentBox-left">
                                                <span className="RDE-label">Credits:</span>
                                                 <span className="RDE-labelRight-text">{item.creditAmount > 0 ? item.creditAmount : '-'}</span>
                                            </div>
                                            <div className="RDE-myBets__contentBox-right text-right">
                                                <span className="RDE-label">Remarks:</span>
                                                <span className="RDE-labelRight-text">{item.transactionRemarks.length > 0 ? item.transactionRemarks : '-'}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                    )
                }) :
                    <PlacedBeteventDetails balanceOverviewTableStore={balanceOverviewStore}
                        selectedEvent={balanceOverviewStore.selectedEvent} component={component} placedBetEventDetailsStore={placedBetEventDetailsStore}
                        selectedSport={selectedSport} /> 

                }

            </div>

        )
    }
}
export default observer(BalanceOverview);
