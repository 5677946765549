import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageURLs from '../../sharedFiles/imageUrls';

class MainHeader extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  selectSport(sportName, sportId) {
  }
  navigateHome() {
    this.props.history.push('/');
  }
 
  render() {
    const { appStore } = this.props;
    return (
      <div className="RDE-mainHeader">
        <div className="RDE-mainHeaderInner">
          <div className="RDE-mobileHeader__left">
            <ul>
              <li onClick={appStore.showhideLeftMenu.bind(appStore)}>
                <i className="RDE-icon-hamburger"></i>
              </li>
              <li onClick={appStore.enableSearchBox.bind(appStore)}>
                <i className="RDE-icon-search"></i>
              </li>
            </ul>
          </div>

          <div className="RDE-logo" onClick={()=>this.navigateHome()}>
            <img src={imageURLs.xchangeLogo} className="" alt="Logo"  />
          </div>
          <div className="RDE-mobileHeader__right">
            {!appStore.isLoggedIn ? <>{/*<div className="RDE-beforeLogin grid-middle-noGutter">
              <button className="btn RDE-btnLogin" onClick={appStore.showLogin.bind(appStore)}>Login</button>
              <button className="btn RDE-btnSignUp" onClick={appStore.showSignup.bind(appStore)}>Signup</button>
    </div>*/}</>
              :
              <div className="RDE-afterLogin grid-middle-noGutter">
                <div className="RDE-afterLogin-balanceSection">
                  <div className="RDE-afterLogin-burgerMenu" onClick={appStore.showhideRightMenu.bind(this.props.appStore)}>
                    <div><i className="RDE-icon-user-profile"></i></div>
                    <div>{appStore.userInfo.userAccountBalance}</div>
                    {/* <div className="grid-noGutter">
                      <div className="col text-right">
                        <div>PTH {appStore.userInfo.userAccountBalance} </div>
                        <div>
                            <i className="RDE-icon-reload"></i><span>{appStore.userInfo.userExposure} </span>
                        </div>
                      </div>
                      <div className="col-6">
                        <span className="RDE-afterLogin-balanceInfo__label">&nbsp;: Main</span>
                        <span className="RDE-afterLogin-balanceInfo__label">&nbsp;: Exposure</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default observer(MainHeader);
