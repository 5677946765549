import { extendObservable } from 'mobx';
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";
import cookie from 'react-cookies';

class mymarketsstore {
    constructor() {
        extendObservable(this, {
            myMarketsInfo: []
        });
    }
    /**
     * this method can be used to get the markets information.
     */
    getMyMarketsInfo() {
        var marketsInfo = [];
        var token = cookie.load('UTocken');
        if (token && token.length > 0) {
            apiCaller(masterPage.getOpenbets + '?userToken=' + token, 'GET').then(response => {
                if (response.data && response.data.length > 0) {
                    for (var item of response.data) {
                        var objMarketInfo = {
                            eventId: item.eventId,
                            eventName: item.eventName,
                            sportId: item.sportId,
                            sportName:item.sportName
                        }
                        marketsInfo.push(objMarketInfo);
                    }
                }
                this.myMarketsInfo = marketsInfo;
            });
        }
    }
}

export default mymarketsstore;