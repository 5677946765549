import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import ElectionComponent from './electioncomponent/electionComponent';
import ElectionComponentStore from './electioncomponent/electionComponentStore';

const electionComponentStore = new ElectionComponentStore();

class ElectionLayout extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount(){
    this.props.appStore.currentPath="election";
  }
  componentDidMount() {
  
  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) {}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const {appStore, isLoggedIn} = this.props;
    return ( 
        // <Router>  
          <div className="RDE-content">
          <Switch>
              <Route path="/election" exact render={(props)=><ElectionComponent {...props} 
                                                                                     appStore={appStore}
                                                                                     isLoggedIn={isLoggedIn}
                                                                                     electionStore ={electionComponentStore}/>} />
          </Switch>
          </div>
        // </Router>
    );
  }
}

export default observer(ElectionLayout);
