import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";
import Flickity from 'react-flickity-component'
import HighlightEvents from '../highlightevents/highlightEvents';
import HighlightEventsStore from '../highlightevents/highlightEventsStore';
import imageUrls from '../../../sharedFiles/imageUrls';

const highlightEventsStore = new HighlightEventsStore();
class SportsHighlights extends Component {
  componentWillMount() {
    if (this.props) {
      if (this.props.sportId && this.props.sportId !== '' && this.props.sportId !== null) {
        this.props.sportsHighlightsStore.sportId = this.props.sportId;
        this.props.sportsHighlightsStore.getLeagues();
      }
      if (this.props.sportName && this.props.sportName !== '' && this.props.sportName !== null) {
        this.props.sportsHighlightsStore.sportName = this.props.sportName;
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props) {
      if (nextProps.sportId &&
        nextProps.sportId !== '' &&
        nextProps.sportId !== null &&
        nextProps.sportId !== this.props.sportsHighlightsStore.sportId) {
        this.props.sportsHighlightsStore.sportId = nextProps.sportId;
        this.props.sportsHighlightsStore.getLeagues();
      }
      if (nextProps.sportName &&
        nextProps.sportName !== '' &&
        nextProps.sportName !== null &&
        nextProps.sportName !== this.props.sportsHighlightsStore.sportName) {
        this.props.sportsHighlightsStore.sportName = this.props.match.params.sportName;
      }
    }
  }
  loadLeagueItems() {
    var self = this;
    return (
      <ul className="RDE-nav-pills-menu">
        {this.props.sportsHighlightsStore.leagues.map((item, index) => {
          return <li key={'leagueItem_' + index}><div className={this.props.sportsHighlightsStore.selectedLeagueId == item.id ? "carousel-cell RDE-nav-pills-menu-item active active" : "carousel-cell RDE-nav-pills-menu-item"} key={'sports_highlights_leagues_' + index} onClick={this.props.sportsHighlightsStore.leagueSelect.bind(this.props.sportsHighlightsStore, item.id)} >
            <span>{item.name}<span className="RDE-country"> ({item.countryName})</span></span>
          </div></li>
        })
        }
      </ul>
    )

  }
  render() {
    const { sportsHighlightsStore, appStore, sportId, sportName } = this.props;
    const events = sportsHighlightsStore.leagueEvents;
    const handleOnDragStart = e => e.preventDefault()
    const items = this.loadLeagueItems();
    return (
      <div className="RDE-sportsPage-content">
        <div className="RDE-highlightsSection">
          <div className="RDE-leagueList">
            {sportsHighlightsStore.leagues &&
              sportsHighlightsStore.leagues.length > 0 ?
              <Flickity
                flickityRef={c => sportsHighlightsStore.flkty = c}
                className={'RDE-leagueListCarousel'}
                elementType={'div'}
                options={{
                  cellAlign: 'left',
                  draggable: true,
                  freeScroll: true,
                  prevNextButtons: true,
                  pageDots: false,
                  contain: true,
                  groupCells: true,
                }}
                disableImagesLoaded={false}
              >
                {/* <ul class="RDE-nav-pills-menu"> */}
                {sportsHighlightsStore.leagues.map((item, index) => {
                  return <div className={sportsHighlightsStore.selectedLeagueId == item.id ? "carousel-cell RDE-leagueListCarouselItem active" : "carousel-cell RDE-leagueListCarouselItem"} key={'sports_highlights_leagues_' + index} onClick={sportsHighlightsStore.leagueSelect.bind(sportsHighlightsStore, item.id)} >
                    <div className="RDE-leagueName">{item.name}</div>
                    <div className="RDE-country">{item.countryName}</div>
                  </div>
                })
                }
                {/* </ul> */}
              </Flickity> : ""}

          </div>
        </div>
        {!sportsHighlightsStore.showLoader && !sportsHighlightsStore.showErrorMsg ? <Route render={(props) => <HighlightEvents {...props} events={events}
          componentType='sportsHighlights'
          appStore={appStore}
          highlightEventsStore={highlightEventsStore}
          sportId={sportId}
          sportName={sportName} />} /> : ""}
        {sportsHighlightsStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for this Sport.
        </div> : ''}
        {sportsHighlightsStore.showLoader ? <div className="RDE-loader">
          <img src={imageUrls.loaderIcon} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(SportsHighlights);
