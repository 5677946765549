import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageURLs from '../../sharedFiles/imageUrls';
import { BrowserRouter as Router } from 'react-router-dom';
import { Collapse } from "react-bootstrap";

class RightMenu extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.rightmenuStore.appStore = this.props.appStore;
        // this.props.rightmenuStore.arrangeMenuData();
    }

    render() {
        const { appStore, rightmenuStore, isLoggedIn } = this.props;
        var self = this;
        return (
            <div className="BCM-modal-content">
                <div className="RDE-leftSideMenu_mainHeader">
                    <i className="RDE-icon-user-profile"></i>
                    <div className="RDE-leftSideMenu-userTitle">{appStore.userInfo.userName}</div>

                    <div className="RDE-afterLogin-balanceInfo">
                        <div className="">
                            <span className="RDE-afterLogin-balanceInfo__label">Main:&nbsp;</span>
                            <span>{appStore.userInfo.userAccountBalance}</span>
                            {/**PTH removed */}
                        </div>
                        <div className="">
                            <span className="RDE-afterLogin-balanceInfo__label">Exposure:&nbsp;</span>
                            <span>{appStore.userInfo.userExposure} </span>
                            <i className="RDE-icon-reload"></i>
                        </div>
                    </div>

                    <button type="button" className="btn RDE-modalClose" onClick={appStore.showhideRightMenu.bind(appStore)}>
                        <i className="RDE-icon-close cursor-pointer"></i>
                    </button>
                </div>

                <div className="RDE-leftSideMenu_mainContent">
                    <div>
                        <ul className="RDE-leftSideMenu-mainNav">
                            <li onClick={appStore.showhideDepositPopup.bind(appStore)}><a>Deposit</a></li>
                            {
                                appStore.userMenu.map(function (item, index) {
                                    //if (index < 3) {
                                    if (item.userMenuId < 5) {
                                        return (
                                            <li key={'menuItem_' + index} onClick={appStore.userMenuClick.bind(appStore, item.userMenuId)}><a href={"/myaccount" + item.userMenuLinkUrl}>{item.userMenuName}</a></li>
                                        )
                                    }

                                })
                            }
                            <li onClick={appStore.userMenuClick.bind(appStore, 0)}><a href={"/myaccount/changepassword"}>Change Password</a></li>
                            <li className="">
                                <a href="#" className={rightmenuStore.accordionOpenIndex.indexOf(0) === -1 ? "collapsed" : "active"} onClick={rightmenuStore.openAccordion.bind(rightmenuStore, 0)}>My Bets<i className="RDE-icon-down-arrow"></i></a>
                                <Collapse in={rightmenuStore.accordionOpenIndex.indexOf(0) !== -1}>
                                    <ul className="RDE-leftSideMenu-subNav">
                                        {
                                            appStore.userMenu.map(function (myBetItem, MBIndex) {
                                                if (myBetItem.userMenuId === 5 || myBetItem.userMenuId === 6 || myBetItem.userMenuId === 7) {
                                                    return (
                                                        <li key={'menuItem_' + MBIndex} onClick={appStore.userMenuClick.bind(appStore, myBetItem.userMenuId)}>
                                                            <a href={"/myaccount" + myBetItem.userMenuLinkUrl}>{myBetItem.userMenuName}</a>
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                </Collapse>
                            </li>
                            {
                                appStore.userMenu.map(function (item, index) {
                                    if (item.userMenuId === 8) {
                                        return (
                                            <li className="RDE-sideMenu-logout" key={'menuItem_' + index} onClick={appStore.userMenuClick.bind(appStore, item.userMenuId)}>
                                                {item.userMenuName == "Log Out" ? <a>{item.userMenuName}</a> :
                                                    <a href={"/myaccount" + item.userMenuLinkUrl}>{item.userMenuName}</a>}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(RightMenu);
