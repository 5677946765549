import { extendObservable } from "mobx";
import { profile } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';
import cookie from 'react-cookies';

class PlacedBetEventDetailsStore {
    constructor(props) {
        extendObservable(this, {
            eventDetails: [],
            component:'',
            data:[],
            backSubTotal:0,
            laySubTotal:0,
            marketSubTotal:0,
            commission:0,
            netTotal:0
        });
    }

    getDetails(selectedEvent) {
        var token = "";
        var cookeTocken = cookie.load('UTocken');
        if (cookeTocken && cookeTocken.length > 0) {
            token = cookeTocken;
        }
        var params={
            "userToken":token,
            "transactionType":this.component==="PL"?'ps':'as',
            "transactionDate":selectedEvent.transactionDate,
            "eventId":selectedEvent.eventId,
            "outcomeName":selectedEvent.outcomeName,
            "betPlaced":selectedEvent.betPlacedDate
        }
        var self = this;
        apiCaller(profile.gettransdetailseventview, 'POST', params).then(response => {
           // console.log("date===", response);
            if(response && response.hasOwnProperty('data') && response.data.length>0){
                self.data = response.data;
                this.calculateTotals()
            }
        }).catch(exception => {

        })
    }
    calculateTotals() {
        var backSubTotal=0,laySubTotal=0,marketSubTotal=0,commission=0;
        for(var bet of this.data){
            if(bet.oddType.toLowerCase() === 'back'){
              backSubTotal = backSubTotal+Number(bet.profitLossAmount)
            }else if(bet.oddType.toLowerCase() === 'lay'){
              laySubTotal = laySubTotal+Number(bet.profitLossAmount)
            }
            commission =commission+ Number(bet.commission)
        }
        marketSubTotal = (backSubTotal+laySubTotal).toFixed(2);
        this.netTotal = (marketSubTotal - commission).toFixed(2);
        this.backSubTotal = (backSubTotal).toFixed(2);
        this.laySubTotal = (laySubTotal).toFixed(2);
        this.marketSubTotal = Number(marketSubTotal).toFixed(2);
        this.commission = Number(commission).toFixed(2);
    }

}
export default PlacedBetEventDetailsStore;