import { extendObservable } from "mobx";
import { scoreCard,interval } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

class ScoreCardStore {
  constructor(props) {
    extendObservable(this, {
      scoreData:{},
      scoreInterval: '',
      showLoader:false
    });
  }
  getScores(sportId, eventId) {
    this.scoreData = {};
    this.showLoader = true;
    if(this.scoreInterval){
      clearInterval(this.scoreInterval);
    }
    if (sportId == 4) {  
      this.getCricketScore(eventId);
      this.scoreInterval= setInterval(() => {
        this.getCricketScore(eventId);
      }, interval.liveScore);
    }
    else {
      this.getOtherSportScore(eventId);
      this.scoreInterval= setInterval(() => {
        this.getOtherSportScore(eventId);
      }, interval.liveScore);
     
    }
  }
  getCricketScore(eventId){
    apiCaller(scoreCard.cricketScore+"?matchid="+eventId,'GET').then(response => {
        if(response && response.data ){
          this.showOdds=true;
          if(!response.data.error){
            var scoreData={}
            response.data.map(function(obj){
              scoreData = Object.assign({},scoreData,obj);
            });
            this.scoreData = scoreData;
            this.scoreData.overs1 = this.calculateOvers(this.scoreData.ballsdone);
            this.scoreData.overs2 = this.calculateOvers(this.scoreData.ballsdone2);
            this.scoreData.previousOvers= this.getPreviousOvers(this.scoreData.lb);
          }
      }
      this.showLoader = false;
      });
    
  }
  getOtherSportScore(eventId) {
    apiCaller(scoreCard.otherSportScores+"?matchid="+eventId,'GET').then(response => {
      if(response && response.length>0 ){
        this.showOdds = true;
        this.scoreData = response[0];
      }
      this.showLoader = false;
    });
  }
  calculateOvers(totalBalls){
    var overs = (Math.floor(totalBalls/6));
    var ball = (totalBalls%6)
    if(ball>0){
      overs =overs+'.'+ball
    }
    return overs;

  }

  getPreviousOvers(lastBalls) {
    var self = this;
    var po = lastBalls.split('|');
   // var lo = po[0];
    //this.scoreData.lastOver = lo.split(' ').reverse();
    var previousOvers=[]
    po.map(function (over, index) {
      if (index === po.length-1) {
       self.scoreData.lastOver = over.trim().split('.');
      }
      else {
        previousOvers.push(over.trim().split(' '));
      }
      
    });
    return previousOvers;
  
    //this.scoreData.previousOvers = previousOvers;

  }

  
}

export default ScoreCardStore;
