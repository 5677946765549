import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageUrls from '../../../sharedFiles/imageUrls';

class MobileFooter extends Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.state = {
			popupVisible: false
		};
	}
	handleClick() {
		this.props.appStore.showHideBetSlipSettings('settings', '');
		// if (!this.state.popupVisible) {
		// 	// attach/remove event handler
		// 	document.addEventListener('click', this.handleOutsideClick, false);
		// } else {
		// 	document.removeEventListener('click', this.handleOutsideClick, false);
		// }
		// this.setState(prevState => ({
		// 	popupVisible: !prevState.popupVisible,
		// }));
	}

	handleOutsideClick(e) {
		//console.log('e.target ==== ', e.target);
		// if (this.node.contains(e.target)) {
		// 	return;
		//   }
		this.handleClick();
	}
	componentWillMount() {

	}

	componentWillReceiveProps(nextProps) {

	}
	navigatePath(url) {
		if (url === '/') {
			this.props.appStore.currentPath = "home";
			this.props.appStore.footerSelItem = "home";
			this.props.history.push(url);
		} else if (url === 'betslip') {
			this.props.appStore.footerSelItem = "betslip";
		} else if (url === 'settings') {
			this.props.appStore.footerSelItem = "settings";
		} else {
			this.props.appStore.footerSelItem = url
			this.props.appStore.currentPath = url;
			this.props.history.push('/' + url);
		}
	}
	render() {
		const { appStore } = this.props;
		var self = this;
		return (
			<div className="RDE-mobileFooter">
				<ul>

					{/* {appStore.headerData.map(function(header,headerIndex){
					return(
						<li key={'items_'+headerIndex} className={((appStore.currentPath === "home" && header.url === "") || (appStore.currentPath === header.url)) ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self,header.url)}>
							<i className={header.id == 1 ? "RDE-icon-home" : header.id == 2 ? "RDE-icon-inplay" :'' }></i>
							<div>{header.name}</div>
						</div>
					</li>
					)
				})} */}
					{/* <li className={(appStore.footerSelItem === "home") ? "active" : ''}> */}
					<li className={(window.location.pathname === '/' && appStore.footerSelItem === "home") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, '/')}>
							<i className="RDE-icon-home"></i>
							<div>Home</div>
						</div>
					</li>
					{/* <li className={appStore.footerSelItem === "inplay" ? "active" : ''}> */}
					{/* <li className={(window.location.pathname.match('inplay') && appStore.footerSelItem === "inplay") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, 'inplay')}>
							<i className="RDE-icon-inplay"></i>
							<div>Inplay</div>
						</div>
					</li> */}
					<li className={(window.location.pathname.match('multimarkets') && appStore.footerSelItem === "multimarkets") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, 'multimarkets')}>
							<i className="RDE-icon-pin"></i>
							<div>Multi Markets</div>
						</div>
					</li>
					{/* <li className={appStore.footerSelItem === "election" ? "active" : ''}> */}
					{/* <li className={(window.location.pathname.match('election') && appStore.footerSelItem === "election") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, 'election')}>
							<i className="RDE-icon-pin"></i>
							<div>Election</div>
						</div>
					</li> */}
					<li className={appStore.showBetSlipSettings === "betslip" ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={appStore.showHideBetSlipSettings.bind(appStore, 'oneclickbet','')}>
							<i className="RDE-icon-click"></i>
							<div>1-Click Bet</div>
						</div>
					</li>
					{appStore.isLoggedIn && <li className={(window.location.pathname.match('openbets') && appStore.footerSelItem === "openbets") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, 'openbets')}>
							<i className="RDE-icon-betslip"></i>
							<div>Open Bets</div>
						</div>
					</li>}

					
					<li className={appStore.footerSelItem === "settings" ? "active" : ''}>
						{/* <div className="RDE-mobileFooter__item" onClick={appStore.showHideBetSlipSettings.bind(appStore, 'settings','')}> */}
						<div className="RDE-mobileFooter__item" onClick={this.handleClick}>
							<i className="RDE-icon-settings"></i>
							<div>Settings</div>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

export default observer(MobileFooter);