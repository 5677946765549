import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { openBetsFormatDate } from '../../sharedFiles/helper';

class EventBox extends Component {
    render() {
        const { betInfo, openbetsStore } = this.props;
        return (
            <div className="RDE-betslipConfirmationBox">
                <div className="RDE-betslipConfirmationBox__content RDE-openbets-confirmationBox__content">
                    <div className={'RDE-openbets-refNumberDetails ' + (betInfo.oddType.toLowerCase() === "back" ? "RDE-Openbets-backOdds" : "RDE-Openbets-layOdds")}>
                        <span>Ref: {betInfo.betId}</span>
                        <span className="text-right">{openBetsFormatDate(betInfo.betPlacedTime)}</span>
                    </div>
                    <div className="RDE-hr">
                    </div>

                    <div className="RDE-betslipConfirmationBox__matchDetails RDE-openbets-matchDetails">
                        <div className="RDE-betslipConfirmationBox__left">
                            <div className="RDE-betslipConfirmationBox__marketOutcome RDE-openbets-marketOutcome">
                                <span>{betInfo.marketName} - </span>
                                <span className="RDE-betslipConfirmationBox__outcome">{betInfo.outcomeName}</span>
                            </div>
                        </div>
                        {openbetsStore.editOpenBets ?
                            <div className="RDE-betslipConfirmationBox__right">
                                <div className="RDE-betslipBox__oddsBox">
                                    <input type="text" className="RDE-formControl" value={betInfo.changedOdds}
                                        onChange={openbetsStore.oddsChanged.bind(openbetsStore, betInfo)} />
                                    <i className="RDE-icon-plus" onClick={openbetsStore.oddsInc.bind(openbetsStore, betInfo)}></i>
                                    <i className="RDE-icon-minus" onClick={openbetsStore.oddsDec.bind(openbetsStore, betInfo)}></i>
                                </div>
                            </div> :
                            <div className="RDE-betslipConfirmationBox__right">
                                {betInfo.odds}
                            </div>}

                    </div>
                    <div className="RDE-betslipConfirmationBox__stakeReturns RDE-openbets-matchDetails">
                        <div className="RDE-betslipConfirmationBox__stake">
                            {openbetsStore.editOpenBets ?
                                <input type="text" readOnly className="RDE-formControl" placeholder="Stake" value={betInfo.changedStake}
                                    onChange={openbetsStore.stakeChanged.bind(openbetsStore, betInfo)} /> :
                                <><span>stake: </span>
                                    <span className="RDE-betslipConfirmationBox__stakeValue">
                                        {betInfo.stake}
                                    </span></>}
                        </div>
                        <div className="RDE-betslipConfirmationBox__returns text-right">
                            <span>{betInfo.oddType === "Back" ? "Profit" : "Liability"}:</span>
                            <span className="RDE-betslipConfirmationBox__returnsValue">
                                {openbetsStore.editOpenBets ? (betInfo.changedReturns - betInfo.changedStake).toFixed(2) : (betInfo.returns - betInfo.stake).toFixed(2)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(EventBox);