import React, { Component } from 'react';
import { Route, NavLink, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
//import './assets/css/RD-Exchange-styles.css';
import './assets/css/RD-Exchange-styles-blue-theme.scss';
import { Modal } from "react-bootstrap";
import { observer } from 'mobx-react';
import IdleTimer from 'react-idle-timer';
import cookie from 'react-cookies';

import HomepageLayout from "./components/homepage/HomepageLayout";
import SportspageLayout from './components/sportspage/sportsPageLayout';
import InplayPageLayout from "./components/inplaypage/inPlayPageLayout";
import MultiViewLayout from './components/multiview/multiViewLayout';
import ElectionLayout from './components/election/electionLayout';

import MainHeader from "./sharedComponents/mainheader/mainHeader";
import MainHeaderStore from './sharedComponents/mainheader/mainHeaderStore';
import RightMenu from './sharedComponents/rightmenu/rightmenu';
import RightMenuStore from './sharedComponents/rightmenu/rightmenuStore';
import LeftMenu from './sharedComponents/leftmenu/leftmenu';
import LeftMenuStore from './sharedComponents/leftmenu/leftmenuStore';
import SportsHeader from './sharedComponents/sportsheader/sportsHeader';
import SportsHeaderStore from './sharedComponents/sportsheader/sportsHeaderStore';

import LoginSignup from './sharedComponents/loginsignup/loginSignUp';
import LoginSignupStore from './sharedComponents/loginsignup/loginSignUpStore';
import ContactInfoFooter from './sharedComponents/footer/contactinfofooter/contactInfoFooter';
import ContactInfoFooterStore from './sharedComponents/footer/contactinfofooter/contactInfoFooterStore';
import MobileFooter from './sharedComponents/footer/mobilefooter/mobileFooter';
import InplayHighlightsStore from './components/inplaypage/inplayhighlights/inplayHighlightsStore';
import MyAccount from './components/profile/account/account';
import MyAccountStore from './components/profile/account/accountStore';
import BetSlipComponent from "./sharedComponents/betslipcomponent/BetSlipComponent";
import BetSlipComponentStore from "./sharedComponents/betslipcomponent/BetSlipComponentStore";
import Settings from './sharedComponents/settings/settings';
import SettingsStore from './sharedComponents/settings/settingsStore';
import PrivacyPolicy from "./components/privacypolicy/PrivacyPolicy";
import RulesRegulations from "./components/rulesregulations/RulesRegulations";
import TermsConditions from "./components/termscondition/TermsConditions";
import popUpClose from "./assets/images/popup-close.svg";
import OpenBets from "./components/openbets/openbets";
import OpenBetsStore from "./components/openbets/openbetsstore";
import MyMarkets from "./sharedComponents/mymarkets/mymarktes";
import MyMarketsStore from "./sharedComponents/mymarkets/mymarketsstore";
import Search from './sharedComponents/search/search';
import Games from "./components/games/Games";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import ForgotPasswordStore from "./components/forgotpassword/ForgotPasswordStore";
import OneClickBet from './components/oneclickbet/OneClickBet';
import OneClickBetStore from './components/oneclickbet/OneClickBetStore';
const loginSignupStore = new LoginSignupStore();
const mainHeaderStore = new MainHeaderStore();
const rightmenuStore = new RightMenuStore();
const leftmenuStore = new LeftMenuStore();
const betslipComponentStore = new BetSlipComponentStore();
const contactInfoFooterStore = new ContactInfoFooterStore();
const sportsHeaderStore = new SportsHeaderStore();
const inplayHighlightsStore = new InplayHighlightsStore();
const accountStore = new MyAccountStore();
const settingsStore = new SettingsStore();
const openbetsStore = new OpenBetsStore();
const myMarketsStore= new MyMarketsStore();
const forgotPasswordStore = new ForgotPasswordStore();
const oneClickBetStore=new OneClickBetStore();
class App extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.searchValue = '';
    this.getBetslipInfoClassName=this.getBetslipInfoClassName.bind(this);
  }
  componentWillMount() {
    this.props.appStore.mounted=true;
    if (cookie.load('UTocken') && cookie.load('UTocken').length > 0) {
      this.props.appStore.isLoggedIn = true;
      this.props.appStore.getUserMenu();
      this.props.appStore.getUserBalanceDetails();
      settingsStore.getSettingsValue();
      this.props.appStore.getBetsProfitLoss(false);
    }
    else{
      if(!window.location.href.includes('update_password')){
        this.props.history.push('/login');
      }
    }
    if (cookie.load('userInfo')) {
      this.props.appStore.userInfo = cookie.load('userInfo');
    }
    this.props.appStore.betSlipStore = betslipComponentStore;
    betslipComponentStore.appStore = this.props.appStore;
    this.props.appStore.getHeaderData();
    this.props.appStore.getMainHeaderSportsData();
    if (this.props.location && this.props.location.pathname.includes("privacy-policy") ||
      this.props.location.pathname.includes("rules-and-regulations") ||
      this.props.location.pathname.includes("terms-and-conditions") ||
      this.props.location.pathname.includes("reset")) {
      this.props.appStore.showTC = true
    }

  }
  componentDidMount() {
     var self = this;
      setTimeout(function reCallService() {
        if(self.props.appStore.mounted){
          self.props.appStore.getBetsProfitLoss(false);
          setTimeout(reCallService, 6000);
        }
      }, 6000);
      setTimeout(function reCallService() {
        if (self.props.appStore.mounted) {
          if (self.props.appStore.isLoggedIn) {
            self.props.appStore.getUserBalanceDetails();
          }
          setTimeout(reCallService, 3000);
        }
      }, 3000);
  } 
  _onIdle(e) {
    if (this.props.appStore.isLoggedIn) {
      this.props.appStore.logout();
    }
  }
  selectSport(sportName, sportId) {
    // this.sportName = sportName;
    // this.sportId = sportId;
    // this.props.appStore.selectSport(sportName,sportId);
    // this.props.history.push('/sports/'+sportName.replace(' ','-').toLowerCase()+'/'+sportId);
  }
  getBetslipInfoClassName(){
    //"RDE-betslip-infoMsg " + (appStore.msgType === "success" ? (appStore.placedBetInfo.type==="B"? "RDE-success RDE-back":"RDE-success RDE-lay")
    //: "RDE-error")}
    let className="RDE-betslip-infoMsg ";
    let type=this.props.appStore.placedBetInfo.type;
    let isMatched=this.props.appStore.placedBetInfo.isMatched;
    let msgType=this.props.appStore.msgType;

   if(msgType === "success"){
     if(type == "B" && isMatched){
      className+="RDE-success";
     }
     else if(type === "L" && isMatched){
      className+="RDE-lay";
     }
     else if(!isMatched){
       className+="RDE-error";
     }
   }
   else{
    className+="RDE-error";
   }
   return className;
  }
  render() {
    const { appStore } = this.props;
    return (
      <Router>
        {!appStore.showTC ? <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <div className="App">
            <div className="RD-exchange-sportsBook">
              <div className="RDE-stickyHeader">
                <Route render={(props) => <MainHeader  {...props} appStore={appStore} mainHeaderStore={mainHeaderStore} />} />
                {!window.location.pathname.match("myaccount") ? <Route render={(props) => <SportsHeader {...props} sportsHeaderStore={sportsHeaderStore} inplayHighlightsStore={inplayHighlightsStore} appStore={appStore} />} /> : ''}
              </div>

              <div className={window.location.pathname.match("myaccount") ? "RDE-dashboard-container" : "RDE-content"}>
                <Switch>
                
                <Route exact path="/login" render={(props) => <LoginSignup   {...props}
                  loginSignupStore={loginSignupStore} 
                  appStore={appStore} 
                  settingsStore={settingsStore}/>} />
                <Route exact path="/" render={(props) => <HomepageLayout {...props} 
                  appStore={appStore}
                  isLoggedIn={appStore.isLoggedIn}
                  sportId={appStore.sportId}
                  sportName={appStore.sportName} />} />
                  <Route exact path="/home" render={(props) => <HomepageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName} />} />
                  <Route path="/sports" render={(props) => <SportspageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName}
                    pLStatusChanged={appStore.pLStatusChanged}/>} />
                  <Route exact path="/inplay" render={(props) => <InplayPageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    inplayHighlightsStore={inplayHighlightsStore}
                    sportName={appStore.sportName} />} />
                  <Route exact path="/multimarkets" render={(props) => <MultiViewLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    betslipComponentStore={betslipComponentStore}
                    pLStatusChanged={appStore.pLStatusChanged}
                    sportName={appStore.sportName} />} />
                  <Route path="/election" render={(props) => <ElectionLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName} />} />
                  <Route path="/myaccount" render={(props) => <MyAccount {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    accountStore={accountStore} />} />
                  <Route exact path="/openbets" render={(props) => <OpenBets {...props} openbetsStore={openbetsStore} appStore={appStore} />} />
                  <Route exact path="/mymarkets" render={(props) => <MyMarkets {...props} appStore={appStore} myMarketsStore={myMarketsStore}/>}/>
                  <Route exact path="/livecasino" render={(props) => <Games {...props}
                    appStore={appStore} />} />
                  
                  <Route exact path="/update_password/:token" render={(props) => <ForgotPassword {...props} 
                    appStore={appStore}
                    forgotPasswordStore={forgotPasswordStore} />} />

                </Switch>

                <ContactInfoFooter appStore={appStore} footerStore={contactInfoFooterStore} />
              </div>
              {/* {appStore.currentPath !== 'profile'?<div className=""> */}
              {/* {betslipComponentStore.confirmBets ?
                <div className="">
                  <BetSlipComponent betslipComponentStore={betslipComponentStore} appStore={appStore} coinValueChange={appStore.coinValuesChanged} />
                </div> : ""} */}
              {/* <MobileFooter appStore={appStore} /> */}
              <Route render={(props) => <MobileFooter {...props}
                appStore={appStore}
                isLoggedIn={appStore.isLoggedIn}
              />} />

              <Route render={(props) => <Search  {...props} appStore={appStore}/>}/>
              {/* <SubFooter appStore={appStore} /> */}

              {/* {appStore.footerSelItem === "settings" ?
                <div className="">
                  <Settings settingsStore={settingsStore} appStore={appStore} coinValueChange={appStore.coinValuesChanged} />
                </div> : ""} */}
                {appStore.footerSelItem === "oneclickbet" && appStore.showOneClick ?
                <div className="">
                  <OneClickBet oneClickBetStore={oneClickBetStore} appStore={appStore} />
                </div> : ""}
            </div>

            {/*<Modal
              show={appStore.showSignupPopup || appStore.showLoginPopup}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.hideSignup.bind(appStore)}
              dialogClassName="RDE-loginSignup-modal">
              <LoginSignup loginSignupStore={loginSignupStore} appStore={appStore} settingsStore={settingsStore}/>
            </Modal>*/}
            <Modal
              show={appStore.showLogoutPopup}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.hideSignup.bind(appStore)}
              dialogClassName="RDE-idleLogout-modal sm">
              <div className="RDE-modal-content">
                <div className="RDE-modal-header">
                </div>
                <div className="RDE-modal-body text-center">
                  <div className="RDE-idleLogout-txt"><b>Your session has expired, Please login again.</b></div>
                </div>
                <div className="RDE-modal-footer">
                  <div className="grid-noGutter-middle-center">
                    <div className="col-6 text-center">
                      <button className="btn RDE-btn-primary RDE-btn-default" onClick={appStore.logout.bind(appStore)}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              show={appStore.showLeftMenuItems}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.showhideLeftMenu.bind(appStore)}
              dialogClassName="RDE-leftSideMenu animated slideInLeft">
              <Route render={(props) => <LeftMenu {...props}
                appStore={appStore} isLoggedIn={appStore.isLoggedIn}
                leftmenuStore={leftmenuStore} />} />
            </Modal>
            {/* <Modal
              show={appStore.footerSelItem === "oneclickbet" && appStore.showOneClick}
              backdrop={true}
              backdropClassName="fade"
              centered
              // onHide={appStore.showhideLeftMenu.bind(appStore)}
             >
              <OneClickBet oneClickBetStore={oneClickBetStore} appStore={appStore} />
            </Modal> */}
            <Modal
              show={appStore.footerSelItem === "settings" && appStore.showSettings}
              backdrop={true}
              backdropClassName="fade"
              centered
              // onHide={appStore.showhideLeftMenu.bind(appStore)}
             >
              <Settings settingsStore={settingsStore} appStore={appStore} />
            </Modal>
            <Modal
              show={betslipComponentStore.confirmBets}
              backdrop={true}
              backdropClassName="fade"
              centered
              dialogClassName="RDE-confirmBets-modal sm">
                <div className="">
                <BetSlipComponent betslipComponentStore={betslipComponentStore}
                  appStore={appStore} coinValueChange={appStore.coinValuesChanged}  />
                </div> 
            </Modal>
            <Modal
              show={appStore.showRightMenuItems}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.showhideRightMenu.bind(appStore)}
              dialogClassName="RDE-rightSideMenu animated slideInRight">
              <RightMenu appStore={appStore} isLoggedIn={appStore.isLoggedIn} rightmenuStore={rightmenuStore} />
            </Modal>
            <Modal
              show={appStore.showDeposit}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.showhideDepositPopup.bind(appStore)}
              dialogClassName="RDE-deposit-modal sm">
              <div className="RDE-modal-content">
                <div className="RDE-modal-header">
                  <div className="RDE-modal-header__txt">Deposit</div>
                  <img src={popUpClose} className="RDE-modal-closeIcon" onClick={appStore.showhideDepositPopup.bind(appStore)} />
                  <div className="RDE-hr"></div>
                </div>
                <div className="RDE-modal-body">
                  <div className="RDE-deposit-contact text-center">
                    <div className="mb-5">Please contact for Deposit</div>
                    {/* <a href="tel:+91 9876543210"><strong>+91 9876543210</strong></a> */}
                   <a href="tel:+44 7566 742924"><strong>+44 7566 742924</strong></a>
                  </div>
                </div>
                <div className="RDE-modal-footer">
                  <div className="grid-noGutter-middle-center">
                    <div className="col-12 text-center">
                      <button className="btn RDE-btn-primary" onClick={appStore.showhideDepositPopup.bind(appStore)}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* {appStore.currentPath !== "mymarkets" && appStore.isLoggedIn && <NavLink to="/mymarkets">
              <div className="RDE-eyeIcon">
                <i className="RDE-icon-eye"></i>
              </div>
            </NavLink>}  */}
                
            {appStore.msgInfo.length > 0 ?
              <div className= {this.getBetslipInfoClassName()}>{appStore.msgInfo}
                {appStore.msgType === "success" && <div className="RDE-betslip-infoMsg__oddsStakeInfo">
                  <div><span>&nbsp;Odds:</span><span>&nbsp;{appStore.placedBetInfo.odds}</span></div>
                  <div><span>&nbsp;Stake:</span><span>&nbsp;{appStore.placedBetInfo.stake}</span></div>
                </div>}
                </div> : ""}

          </div>
         
        </div> : <div>
            <Switch>
              <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
              <Route exact path="/rules-and-regulations" render={(props) => <RulesRegulations {...props} />} />
              <Route exact path="/terms-and-conditions" render={(props) => <TermsConditions {...props} />} />
            </Switch>
          </div>}

      </Router>
    );
  }
}

export default observer(App);
