import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { upcomingHighlights } from "../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../sharedFiles/apiStore';
import { selectSelection } from '../../../sharedFiles/helper';

class InplayHighlightsStore {
  constructor() {
    extendObservable(this, {
      highlightsData: [],
      pageId: "",
      selectedSport: "",
      showErrorMsg: false,
      showLoader: false,
      sports: [],
      isInitialLoad: true,
      mounted: true,
      sportLength: ''
    })
  }

  /**
    * This method is used to get the list of sports in highlights.
    *
    */
  getHighlightsSports() {
    apiCaller(upcomingHighlights.getHighlightsSports + "?pageId=" + this.pageId, 'GET').then(response => {
      if (response.data && response.data.hasOwnProperty('sportDetailsList') &&
        response.data.sportDetailsList && response.data.sportDetailsList.length > 0) {
        this.sports = response.data.sportDetailsList;
        if (!this.selectedSport) {
          this.selectedSport = this.sports[0];
        }
        this.getHighlightsEventData();
      }
    });
  }

  selectSport(sport) {
    if (sport && sport.hasOwnProperty('name')) {
      this.selectedSport = sport;
      this.getHighlightsEventData();
    }
  }

  /**
  * This method is used to get the highlights event data.
  *
  */
  getHighlightsEventData() {
    if (this.selectedSport.id !== null && this.selectedSport.id > 0) {
      this.showErrorMsg = false;
      this.showLoader = true;
      this.highlightsData = [];
      var token = "";
      var cookeTocken = cookie.load('UTocken');
      if (cookeTocken && cookeTocken.length > 0) {
        token = cookeTocken;
      }
      apiCaller(upcomingHighlights.getHighlightsEvents + "?sportId=" + this.selectedSport.id + "&userToken=" + token + "&pageId=" + this.pageId, 'GET').then(response => {
        this.showLoader = false;
        if (response.data && response.data.hasOwnProperty('events') &&
          response.data.events && response.data.events.length > 0) {
          this.highlightsData = response.data.events;
          this.selections = selectSelection(this.highlightsData[0]);
        } else {
          this.showErrorMsg = true;
        }
      });
    } else {
      this.showErrorMsg = true;
    }
  }


}

export default InplayHighlightsStore;