import { extendObservable } from "mobx";
import cookie from 'react-cookies';
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";

const re = /[0-9\b.]+$/;
class OpenBetsStore {
  constructor(props) {
    extendObservable(this, {
      openbetsData: [],
      // selectedOpenBetsEvent: {
      //   eventId: '',
      //   eventName: ''
      // },
      selectedOpenBets: [],
      editOpenBets: false,
      openBetsErr: '',
      isOpenBetsStakeErr: false,
      isMounted: true
    })
  }
  /**
   * this method can be used to get the openBets Data.
   */
  getOpenbets(appStore) {
    appStore.openBetsEventIds = [];
    // this.openbetsData = [];
    var token = cookie.load('UTocken');
    if (token && token.length > 0) {
      apiCaller(masterPage.getOpenbets + '?userToken=' + token, 'GET').then(response => {
        if (response && response.data && response.data.length > 0) {

          // by default selecting first.
          // this.selectedOpenBetsEvent.eventId = response.data[0].eventId;
          // this.selectedOpenBetsEvent.eventName = response.data[0].eventName;
          var openBetsResponse = response.data;
          this.openbetsData = response.data;
          for (var i = 0; i < this.openbetsData.length; i++) {
            appStore.openBetsEventIds.push(this.openbetsData[i].eventId);
            if (this.openbetsData[i]['unMatchedBets']) {
              for (var j = 0; j < openBetsResponse[i]['unMatchedBets']['bets'].length; j++) {


                openBetsResponse[i]['unMatchedBets']['bets'][j]["isOddsEdited"] = true;
                openBetsResponse[i]['unMatchedBets']['bets'][j]["isStakeEdited"] = true;
                openBetsResponse[i]['unMatchedBets']['bets'][j]["edited"] = false;
                openBetsResponse[i]['unMatchedBets']['bets'][j]['changedOdds'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['odds'];
                openBetsResponse[i]['unMatchedBets']['bets'][j]['changedStake'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['stake'];
                openBetsResponse[i]['unMatchedBets']['bets'][j]['changedReturns'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['returns'];
                openBetsResponse[i]['unMatchedBets']['bets'][j]['stakeError'] = "";

              }
              // for(var j=0;j<this.openbetsData[i]['unMatchedBets']['backBets'].length;j++){
              //   this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['odds'];
              //   this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['stake'];
              //   this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['returns'];
              //   this.openbetsData[i]['unMatchedBets']['backBets'][j]['stakeError'] = "";
              // }
              // for(var j=0;j<this.openbetsData[i]['unMatchedBets']['layBets'].length;j++){
              //   this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['odds'];
              //   this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['stake'];
              //   this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['returns'];
              //   this.openbetsData[i]['unMatchedBets']['layBets'][j]['stakeError'] = "";
              // }
            }
          }
          this.openbetsData = openBetsResponse;
          if (!!appStore.selectedOpenBetsEvent.eventId && appStore.selectedOpenBetsEvent.eventId != undefined) {
            var openBetEventIndex = appStore.openBetsEventIds.indexOf(appStore.selectedOpenBetsEvent.eventId);
            if (openBetEventIndex === -1) {
              appStore.selectedOpenBetsEvent.eventName = this.openbetsData[0].eventName;
              appStore.selectedOpenBetsEvent.eventId = this.openbetsData[0].eventId;
            }
          }
          else {
            appStore.selectedOpenBetsEvent.eventName = this.openbetsData[0].eventName;
            appStore.selectedOpenBetsEvent.eventId = this.openbetsData[0].eventId;
          }
        }
        else {
          this.openbetsData = [];
        }
      }).catch(exception => {
        this.openbetsData = [];
      });

    }
  }

  /**
  * This method used to select the open bets events
  * @param {Number} eventId contains the event id to be selected.
  **/
  selectOpenbetsEvent(eventId, eventName, appStore) {
    appStore.selectedOpenBetsEvent.eventId = eventId;
    appStore.selectedOpenBetsEvent.eventName = eventName;
    this.selectedOpenBets = [];
    this.editOpenBets = false;
  }
  calculateOpenBetsReturns(bet) {
    if (bet.isFancy) {
      if (bet.changedOdds && bet.changedOdds > 0 && bet.changedStake && bet.changedStake > 0) {
        var returns = (Number(bet.changedOdds) * Number(bet.changedStake)) / 100;
        var totalReturns = returns + Number(bet.changedStake);
        return totalReturns.toFixed(2);
      } else {
        return "0.00"
      }
    } else {
      if (bet.changedOdds && bet.changedOdds > 0 && bet.changedStake && bet.changedStake > 0) {
        return (Number(bet.changedOdds) * Number(bet.changedStake)).toFixed(2);
      } else {
        return "0.00"
      }
    }
  }
  oddsInc(bet) {
    // bet.changedOdds = (Number(bet.changedOdds)+0.01).toFixed(2);
    var incRange = "";

    if (Number(bet.changedOdds) >= 1 && Number(bet.changedOdds) < 2) {
      incRange = 0.01;
    } else if (Number(bet.changedOdds) >= 2 && Number(bet.changedOdds) < 3) {
      incRange = 0.02;
    } else if (Number(bet.changedOdds) >= 3 && Number(bet.changedOdds) < 4) {
      incRange = 0.05;
    } else if (Number(bet.changedOdds) >= 4 && Number(bet.changedOdds) < 6) {
      incRange = 0.10;
    } else if (Number(bet.changedOdds) >= 6 && Number(bet.changedOdds) < 10) {
      incRange = 0.20;
    } else if (Number(bet.changedOdds) >= 10 && Number(bet.changedOdds) < 20) {
      incRange = 0.50;
    } else if (Number(bet.changedOdds) >= 20 && Number(bet.changedOdds) < 30) {
      incRange = 1.00;
    } else if (Number(bet.changedOdds) >= 30 && Number(bet.changedOdds) < 50) {
      incRange = 2.00;
    } else if (Number(bet.changedOdds) >= 50 && Number(bet.changedOdds) < 100) {
      incRange = 5.00;
    } else if (Number(bet.changedOdds) >= 100) {
      incRange = 10.00;
    }

    bet.changedOdds = (Number(bet.changedOdds) + incRange).toFixed(2);
    bet.changedReturns = (bet.changedOdds * bet.changedStake).toFixed(2);
  }
  oddsDec(bet) {
    if (bet.changedOdds > 1.01) {
      // bet.changedOdds = (Number(bet.changedOdds)-0.01).toFixed(2);
      var incRange = "";

      if (Number(bet.changedOdds) >= 1 && Number(bet.changedOdds) < 2) {
        incRange = 0.01;
      } else if (Number(bet.changedOdds) >= 2 && Number(bet.changedOdds) < 3) {
        incRange = 0.02;
      } else if (Number(bet.changedOdds) >= 3 && Number(bet.changedOdds) < 4) {
        incRange = 0.05;
      } else if (Number(bet.changedOdds) >= 4 && Number(bet.changedOdds) < 6) {
        incRange = 0.10;
      } else if (Number(bet.changedOdds) >= 6 && Number(bet.changedOdds) < 10) {
        incRange = 0.20;
      } else if (Number(bet.changedOdds) >= 10 && Number(bet.changedOdds) < 20) {
        incRange = 0.50;
      } else if (Number(bet.changedOdds) >= 20 && Number(bet.changedOdds) < 30) {
        incRange = 1.00;
      } else if (Number(bet.changedOdds) >= 30 && Number(bet.changedOdds) < 50) {
        incRange = 2.00;
      } else if (Number(bet.changedOdds) >= 50 && Number(bet.changedOdds) < 100) {
        incRange = 5.00;
      } else if (Number(bet.changedOdds) >= 100) {
        incRange = 10.00;
      }
      bet.changedOdds = (Number(bet.changedOdds) - incRange).toFixed(2);
      bet.changedReturns = (bet.changedOdds * bet.changedStake).toFixed(2);
    }
  }
  oddsChanged(bet, e) {
    if ((re.test(e.target.value) && e.target.value >= 1 && e.target.value <= 100000) || e.target.value === "") {
      bet.changedOdds = e.target.value; //Number(e.target.value);
      bet.changedReturns = this.calculateOpenBetsReturns(bet);
    }
  }
  stakeChanged(bet, e) {
    var minStake = '';
    var maxStake = '';
    if (bet.minStake > 0) {
      minStake = bet.minStake
    } else {
      minStake = 1;
    }
    if (bet.maxStake > 0) {
      maxStake = bet.maxStake;
    } else {
      maxStake = 100000;
    }
    var stake = e.target.value;
    if ((re.test(stake) && Number(stake) >= 1) || stake === "") {
      bet.changedStake = stake;
      if ((Number(stake) < minStake || Number(stake) > maxStake) || stake === "") {
        bet.stakeError = "Min. Stake: " + minStake + " and Max. Stake:" + maxStake;
      } else {
        bet.stakeError = '';
        // if(bet.isFancy){
        //   this.calculateFancyReturns(selection);
        // }else{
        //   this.calculateReturns(selection);
        // }
        bet.changedReturns = this.calculateOpenBetsReturns(bet);
      }
    }
    //this.appStore.updateBetSlipStatus();
  }
  validateOpenBets(bets) {
    for (var bet of bets.backBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    for (var bet of bets.layBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    return true;
  }
  updateOpenbets(appStore) {
    var token = cookie.load('UTocken')
    var params = {
      "userToken": cookie.load('UTocken'),
      "layBets": [],
      "backBets": []
    }
    this.isOpenBetsStakeErr = true;
    for (var openBetsEvent of this.openbetsData) {
      if (openBetsEvent.eventId === appStore.selectedOpenBetsEvent.eventId && openBetsEvent.unMatchedBets) {
        for (var bet of openBetsEvent.unMatchedBets.backBets) {
          params.backBets.push({
            "betId": bet.betId,
            "odds": Number(bet.changedOdds),
            "stake": Number(bet.changedStake),
            "returns": Number(bet.changedReturns)
          })
          if (bet.stakeError && bet.stakeError.length > 0) {
            this.isOpenBetsStakeErr = false
          }
        }
        for (var bet of openBetsEvent.unMatchedBets.layBets) {
          params.layBets.push({
            "betId": bet.betId,
            "odds": Number(bet.changedOdds),
            "stake": Number(bet.changedStake),
            "returns": Number(bet.changedReturns)
          });
          if (bet.stakeError && bet.stakeError.length > 0) {
            this.isOpenBetsStakeErr = false
          }
        }
      }
    }
    if (this.validateOpenBets(params) && this.isOpenBetsStakeErr) {
      apiCaller(masterPage.updateOpenBets, 'POST', params).then(response => {
        if (response && response.data && response.data.length > 0) {
          this.openbetsData = response.data;
          for (var i = 0; i < this.openbetsData.length; i++) {
            if (this.openbetsData[i]['unMatchedBets']) {
              for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['backBets'].length; j++) {
                this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['odds'];
                this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['stake'];
                this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['returns'];
                this.openbetsData[i]['unMatchedBets']['backBets'][j]['stakeError'] = "";
              }
              for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['layBets'].length; j++) {
                this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['odds'];
                this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['stake'];
                this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['returns'];
                this.openbetsData[i]['unMatchedBets']['layBets'][j]['stakeError'] = "";
              }
            }
          }
        }
      });
    } else {
      if (this.isOpenBetsStakeErr) {

      } else {
        this.openBetsErr = "Please enter stake and odds should >1"
      }
    }


  }
  resetOpen() {
    for (var i = 0; i < this.openbetsData.length; i++) {
      if (this.openbetsData[i]['unMatchedBets']) {
        for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['backBets'].length; j++) {
          this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['odds'];
          this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['stake'];
          this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['returns'];
          this.openbetsData[i]['unMatchedBets']['backBets'][j]['stakeError'] = "";
        }
        for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['layBets'].length; j++) {
          this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['odds'];
          this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['stake'];
          this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['returns'];
          this.openbetsData[i]['unMatchedBets']['layBets'][j]['stakeError'] = "";
        }
      }
    }
    this.editOpenBets = false;
  }
  cancelBets(betId, appStore) {
    this.callCancelBets([betId], appStore);
  }
  cancelAllBets(appStore) {
    var betIds = []
    for (var openBetsEvent of this.openbetsData) {
      if (openBetsEvent.eventId === appStore.selectedOpenBetsEvent.eventId && openBetsEvent.unMatchedBets) {
        for (var bet of openBetsEvent.unMatchedBets.bets) {
          betIds.push(bet.betId);
        }
        for (var bet of openBetsEvent.unMatchedBets.bets) {
          betIds.push(bet.betId);
        }
      }
    }
    this.callCancelBets(betIds, appStore);
  }
  callCancelBets(betIds, appStore) {
    var params = {
      "userToken": cookie.load('UTocken'),
      "betIdList": betIds
    }
    if (params.betIdList.length > 0) {
      apiCaller(masterPage.cancelOpenBets, 'POST', params).then(response => {
        if (response && response.data && response.data.betCancelled) {
          this.getOpenbets(appStore);
          appStore.getUserBalanceDetails();
        }
      });
    }
  }
  /**
 * This method is used to show the bet in edit mode.
 * @parm {Number} betId contains the bet id to show in edit mode
 */
  editOpenBet(appStore, bet, openBetProps) {
    // bet.changedOdds = bet.odds;
    // bet.changedStake = bet.stake;
    // bet.changedReturns = bet.returns;
    // this.editViewOpenBets.push(bet.betId);
    // this.editOpenBets = true;
    //console.log("bet=====", bet)
    appStore.clearBetslip();
    let eventId = appStore.selectedOpenBetsEvent.eventId;
    // for (var openBetEvnt of this.openbetsData) {
    //  if (openBetEvnt.eventId === appStore.selectedOpenBetsEvent.eventId) {
    //  for (var betType in openBetEvnt.unMatchedBets) {
    //   if (openBetEvnt.unMatchedBets[betType].length > 0) {
    //    for (var bet of openBetEvnt.unMatchedBets[betType]) {
    // console.log("bet===", bet);

    appStore.selectedOutcomeUniqueId = eventId + "_" + bet.marketId + "_" + bet.outcomeId;
    let homeTeam = appStore.selectedOpenBetsEvent.eventName.split(' v ')[0];
    let awayTeam = appStore.selectedOpenBetsEvent.eventName.split(' v ')[1];
    var obj = {
      betId: bet.betId,
      eventId: eventId,
      betFairBetId: bet.betfairBetId,
      clEventId: eventId,
      marketId: bet.marketId,
      clMarketId: bet.clientMarketId,
      selectionId: bet.outcomeId,
      clOutcomeId: bet.selectionId,
      eventName: appStore.selectedOpenBetsEvent.eventName,
      isLive: bet.isLive,
      marketName: bet.marketName,
      homeTeam: homeTeam,
      awayTeam: awayTeam,
      odds: bet.odds,
      selectionName: bet.outcomeName === "1" ? homeTeam : bet.outcomeName.toLowerCase() === 'x' ? "Draw" : awayTeam,
      betType: bet.oddType === "Back" ? "BO" : "LO",
      minStake: bet.minStake,
      maxStake: bet.maxStake,
      providerId: bet.providerId,
      isFancy: bet.isFancy,
      stakeError: "",
      subMarket: '',
      userStake: bet.stake
    }
    //debugger;
    appStore.isBetEditMode = true;
   appStore.addToBetSlip(obj, 'BO', false);
    appStore.sportId = bet.sportId;
    appStore.sportName = bet.sportName.toLowerCase();
    appStore.eventViewId = appStore.selectedOpenBetsEvent.eventId;
    openBetProps.naviGateTOEventView(appStore);

    //  }
    //  }
    //}
    // }
    // }

  }
}
export default OpenBetsStore;
