import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import { extendObservable } from "mobx";
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";
import sortJsonArray from 'sort-json-array';

import EventViewOddsComponent from '../eventviewoddscomponent/eventViewOddsComponent';
import EventViewOddsComponentStore from '../eventviewoddscomponent/eventViewOddsComponentStore';
import BetSlipComponent from '../betslipcomponent/BetSlipComponent';


const eventViewOddsComponentStore = new EventViewOddsComponentStore();
class OutcomeComponent extends Component {
    constructor(props){
        super(props)
        this.setOutcomeAccordionIndex = this.setOutcomeAccordionIndex.bind(this);
        extendObservable(this, {
            isMounted: true,
            showOdds: false,
            marketSuspended:0
        });
      }
    componentWillMount() {
        this.marketSuspended = this.props.marketSuspended;
    }
    componentWillReceiveProps(nextProps) {
        if(this.marketSuspended !== nextProps.marketSuspended){
            this.marketSuspended = nextProps.marketSuspended;
        }
    }
    setOutcomeAccordionIndex(id, isAdd) {
        this.props.appStore.selectedOutcomeUniqueId = 0;
        if (isAdd) {
            this.props.appStore.selectedOutcomeUniqueId = id
        }
    }
    componentDidMount(){
       // if(this.props.component === 'matchodds'){
        var self = this;
            setTimeout(function reCallService() {
                if(self.isMounted){
                  self.getOdds();
                  setTimeout(reCallService, 1000);
                }
            }, 1000);
       // }
    }
    componentWillUnmount(){
        this.isMounted = false;
    }
    getOdds(){
    //+this.props.clMarketId
    const self = this;
    if(this.props.clMarketId){
        apiCaller(masterPage.getUpdatedOdds+"?marketId="+this.props.clMarketId,'GET').then(response => {
            if (response && response.data) {
                this.showOdds=true;
                var market = response.data;
                this.props.appStore.betSlipStore.updateBetSlipOdds(response.data);
                if (market.marketId === self.props.clMarketId) {
                    if(market.status.toLowerCase() === 'suspended'){
                        self.marketSuspended = 1;
                    }else{
                        self.marketSuspended = 0;
                    }
                for(var outcome of market.runners){
                for(var i=0;i<self.props.outcomes.length;i++){
                    if(outcome.selectionId === Number(self.props.outcomes[i]['clOutcomeId'])){
                    var backOddsIndex=0
                    for(var backSelection of outcome.ex.availableToBack){
                        if(backOddsIndex<3 && self.props.outcomes[i]['backOdds'][backOddsIndex]){
                        self.props.outcomes[i]['backOdds'][backOddsIndex]['odds'] = backSelection.price;
                        self.props.outcomes[i]['backOdds'][backOddsIndex]['size'] =  Math.round(backSelection.size);
                        }
                        backOddsIndex++;
                    }
                    self.props.outcomes[i]['backOdds']=sortJsonArray( self.props.outcomes[i]['backOdds'],'odds','asc')
                    var layOddsIndex=0
                    for(var laySelection of outcome.ex.availableToLay){
                        if (layOddsIndex < 3 && self.props.outcomes[i]['layOdds'][layOddsIndex]) {
                        self.props.outcomes[i]['layOdds'][layOddsIndex]['odds'] = laySelection.price;
                        self.props.outcomes[i]['layOdds'][layOddsIndex]['size'] =  Math.round(laySelection.size);
                        }
                        layOddsIndex++;
                    }
                    //self.props.outcomes[i]['layOdds']=sortJsonArray( self.props.outcomes[i]['layOdds'],'odds','asc')
                    }
                }
                
                }
            }
            
            }
        });
    }

  }
    render() {
        const { outcomes,
            eventSuspended,
            eventBlocked,
            marketSuspended,
            marketBlocked,
            appStore,
            eventName,
            eventId,
            marketName,
            marketId,
            homeTeam,
            awayTeam,
            clMarketId,
            providerId,
            minStake,
            maxStake,
            component,
            profitLoss,
            outcomeComponentStore,
            isLive,parentStore} = this.props;
            //console.log(appStore.selectedOutcomeUniqueId,"uniqueId", eventId+"_"+marketId+"_")
        return (
            <div className="RDE-OutcomeItemGroup">
                <div className="RDE-accordion-group">
                    {
                        outcomes.map((outcome, index) => {
                            return (
                                <>
                                    <div className={"RDE-accordion RDE-singleLineOutcomeItem" + (outcomeComponentStore.accordionOpenIndex.indexOf(index) === -1 ? " collapsed" : "")} key={"eventView_outcome_" + index}>
                                        <div className="RDE-singleLineOutcomeItem__OutcomeContainer">
                                            {/* <div className="RDE-singleLineOutcomeItem__outcomeStats">
                                            <i className="RDE-icon-stats"></i>
                                        </div> */}
                                            <div className="RDE-singleLineOutcomeItem__outComeDetails">
                                                <div className="RDE-singleLineOutcomeItem__outComeName">
                                                    {outcome.name}
                                                </div>
                                                {homeTeam === outcome.name ?
                                                    <div className="RDE-singleLineOutcomeItem__result">
                                                        {profitLoss.homeTeamProfitLoss ?
                                                            <div className={profitLoss.homeTeamProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.homeTeamProfitLoss && Math.abs(profitLoss.homeTeamProfitLoss).toFixed(2)} </div> :
                                                            ""}
                                                        {profitLoss.homeTeamProfitLoss && profitLoss.homeProfitLoss ? <i className="RDE-icon-right-arrow"></i> : ''}
                                                        <div className={profitLoss.homeProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.homeProfitLoss && Math.abs(profitLoss.homeProfitLoss).toFixed(2)}</div>
                                                    </div>

                                                    :
                                                    awayTeam === outcome.name ?
                                                        <div className="RDE-singleLineOutcomeItem__result">
                                                            {profitLoss.awayTeamProfitLoss ?
                                                                <div className={profitLoss.awayTeamProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.awayTeamProfitLoss && Math.abs(profitLoss.awayTeamProfitLoss).toFixed(2)} </div> :
                                                                ''}
                                                            {profitLoss.awayTeamProfitLoss && profitLoss.awayProfitLoss ? <i className="RDE-icon-right-arrow"></i> : ''}
                                                            <div className={profitLoss.awayProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.awayProfitLoss && Math.abs(profitLoss.awayProfitLoss).toFixed(2)}</div>
                                                        </div> :
                                                        outcome.name.toLowerCase().includes('draw') ?
                                                            <div className="RDE-singleLineOutcomeItem__result">{profitLoss.drawTeamProfitLoss ?
                                                                <div className={profitLoss.drawTeamProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.drawTeamProfitLoss && Math.abs(profitLoss.drawTeamProfitLoss).toFixed(2)} </div> :
                                                                ''}
                                                                {profitLoss.drawTeamProfitLoss && profitLoss.drawProfitLoss ? <i className="RDE-icon-right-arrow"></i> : ''}
                                                                <div className={profitLoss.drawProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.drawProfitLoss && Math.abs(profitLoss.drawProfitLoss).toFixed(2)}</div>
                                                            </div> : ""}
                                                {/* <div className="RDE-singleLineOutcomeItem__result profit">220</div> */}
                                                {/* <div className="RDE-singleLineOutcomeItem__result loss">-220</div> */}
                                            </div>
                                            {/* <div className="RDE-singleLineOutcomeItem__expandArrow" onClick={outcomeComponentStore.openAccordion.bind(outcomeComponentStore, index)}>
                                            <i className="RDE-icon-down-arrow"></i>
                                        </div> */}
                                        </div>
                                        {/* <Collapse in={outcomeComponentStore.accordionOpenIndex.indexOf(index) === -1}> */}
                                        <div className="RDE-singleLineOutcomeItem__OutcomeContainer__expandable">
                                            {/* <div className="RDE-singleLineOutcomeMarketHeader__rightContainer">
                                                <div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage RDE-backAll">
                                                </div>
                                                <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAllGroup">
                                                    <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll">
                                                        {component === 'matchodds1' ? 'Back' : 'Back all'}
                                                    </div>
                                                    <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll">
                                                        {component === 'matchodds1' ? 'Lay' : 'Lay all'}
                                                    </div>
                                                </div>
                                                <div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage RDE-layAll">
                                                </div>
                                            </div> */}
                                            <div className="RDE-singleLineOutcomeItem__BackAllLayAll">
                                                <ul>
                                                    <li>
                                                        <div className="RDE-backOddsGroup">
                                                            {!eventSuspended && !this.marketSuspended ?
                                                                outcome.backOdds.map((data, outcomeIndex) => {
                                                                    // for displaying highest odd in backodds.
                                                                    if (outcomeIndex === outcome.backOdds.length - 1) {
                                                                        return <EventViewOddsComponent
                                                                            eventSuspended={eventSuspended}
                                                                            eventBlocked={eventBlocked}
                                                                            marketSuspended={this.marketSuspended}
                                                                            marketBlocked={marketBlocked}
                                                                            appStore={appStore}
                                                                            outcome={data}
                                                                            outcomes={outcome}
                                                                            outcomeIndex={outcomeIndex}
                                                                            key={"eventview_outcome_" + outcomeIndex}
                                                                            eventViewOddsComponentStore={eventViewOddsComponentStore}
                                                                            eventName={eventName}
                                                                            eventId={eventId}
                                                                            providerId={providerId}
                                                                            isLive={isLive}
                                                                            marketName={marketName}
                                                                            marketId={marketId}
                                                                            clMarketId={clMarketId}
                                                                            minStake={minStake}
                                                                            maxStake={maxStake}
                                                                            betSlipStatusChange={appStore.betSlipStatusChange}
                                                                            homeTeam={homeTeam}
                                                                            awayTeam={awayTeam}
                                                                            showOdds={component=="matchodds1"?true: this.showOdds}
                                                                            oddsType="BO"
                                                                            setOutcomeAccordionIndex={this.setOutcomeAccordionIndex}/>
                                                                    }
                                                                })
                                                                : <div className="RDE-btnOddsSuspended">
                                                                    <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button>
                                                                </div>}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="RDE-backOddsGroup">
                                                            {/* <button className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds__one">
                                                    <div><b>1.74</b></div>
                                                    <div>361,117</div>
                                                </button>
                                                <button className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds__two">
                                                    <div><b>3.15</b></div>
                                                    <div>48,117</div>
                                                </button>
                                                <button className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds__three">
                                                    <div><b>3.10</b></div>
                                                    <div>12,630</div>
                                                </button> */}
                                                            {!eventSuspended && !this.marketSuspended ?
                                                                outcome.layOdds.map((data, outcomeIndex) => {
                                                                    // for displaying lowest odd in layodds.
                                                                    if (outcomeIndex === 0) {
                                                                        return <EventViewOddsComponent
                                                                            eventSuspended={eventSuspended}
                                                                            eventBlocked={eventBlocked}
                                                                            marketSuspended={this.marketSuspended}
                                                                            marketBlocked={marketBlocked}
                                                                            appStore={appStore}
                                                                            outcome={data}
                                                                            outcomes={outcome}
                                                                            outcomeIndex={outcomeIndex}
                                                                            key={"eventview_outcome_" + outcomeIndex}
                                                                            eventViewOddsComponentStore={eventViewOddsComponentStore}
                                                                            eventName={eventName}
                                                                            eventId={eventId}
                                                                            providerId={providerId}
                                                                            isLive={isLive}
                                                                            marketName={marketName}
                                                                            marketId={marketId}
                                                                            clMarketId={clMarketId}
                                                                            minStake={minStake}
                                                                            maxStake={maxStake}
                                                                            betSlipStatusChange={appStore.betSlipStatusChange}
                                                                            homeTeam={homeTeam}
                                                                            awayTeam={awayTeam}
                                                                            showOdds={component=="matchodds1"?true: this.showOdds}
                                                                            oddsType="LO"
                                                                            setOutcomeAccordionIndex={this.setOutcomeAccordionIndex}/>
                                                                    }
                                                                })
                                                                : <div className="">
                                                                    {/* <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button> */}
                                                                </div>}
                                                        </div>
                                                    </li>
                                                    {/* {component === 'matchodds1' ? <li>
                                                        <div className="RDE-minMax">
                                                            <div className="RDE-minMax__label">Min/Max</div>
                                                            <div className="RDE-minMax__value">{minStake > 0 ? minStake : '-'} / {maxStake > 0 ? maxStake : '-'}</div>
                                                        </div>
                                                    </li> : ''} */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* </Collapse> */}
                                    </div>
                                    <Collapse in={appStore.selectedOutcomeUniqueId === eventId+"_"+marketId+"_"+outcome.id}>
                                        <div>
                                            <BetSlipComponent betslipComponentStore={appStore.betSlipStore} appStore={appStore}
                                                coinValueChange={appStore.coinValuesChanged} />
                                        </div>
                                    </Collapse>     
                              </>
                            )
                        })
                    }

                </div>
            </div>
        )
    }
}
export default observer(OutcomeComponent);
