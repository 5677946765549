import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { eventView } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

class EventViewStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore: {},
      eventId: -1,
      matchOdds: {},
      fancyBets:{},
      eventInfo: {},
      popularBets:[],
      goalsBets:[],
      showTab: "popular",
      showStatsLive:"stats",
      selectedStatsLiveTab:"stats",
      sportName: "",
      sportId: -1,
      isMatchOdds:false,
      isFancy:false,
      isPopular:false,
      isGoals:false,
      showLoader:false,
      showErrorMsg:false,
      isInitialLoad:true,
      mounted:true,
      selectedTabName:'popular',
      showLiveFor:[901715,865818],
      eventPL:[],
      marchOddsPL:{},
      marchOdds1PL:{},
      matchOdds1: [],
      outcomeAccordionIndex:0
    });
  }
  /**
  * This method is used to get the event view data.
  *
  */
  getEventView(appStore){
    if(this.isInitialLoad){
      this.showLoader = true;
    }
    this.showErrorMsg = false;
    // this.eventInfo={};
    var token="";
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(eventView.getEvent+"?eventId="+this.eventId+"&userToken="+token,'GET').then(response => {
          this.showLoader=false;
          this.eventInfo={};
          this.getEventPL();
      if (response?.data) {
        if (appStore != null && appStore !== undefined) {
          var openBetEventIndex = appStore.openBetsEventIds.indexOf(this.eventId);
          if (openBetEventIndex !== -1) {
            appStore.selectedOpenBetsEvent.eventId = this.eventId;
            appStore.selectedOpenBetsEvent.eventName = response.data.eventName;
          }
        }
        this.eventInfo = response.data
      }
          if(response?.data?.matchOdds){
              this.matchOdds = response.data.matchOdds;
              this.marchOddsPL = this.getMarketPL(response.data.matchOdds.marketGroupId, response.data.matchOdds.providerId,null);
              this.isMatchOdds = true;
          }else{
           this.isMatchOdds = false;
          }
          if(response?.data?.matchOdds1){
              this.matchOdds1 = response.data.matchOdds1;
              this.marchOdds1PL={};
              this.marchOdds1PL = this.getMarketPL(response.data.matchOdds1.marketGroupId,response.data.matchOdds1.providerId);
              this.isMatchOdds1 = true;
          }else{
           this.isMatchOdds1 = false;
          }
          if(response?.data?.fancyBets){
              this.fancyBets = response.data.fancyBets;
              this.isFancy = true;
          }else{
           this.isFancy = false;
          }
          if( response?.data?.popular?.length>0){
              this.popularBets = response.data.popular;
              this.isPopular = true;
          }else{
           this.isPopular = false;
          }
          if(response?.data?.goals?.length>0){
              this.goalsBets = response.data.goals;
              this.isGoals = true;
          }else{
           this.isGoals = false;
          }
          if(!this.isMatchOdds && !this.isFancy && !this.isPopular && !this.isGoals){
            this.showErrorMsg = true;
          }
          if(this.isInitialLoad){
            this.isInitialLoad = false;
            if(this.isPopular){
              this.showTab = 'popular';
            }else if(this.isGoals){
              this.showTab = 'goal';
            }
          }
          this.loadStats();
          // var statsEventId = 'm'+this.eventId;
          // window.SIR("addWidget", ".RDE-eventView-scoreBoardContainer", "match.lmtPlus", {layout: "single", matchId:statsEventId,collapseTo:"momentum",activeSwitcher:"scoreDetails",disableAds:true});
    });
  }

  /**
  * This method is used to select the tab.
  * @param{String} tabName contains tab name to select.
  */
  selectTab(tabName){
    this.showTab = tabName;
  }

  updateData(){
    this.getEventView(this.appStore);
  }
  /**
  * this method is used to selected the tab
  */
  selectStatsLiveTab(tabName) {
    //this.selectedTabName = tabName;
    if (tabName === "live" && !this.appStore.isLoggedIn) {
      this.appStore.showLogin();
    }
    else {
      this.showStatsLive = tabName;
      if (this.showStatsLive === "stats") {
        setTimeout(() => {
          this.loadStats();
        }, 500)
      
      }
    }
  }

  loadStats(){
    var statsEventId = 'm' + this.eventId;
    //collapseTo:"momentum"
    window.SIR("addWidget", ".RDE-eventView-scoreBoardContainer", "match.lmtPlus", {layout: "single", matchId:statsEventId,activeSwitcher:"scoreDetails",disableAds:true});
  }
  /**
  * This method is used to get the PL for market.
  */
  getMarketPL(marketId, providerId, subMarket){
    var marketPL={};
    for(var bet of this.eventPL){
      if(marketId === bet.marketId && providerId === bet.providerId && subMarket === bet.subMarket){
        marketPL= bet
      }
    }
    return marketPL;
  }
  /**
  * This method is used to get the market profit loss.
  */
  getEventPL(){
   // debugger;
    this.eventPL=[];
    for(var bet of this.appStore.betsProfitLoss){
      if(this.eventId === bet.eventId){
          this.eventPL.push(bet)
      }
    }
  }
}

export default EventViewStore;