import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import OddsComponent from '../oddscomponent/oddsComponent';
import OutcomeComponent from '../outcomescomponent/outcomeComponent'
import EventViewOddsComponent from '../eventviewoddscomponent/eventViewOddsComponent';
import EventViewOddsComponentStore from '../eventviewoddscomponent/eventViewOddsComponentStore';
import { formatDate } from '../../sharedFiles/helper';
import BetSlipComponent from '../betslipcomponent/BetSlipComponent';
const eventViewOddsComponentStore = new EventViewOddsComponentStore();

class FancyOutcomes extends Component {
    componentWillMount() {
        this.props.fancyOutcomesStore.appStore = this.props.appStore;
        this.props.fancyOutcomesStore.component = this.props.component;
        this.props.fancyOutcomesStore.parentStore = this.props.parentStore;
        this.setOutcomeAccordionIndex = this.setOutcomeAccordionIndex.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // if(this.props.event.isBlocked !== nextProps.event.isBlocked){

        // }
        // if(this.props.event.isSuspended !== nextProps.event.isSuspended){

        // }
    }
    setOutcomeAccordionIndex(id, isAdd) {
        this.props.parentStore.outcomeAccordionIndex = 0;
        if (isAdd) {
            this.props.parentStore.outcomeAccordionIndex = id
        }
    }
    render() {
        const { data,
            eventSuspended,
            eventBlocked,
            appStore,
            eventName,
            eventId,
            isLive,
            fancyOutcomesStore,
            component,
            backHeader,
            layHeader,
            mainHeader,
            showPin,
            parentStore,
            providerId } = this.props;
        if (data.hasOwnProperty('fancyMarkets') && data.fancyMarkets.length > 0) {

            return (
                <>
                    <div className="RDE-singleLineOutcomeMarketHeader">
                        <div className="RDE-singleLineOutcomeMarketHeader__leftContainer">
                            <div className="RDE-sports-nav-tabs">
                                <ul className="RDE-sports-nav-tabs-menu">
                                    {/* {component === "election" ? "" : <li> */}
                                    {!showPin ? "" : <li>
                                        <div className={"RDE-sports-nav-tabs-menu__item RDE-pin" + (data.isPinned ? " active" : "")} onClick={fancyOutcomesStore.savePin.bind(fancyOutcomesStore, eventId, data.marketGroupId, data.providerId, data.isPinned, this.props)}>
                                            <span className="RDE-skewTxt">
                                                <i className="RDE-icon-pin"></i>
                                            </span>
                                        </div>
                                    </li>}
                                    <li>
                                        <div className="RDE-sports-nav-tabs-menu__item active">
                                            <span className="RDE-skewTxt">
                                                {/* Fancy bets */}
                                                {mainHeader}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="RDE-singleLineOutcomeMarketHeader__rightContainer">
                            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll">Yes</div>
                            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll">No</div>
                        </div>
                    </div>
                    <div className="RDE-OutcomeItemGroup">
                        <div className="RDE-accordion-group">
                            {
                                data.hasOwnProperty('fancyMarkets') && data.fancyMarkets.map((market, index) => {
                                    var profitLoss = parentStore.getMarketPL(market.marketId, providerId, null);
                                    return (
                                        <>
                                            <div className={"RDE-singleLineOutcomeItem" + (fancyOutcomesStore.accordionOpenIndex.indexOf(index) === -1 ? " collapsed" : "")} key={"eventView_outcome_" + index}>
                                                <div className="RDE-singleLineOutcomeItem__OutcomeContainer">
                                                    <div className="RDE-singleLineOutcomeItem__outComeDetails">
                                                        <div className="RDE-singleLineOutcomeItem__outComeName">
                                                            {market.marketName}
                                                        </div>
                                                        <div className="RDE-singleLineOutcomeItem__result">
                                                            {typeof (profitLoss.homeTeamProfitLoss) === "number" ? <div className="RDE-singleLineOutcomeItem__result"><div className="loss">{profitLoss && typeof (profitLoss.homeTeamProfitLoss) === "number" && Math.abs(profitLoss.homeTeamProfitLoss).toFixed(2)} </div></div> : ""}
                                                            {(typeof (profitLoss.homeTeamProfitLoss) === "number" && typeof (profitLoss.homeProfitLoss) === "number") ? <i className="RDE-icon-right-arrow"></i> : ''}
                                                            <div className="loss">{profitLoss && typeof (profitLoss.homeProfitLoss) === "number" && Math.abs(profitLoss.homeProfitLoss).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="RDE-singleLineOutcomeItem__expandArrow" onClick={fancyOutcomesStore.openAccordion.bind(fancyOutcomesStore, index)}>
                                                    <i className="RDE-icon-down-arrow"></i>
                                                </div> */}
                                                </div>
                                                {/* <Collapse in={fancyOutcomesStore.accordionOpenIndex.indexOf(index) !== -1}> */}
                                                <div className="RDE-singleLineOutcomeItem__OutcomeContainer__expandable">
                                                    {/* <div className="RDE-singleLineOutcomeMarketHeader__rightContainer">
                                                        <div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage RDE-backAll">

                                                        </div>
                                                        <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAllGroup">
                                                            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll RDE-yes">
                                                                {backHeader}
															</div>
                                                            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll RDE-no">
                                                                {layHeader}
															</div>
                                                        </div>
                                                        <div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage RDE-layAll">

                                                        </div>
                                                    </div> */}
                                                    <div className={"RDE-singleLineOutcomeItem__BackAllLayAll" + (eventSuspended || market.marketSuspended ? " RDE-fancyBetsYesNoOutComes" : '')}>
                                                        <ul>
                                                            <li>
                                                                <div className="RDE-backOddsGroup">
                                                                    {!eventSuspended && !market.marketSuspended ?
                                                                        market.outcomes.map((outcome, outcomeIndex) => {
                                                                            if (outcome.name && outcome.name.toLowerCase() === "yes" || outcome.name.toLowerCase() === 'back') {
                                                                                return <EventViewOddsComponent
                                                                                    eventSuspended={eventSuspended}
                                                                                    eventBlocked={eventBlocked}
                                                                                    marketSuspended={market.marketSuspended}
                                                                                    marketBlocked={market.marketBlocked}
                                                                                    appStore={appStore}
                                                                                    outcome={outcome}
                                                                                    outcomes={outcome}
                                                                                    outcomeIndex={outcomeIndex}
                                                                                    key={"eventview_outcome_" + outcomeIndex}
                                                                                    eventViewOddsComponentStore={eventViewOddsComponentStore}
                                                                                    eventName={eventName}
                                                                                    isLive={isLive}
                                                                                    eventId={eventId}
                                                                                    marketName={market.marketName}
                                                                                    marketId={market.marketId}
                                                                                    providerId={data.providerId}
                                                                                    oddsType='BO'
                                                                                    betSlipStatusChange={appStore.betSlipStatusChange}
                                                                                    minStake={market.minStake}
                                                                                    maxStake={market.maxStake}
                                                                                    component='fancy'
                                                                                    showOdds={true}
                                                                                    setOutcomeAccordionIndex={this.setOutcomeAccordionIndex} />
                                                                            }
                                                                        })
                                                                        : <div className="RDE-btnOddsSuspended">
                                                                            <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button>
                                                                        </div>}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="RDE-backOddsGroup">

                                                                    {!eventSuspended && !market.marketSuspended ?
                                                                        market.outcomes.map((outcome, outcomeIndex) => {

                                                                            if (outcome.name && outcome.name.toLowerCase() === "no" || outcome.name.toLowerCase() === 'lay') {
                                                                                return <EventViewOddsComponent
                                                                                    eventSuspended={eventSuspended}
                                                                                    eventBlocked={eventBlocked}
                                                                                    marketSuspended={market.marketSuspended}
                                                                                    marketBlocked={market.marketBlocked}
                                                                                    appStore={appStore}
                                                                                    outcome={outcome}
                                                                                    outcomes={outcome}
                                                                                    outcomeIndex={outcomeIndex}
                                                                                    key={"eventview_outcome_" + outcomeIndex}
                                                                                    eventViewOddsComponentStore={eventViewOddsComponentStore}
                                                                                    eventName={eventName}
                                                                                    eventId={eventId}
                                                                                    marketName={market.marketName}
                                                                                    marketId={market.marketId}
                                                                                    isLive={isLive}
                                                                                    providerId={data.providerId}
                                                                                    oddsType='LO'
                                                                                    betSlipStatusChange={appStore.betSlipStatusChange}
                                                                                    minStake={market.minStake}
                                                                                    maxStake={market.maxStake}
                                                                                    component='fancy'
                                                                                    showOdds={true}
                                                                                    setOutcomeAccordionIndex={this.setOutcomeAccordionIndex} />
                                                                            }
                                                                        })
                                                                        : <div className="">
                                                                            {/* <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button> */}
                                                                        </div>}
                                                                </div>
                                                            </li>
                                                            {/* <li>
                                                                <div className="RDE-minMax">
                                                                    <div className="RDE-minMax__label">Min/Max</div>
                                                                    <div className="RDE-minMax__value">{market.minStake > 0 ? market.minStake : '-'} / {market.maxStake > 0 ? market.maxStake : '-'}</div>
                                                                </div>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* </Collapse> */}
                                            </div>

                                            <Collapse in={parentStore.outcomeAccordionIndex === eventId + "_" + market.marketId}>
                                                <div>
                                                    <BetSlipComponent betslipComponentStore={appStore.betSlipStore} appStore={appStore}
                                                        coinValueChange={appStore.coinValuesChanged} />
                                                </div>
                                            </Collapse>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            )
        } else {
            return ""
        }

    }
}
export default observer(FancyOutcomes);
