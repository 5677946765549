import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

class MyProfile extends Component {
    componentWillMount() {
        this.props.myProfileStore.getUserProfile()
    }
    componentWillReceiveProps(nextProps) { }
    render() {
        const { myProfileStore } = this.props;
        return (
            <div className="RDE-DB-myProfile">
                <div className="grid-noGutter">
                    <div className="col-12">
                        <div className="RDE-DB-infoBox">
                            <div className="RDE-DB-infoBox__header">
                                <div className="RDE-DB-infoBox__header-left">About you</div>
                            </div>
                            <div className="RDE-DB-infoBox__content">
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>First name</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.firstName ? myProfileStore.userData.firstName : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Last name</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.lastName ? myProfileStore.userData.lastName : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>DOB</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.dateOfBirh ? myProfileStore.userData.dateOfBirh : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>E-mail</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.profileEmail ? myProfileStore.userData.profileEmail : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Primary number</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.profilePhone ? myProfileStore.userData.profilePhone : '---'}</b></div>
                                        {/* <div className="">
                                        <input type="text" className="RDE-formControl"
                                            placeholder="Mobile Number" value="" />
                                    </div> */}
                                    </div>
                                </div>
                                {/* <div className="RDE-DB-errorMessage d-none">Please enter mobile number</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="RDE-DB-infoBox">
                            <div className="RDE-DB-infoBox__header">
                                <div className="RDE-DB-infoBox__header-left">Address</div>
                            </div>
                            <div className="RDE-DB-infoBox__content">
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Address</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>--</b></div>
                                        <div className="d-none"><input type="text" className="RDE-formControl"
                                            placeholder="Name" value="" /></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Town/City</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.townCity ? myProfileStore.userData.townCity : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Country</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.countryName ? myProfileStore.userData.countryName : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Country/State</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.state ? myProfileStore.userData.state : '---'}</b></div>
                                    </div>
                                </div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Postcode</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.postalCode ? myProfileStore.userData.postalCode : '---'}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="RDE-DB-infoBox">
                            <div className="RDE-DB-infoBox__header">
                                <div className="RDE-DB-infoBox__header-left">Settings</div>
                            </div>
                            <div className="RDE-DB-infoBox__content">
                                {/* <div className="RDE-DB-infoBox-item">
                                <div className="RDE-DB-infoBox-item__left">
                                    <span>Currency</span>
                                    <span className="RDE-DB-colon">:</span>
                                </div>
                                <div className="RDE-DB-infoBox-item__right">
                                    <div><b>{myProfileStore.userData.currencyTitle?myProfileStore.userData.currencyTitle:'---'}</b></div>
                                </div>
                            </div>
                            <div className="RDE-DB-infoBox-item">
                                <div className="RDE-DB-infoBox-item__left">
                                    <span>Odds format</span>
                                    <span className="RDE-DB-colon">:</span>
                                </div>
                                <div className="RDE-DB-infoBox-item__right">
                                    <div><b>{myProfileStore.userData.oddsFormat?myProfileStore.userData.oddsFormat:'---'}</b></div>
                                </div>
                            </div> */}
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Time zone</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.timeZone ? myProfileStore.userData.timeZone : '---'}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="RDE-DB-infoBox">
                            <div className="RDE-DB-infoBox__header">
                                <div className="RDE-DB-infoBox__header-left">Commission</div>
                            </div>
                            <div className="RDE-DB-infoBox__content">
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left">
                                        <span>Comm charge</span>
                                        <span className="RDE-DB-colon">:</span>
                                    </div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div><b>{myProfileStore.userData.commisionCharges ? myProfileStore.userData.commisionCharges + '%' : '---'}</b></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(MyProfile);
