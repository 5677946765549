import { extendObservable } from "mobx";

import { masterPage } from "../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../sharedFiles/apiStore';

class ContactInfoFooterStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      communicationDetails:[],
      logoAndLicensingInformation:[],
      points:[]
    });
  }
  /**
  *
  *
  */
  getFooterData(){
   	apiCaller(masterPage.getFooter,'GET').then(response => {
  	  if(response?.data?.CommunicationDetails?.length>0){
  	      this.communicationDetails = response.data.CommunicationDetails;
  	  }
      if(response?.data?.LogoAndLicensingInformation?.length>0){
          this.logoAndLicensingInformation = response.data.LogoAndLicensingInformation[0];
      }
      if(response?.data?.Points?.length>0){
          this.points = response.data.Points;
      }
    });
  }
}

export default ContactInfoFooterStore;
