import React, { Component } from 'react';
import { observer } from 'mobx-react';

class Search extends Component {
    constructor(props) {
        super(props);
        this.navigateToEventView = this.navigateToEventView.bind(this);
    }
    navigateToEventView(sportId, sportName, eventId) {
        const { appStore } = this.props;
        appStore.clearSearch();
        appStore.sportName = sportName;
        appStore.sportId = sportId;
        appStore.eventViewId = eventId;
        this.props.history.push('/sports/' + appStore.sportName + '/' + appStore.sportId + '/event/' + appStore.eventViewId);
    }
    render() {
        const { appStore } = this.props;
        var self = this;
        return (
            <div className={appStore.enableSearch ? "RDE-searchBar" : "RDE-searchBar d-none"}>
                <div className="RDE-searchBox RDE-formGroup">
                    <input type="text" className="RDE-formControl" placeholder="Search Events" value={appStore.searchValue} onChange={appStore.handleInputChange.bind(appStore)} />
                    <button type="button" className="btn RDE-modalClose" onClick={appStore.clearSearch.bind(appStore)}>
                        <i className="RDE-icon-close"></i>
                    </button>
                    <div className="RDE-searchBoxMenu">
                        <ul>
                            {
                                appStore.loading ? (
                                    <li className="RDE-searchItem">Loading...</li>
                                ) : (appStore.searchValue !== '' && appStore.searchSuggestions.length === 0) ? (
                                    <li className="RDE-searchItem">No matches for {appStore.searchValue}</li>
                                ) :
                                        appStore.searchSuggestions.map(function (item, index) {
                                            return (<li key={'searchitem_' + item.id} className="RDE-searchItem"
                                                onClick={()=>self.navigateToEventView(item.sportId, item.sportName, item.id)} >{item.name}</li>)
                                        })

                            }
                        </ul>

                    </div>
                </div>
            </div>
        );
    }
}

export default observer(Search);