import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import imageURLs from '../../sharedFiles/imageUrls';

import BetSlipEventComponent from './betslipeventcomponent/BetSlipEventComponent';
import BetSlipEventComponentStore from './betslipeventcomponent/BetSlipEventComponentStore'

const betSlipEventComponentStore = new BetSlipEventComponentStore()
class BetSlipComponent extends Component {
  componentWillMount() {
    // if(cookie.load("showOneClick") && cookie.load("showOneClick")==="true"){
    //      this.props.subHeaderStore.showOneClick = true;
    // }
    if (this.props.appStore.isLoggedIn && localStorage && localStorage.getItem('stakeCoins')) {
      this.props.betslipComponentStore.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    } else {
      this.props.betslipComponentStore.stakeCoins = [
        { "coinValue": 10 },
        { "coinValue": 50 },
        { "coinValue": 100 },
        { "coinValue": 200 },
        { "coinValue": 300 },
        { "coinValue": 400 }
      ]
    }
    this.props.betslipComponentStore.appStore = this.props.appStore;
  //  this.props.betslipComponentStore.getBets();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.coinValueChange !== nextProps.coinValueChange) {
      this.props.betslipComponentStore.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    }
  }
  onToggleSettings() { }
  closeBetSlip() {
    this.props.appStore.showHideBetSlipSettings('', '');
    this.props.betslipComponentStore.clearBetslip();
  }
  render() {
    const { betslipComponentStore, appStore } = this.props;
    return (
      <div className={betslipComponentStore.betSlipItems.backBets.length > 0 ? "RDE-betslip RDE-backOdds" : "RDE-betslip RDE-layOdds"}>
        {/* <div className="RDE-betslip-header">
              <div className="RDE-betslip-header__txt">Betslip</div> */}
        {/* {betslipComponentStore.betSlipItems.layBets.length>0 || betslipComponentStore.betSlipItems.backBets.length>0?<div className="RDE-betslip-header__clearAll" onClick={betslipComponentStore.clearBetslip.bind(betslipComponentStore)}>Clear All</div>:""} */}
        {/* <button type="button" className="btn RDE-modalClose" onClick={this.closeBetSlip.bind(this)}>
                <i className="RDE-icon-close"></i>
              </button>
            </div> */}
        {!betslipComponentStore.showLoader ?
          <>
            {/* {betslipComponentStore.betSlipError.length > 0 ? <div className="RDE-betslip-errorMsgBox">{betslipComponentStore.betSlipError}</div> : ""} */}
            {/* {betslipComponentStore.betSlipItems.backBets.length===0 && betslipComponentStore.betSlipItems.layBets.length===0?<div className="RDE-betslip-noBets">
              <div>
                <b>Your Betslip is empty</b>
              </div>
              <div>Please click on odds to add selections to the betslip.</div>
            </div> : */}
            {!betslipComponentStore.confirmBets ? <div>

              {(betslipComponentStore.betSlipItems.backBets.length > 0 || betslipComponentStore.betSlipItems.layBets.length > 0) &&
                <div className="RDE-betslip-content">
                  {/* {betslipComponentStore.betSlipItems.backBets.length>0?<div className="RDE-betslip__backOdds-header">Back (Bet For)</div>:""} */}
                  {
                    betslipComponentStore.betSlipItems.backBets.map((item, index) => {
                      if (Object.keys(item).length > 0) {
                        return <BetSlipEventComponent key={'betslipItem_' + index} betSlipEventComponentStore={betSlipEventComponentStore}
                          appStore={appStore}
                          selection={item}
                          stakeCoins={betslipComponentStore.stakeCoins}
                          betslipComponentStore={betslipComponentStore}
                          betType='BO'
                          betIndex={index} />
                      } else {
                        return '';
                      }

                    })
                  }
                  {/* {betslipComponentStore.betSlipItems.layBets.length>0?<div className="RDE-betslip__layOdds-header">Lay (Bet For)</div>:""} */}
                  {
                    betslipComponentStore.betSlipItems.layBets.map((item, index) => {
                      if (Object.keys(item).length > 0) {
                        return <BetSlipEventComponent key={'betslipcomponent_' + index} betSlipEventComponentStore={betSlipEventComponentStore}
                          appStore={appStore}
                          selection={item}
                          stakeCoins={betslipComponentStore.stakeCoins}
                          betslipComponentStore={betslipComponentStore}
                          betType='LO'
                          betIndex={index} />
                      } else {
                        return '';
                      }
                    })
                  }
                  {(betslipComponentStore.betSlipItems.layBets.length > 0 || betslipComponentStore.betSlipItems.backBets.length > 0) && <div className="RDE-betslip-footer">
                    <div className="RDE-betslip__stakeReturnsBox">
                      {/* <div className="RDE-betslip__stakeReturns">
                  <span className="RDE-betslip__stakeReturns-txt">Total Stake:</span>
                    <span className="RDE-betslip__stakeReturns-value">$ {betslipComponentStore.totalStake}</span>
                  </div>
                  <div className="RDE-betslip__stakeReturns">
                  <span className="RDE-betslip__stakeReturns-txt">Total Returns:</span>
                    <span className="RDE-betslip__stakeReturns-value">$ {betslipComponentStore.totalReturns}</span>
                  </div> */}
                      {/* {!appStore.isBetEditMode && <div className="RDE-betslip__footer-infoHighlight">
                        {/* onClick={() => betslipComponentStore.confirmBets = true} 
                        <div className="RDE-checkbox" >
                          <input id="acceptOdds" type="checkbox" name="Betslip2" checked={betslipComponentStore.acceptOddsChange}
                            onChange={betslipComponentStore.changeAcceptOddsChange.bind(betslipComponentStore)} />
                          <label htmlFor="acceptOdds"></label>
                          <span className="RDE-checkboxLabel">Accept Odds Change</span>
                        </div>
                      </div>} */}
                      {!appStore.isBetEditMode && <div className="RDE-betslip__footer-infoHighlight">
                        {/* onClick={() => betslipComponentStore.confirmBets = true} */}
                        <div className="RDE-checkbox" >
                          <input id="confirmBet" type="checkbox" name="Betslip2" checked={betslipComponentStore.showConfirm}
                            onChange={betslipComponentStore.confirmBetSlip.bind(betslipComponentStore)} />
                          <label htmlFor="confirmBet"></label>
                          <span className="RDE-checkboxLabel">Confirm bet before placing</span>
                        </div>
                      </div>}
                      <div className="RDE-betslip__placeBet text-center">
                        {appStore.isBetEditMode ? <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md RDE-betslip-header__clearAll" disabled={
                          betslipComponentStore.isBetUpdating ? true : false
                        }
                          onClick={betslipComponentStore.updateOpenbets.bind(betslipComponentStore, appStore)}>Update Bets</button> :
                          <button className={"btn RDE-btn-primary RDE-btn-md RDE-btn-auto RDE-btnPlaceBet" + (!betslipComponentStore.isValidBets ? " RDE-btnOddsDisabled" : "")}
                            onClick={betslipComponentStore.placeBet.bind(betslipComponentStore, appStore)}>Place Bet</button>}


                        {/* <button className={"btn RDE-btn-primary RDE-btn-large RDE-btnPlaceBet"+(!betslipComponentStore.isValidBets?" RDE-btnOddsDisabled":"")} onClick={betslipComponentStore.confirmBetSlip.bind(betslipComponentStore)}>Place Bet</button> */}
                      </div>

                     
                    </div>
                    {/* <div className="RDE-betslip__footer-infoHighlight text-left">
                  <div className="RDE-checkbox">
                    <input id="confirmbets" type="checkbox" name="Betslip2" checked={betslipComponentStore.showConfirm} onChange={betslipComponentStore.confirmBetSlip.bind(betslipComponentStore)}/>
                    <label htmlFor="confirmbets"></label>
                    <span className="RDE-checkboxLabel">Please confirm your bets</span>
                  </div>
                </div> */}
                  </div>}
                </div>
              }
            </div>
              :
              <div className="RDE-betslip-content">
                {betslipComponentStore.betSlipItems.backBets.length > 0 ? <div className="RDE-betslip__backOdds-header">Back (Bet For)</div> : ""}
                {
                  betslipComponentStore.betSlipItems.backBets.map((item, index) => {
                   // console.log(item)
                    return <div className="RDE-betslipConfirmationBox">
                      <div className="RDE-betslipConfirmationBox__content">
                        <div className="RDE-betslipConfirmationBox__matchDetails">
                          <div className="RDE-betslipConfirmationBox__left">
                            <div className="RDE-betslipConfirmationBox__fixture truncate">{item.eventName}</div>
                            <div className="RDE-betslipConfirmationBox__marketOutcome">
                              <span>{item.marketName} - </span>
                              <span className="RDE-betslipConfirmationBox__outcome">{item.selectionName}</span>
                            </div>
                          </div>
                          <div className="RDE-betslipConfirmationBox__right">
                            {item.userOdds}
                          </div>
                        </div>
                        <div className="RDE-betslipConfirmationBox__stakeReturns">
                          <div className="RDE-betslipConfirmationBox__stake">
                            <span>Stake: </span>
                            <span className="RDE-betslipConfirmationBox__stakeValue">{item.userStake}</span>
                          </div>
                          <div className="RDE-betslipConfirmationBox__returns text-right">
                            <span>Profit: </span>
                            <span className="RDE-betslipConfirmationBox__returnsValue">{item.returns - item.userStake}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }


                {betslipComponentStore.betSlipItems.layBets.length > 0 ? <div className="RDE-betslip__layOdds-header">Lay (Bet For)</div> : ""}
                {
                  betslipComponentStore.betSlipItems.layBets.map((item, index) => {
                    return <div className="RDE-betslipConfirmationBox">
                      <div className="RDE-betslipConfirmationBox__content">
                        <div className="RDE-betslipConfirmationBox__matchDetails">
                          <div className="RDE-betslipConfirmationBox__left">
                            <div className="RDE-betslipConfirmationBox__fixture truncate">{item.eventName}</div>
                            <div className="RDE-betslipConfirmationBox__marketOutcome">
                              <span>{item.marketName} - </span>
                              <span className="RDE-betslipConfirmationBox__outcome">{item.selectionName}</span>
                            </div>
                          </div>
                          <div className="RDE-betslipConfirmationBox__right">
                            {item.userOdds}
                          </div>
                        </div>
                        <div className="RDE-betslipConfirmationBox__stakeReturns">
                          <div className="RDE-betslipConfirmationBox__stake">
                            <span>Stake: </span>
                            <span className="RDE-betslipConfirmationBox__stakeValue">{item.userStake}</span>
                          </div>
                          <div className="RDE-betslipConfirmationBox__returns text-right">
                            <span>Liability: </span>
                            <span className="RDE-betslipConfirmationBox__returnsValue">{(item.returns - item.userStake).toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }
                <div className="RDE-betslip-footer">
                  <div className="RDE-betslip__stakeReturnsBox">
                    <div className="space-2"></div>
                    <div className="text-center">
                      A commission is charged on your net profit from each market.
                    </div>
                    {/* <div className="space-2"></div>
                <div className="RDE-betslip__footer-infoHighlight">
                  Please confirm your bets
                    </div> */}
                    <div className="RDE-betslip__placeBet text-center">
                      <div className="grid-middle-noGutter">
                        <div className="col-6 text-center">
                          <span className="RDE-betslip-backToEdit" onClick={betslipComponentStore.goBackToBetSlip.bind(betslipComponentStore, appStore)}>Back to Edit Bets</span>
                        </div>
                        <div className="col-6">
                          <button className="btn RDE-btn-primary RDE-btn-large RDE-btnConfirmBets" onClick={betslipComponentStore.saveBets.bind(betslipComponentStore)}>Confirm Bets</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </> :
          <div className="RDE-loader">
            <div className="text-center">
              <img src={imageURLs.loaderIcon} alt="loading...!" />
              <div><strong>{betslipComponentStore.countDownText} {betslipComponentStore.countDown > 0 ? betslipComponentStore.countDown + " sec" : ""}</strong></div>
            </div>
          </div>
        }
      </div>
    )
  }
}
export default observer(BetSlipComponent);
