import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";

import MarketOutcomes from '../../../sharedComponents/marketoutcomes/marketOutcomes';
import MarketOutcomesStore from '../../../sharedComponents/marketoutcomes/marketOutcomesStore';
import FancyOutcomes from '../../../sharedComponents/fancyoutcomes/fancyOutcomes';
import FancyOutcomesStore from '../../../sharedComponents/fancyoutcomes/fancyOutcomesStore';
import imageUrls from '../../../sharedFiles/imageUrls';
import Popular from '../../../sharedComponents/popular/popular';
import PopularStore from '../../../sharedComponents/popular/popularStore';
import {liveMatchRefreshTime} from '../../../sharedFiles/helper';

const marketOutcomesStore = new MarketOutcomesStore();
const fancyOutcomesStore = new FancyOutcomesStore();
const popularStore = new PopularStore();
class MultiViewComponent extends Component {
  componentWillMount() {
    this.props.multiViewComponentStore.appStore = this.props.appStore;
    if (this.props.isLoggedIn) {
      this.props.multiViewComponentStore.getData();
      this.props.multiViewComponentStore.mounted=true;  
    } else {
      this.props.multiViewComponentStore.showErrorMsg = true;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn !== this.props.isLoggedIn && nextProps.isLoggedIn) {
      this.props.multiViewComponentStore.getData();
    }
  }
  componentDidMount(){
    var self = this;
    setTimeout(function reCallService() {
      if(self.props.multiViewComponentStore.mounted){
        self.props.multiViewComponentStore.getData("update");
        setTimeout(reCallService, liveMatchRefreshTime);
      }
    }, liveMatchRefreshTime);
  }
  componentWillUnmount(){
    this.props.multiViewComponentStore.mounted = false;
  }
  render() {
    const { multiViewComponentStore, appStore,pLStatusChanged } = this.props;
    return (
        <div className="RDE-multiMarket">
          <div className="RDE-accordion-group">
            {
            !multiViewComponentStore.showLoader && multiViewComponentStore.data.map((item, index) => {
                var marchOddsPL={};
                if(item && item.hasOwnProperty('matchOdds') &&
                   item.matchOdds){
                    var matchOdds = item.matchOdds;
                    marchOddsPL = this.props.multiViewComponentStore.getMarketPL(item.eventId,item.matchOdds.marketGroupId, item.matchOdds.providerId);
                }
                var marchOdds1PL={};
                if(item && item.hasOwnProperty('matchOdds1') &&
                   item.matchOdds1){
                    marchOdds1PL = this.props.multiViewComponentStore.getMarketPL(item.eventId,item.matchOdds1.marketGroupId,item.matchOdds1.providerId);
                }
                return <div className={"RDE-accordion" + (multiViewComponentStore.accordionOpenIndex.indexOf(index) === -1 ? " collapsed" : "")} key={"multiMarket_" + index}>
                  <div className="RDE-accordion-header RDE-singleLineEventMarketHeader" onClick={multiViewComponentStore.openAccordion.bind(multiViewComponentStore, index)}>
                    <div className="RDE-singleLineEventMarketHeader__left">
                      <div className="RDE-singleLineEventMarketHeader-title">
                        {item.eventName}
                      </div>
                    </div>
                    <div className="RDE-singleLineEventMarketHeader__right">

                    </div>
                  </div>
                  <Collapse in={multiViewComponentStore.accordionOpenIndex.indexOf(index) === -1}>
                    <div className="RDE-accordion-body">
                      {item.matchOdds && Object.keys(item.matchOdds).length > 0 ? <MarketOutcomes data={item.matchOdds ? item.matchOdds : {}}
                        eventSuspended={item.eventSuspended}
                        eventBlocked={item.eventBlocked}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        appStore={appStore}
                        marketOutcomesStore={marketOutcomesStore}
                        homeTeam={item.homeTeamName}
                        awayTeam={item.awayTeamName}
                        isLive={item.isLive}
                        parentStore={multiViewComponentStore}
                        profitLoss={marchOddsPL}
                        component='multiview' /> : ""}

                      {/* <div className="space-10"></div> */}
                      {item.matchOdds1 && Object.keys(item.matchOdds1).length > 0 ? <MarketOutcomes data={item.matchOdds1 ? item.matchOdds1 : {}}
                        eventSuspended={item.eventSuspended}
                        eventBlocked={item.eventBlocked}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        appStore={appStore}
                        homeTeam={item.homeTeamName}
                        awayTeam={item.awayTeamName}
                        isLive={item.isLive}
                        parentStore={multiViewComponentStore}
                        component='matchodds1'
                        profitLoss={marchOdds1PL}
                        marketOutcomesStore={marketOutcomesStore} /> : ''}

                      {item.tiedMatch && Object.keys(item.tiedMatch).length > 0 ? <MarketOutcomes data={item.tiedMatch ? item.tiedMatch : {}}
                        eventSuspended={item.eventSuspended}
                        eventBlocked={item.eventBlocked}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        appStore={appStore}
                        marketOutcomesStore={marketOutcomesStore}
                        homeTeam={item.homeTeamName}
                        awayTeam={item.awayTeamName}
                        isLive={item.isLive}
                        parentStore={multiViewComponentStore}
                        component='multiview' /> : ""}

                      {/* <div className="space-10"></div> */}
                      {item.fancyBets && Object.keys(item.fancyBets).length > 0 ? <FancyOutcomes data={item.fancyBets ? item.fancyBets : {}}
                        eventSuspended={item.eventSuspended}
                        eventBlocked={item.eventBlocked}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        appStore={appStore}
                        fancyOutcomesStore={fancyOutcomesStore}
                        parentStore={multiViewComponentStore}
                        homeTeam={item.homeTeamName}
                        awayTeam={item.awayTeamName}
                        isLive={item.isLive}
                        marketGroupId={item.fancyBets.marketGroupId}
                        providerId={item.fancyBets.providerId}
                        backHeader='Yes'
                        layHeader='No'
                        showPin={true}
                        mainHeader='Fancy Bets'
                        component='fancy' /> : ""}

                      {item.fancyBets1 && Object.keys(item.fancyBets1).length > 0 ? <FancyOutcomes data={item.fancyBets1 ? item.fancyBets1 : {}}
                        eventSuspended={item.eventSuspended}
                        eventBlocked={item.eventBlocked}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        homeTeam={item.homeTeamName}
                        awayTeam={item.awayTeamName}
                        marketGroupId={item.fancyBets.marketGroupId}
                        providerId={item.fancyBets.providerId}
                        isLive={item.isLive}
                        backHeader='Back'
                        layHeader='Lay'
                        mainHeader='Fancy Bets1'
                        component='adminfancy'
                        showPin={true}
                        appStore={appStore}
                        parentStore={multiViewComponentStore}
                        fancyOutcomesStore={fancyOutcomesStore} /> : ""}

                      {(item.popular && item.popular.length > 0) || (item.goals && item.goals.length > 0) ?
                        <div className="RDE-sports-nav-tabs">
                          <ul className="RDE-sports-nav-tabs-menu">
                            {item.popular && item.popular.length > 0 ?
                              <li>
                                <div className={"RDE-sports-nav-tabs-menu__item" + ((item.showTab === "popular" || ((item.popular && item.popular.length > 0) && item.goals == undefined)) ? ' active' : '')} onClick={multiViewComponentStore.selectTab.bind(multiViewComponentStore, 'popular', index)}>
                                  <span className="RDE-skewTxt">Popular</span>
                                </div>
                              </li> : ''}
                            {item.goals && item.goals.length > 0 ? <li>
                              {item.sportId === 1 || item.sportId === 4 ?
                                <div className={"RDE-sports-nav-tabs-menu__item" + ((item.showTab === "goal" || ((item.goals && item.goals.length > 0) && item.popular == undefined)) ? ' active' : '')} onClick={multiViewComponentStore.selectTab.bind(multiViewComponentStore, 'goal', index)}>
                                  <span className="RDE-skewTxt">{item.sportId === 1 ? 'Goals' : 'Tied match'}</span>
                                </div> : ''}
                            </li> : ''}
                          </ul>
                        </div>
                        : ''}
                      {item.showTab === "popular" ? <Popular data={item.popular ? item.popular : []}
                        eventSuspended={item.eventSuspended}
                        eventName={item.eventName}
                        eventId={item.eventId}
                        appStore={appStore}
                        parentStore={multiViewComponentStore}
                        pLStatusChanged={pLStatusChanged}
                        popularStore={popularStore} /> :
                        <Popular data={item.goals ? item.goals : []}
                          eventSuspended={item.eventSuspended}
                          eventName={item.eventName}
                          eventId={item.eventId}
                          appStore={appStore}
                          parentStore={multiViewComponentStore}
                          pLStatusChanged={pLStatusChanged}
                          popularStore={popularStore} />}
                    </div>
                  </Collapse>
                </div>
              })
            }
          </div>
          {multiViewComponentStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
            Currently data is not available.
                            </div> : ''}
          {multiViewComponentStore.showLoader ? <div className="RDE-loader">
            <img src={imageUrls.loaderIcon} alt="loading...!" />
          </div> : ''}
        </div>
    )
  }
}
export default observer(MultiViewComponent);
