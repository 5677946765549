import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import history from '../../../history';
import HighlightEvents from '../highlightevents/highlightEvents';
import HighlightEventsStore from '../highlightevents/highlightEventsStore';
import imageUrls from '../../../sharedFiles/imageUrls';

const highlightEventsStore = new HighlightEventsStore();
class LeaguePageComponent extends Component {
    componentWillMount() {
      this.props.appStore.currentPath="league";
      if(this.props.match){
          if(this.props.match.params.sportName && this.props.match.params.sportName !== '' && this.props.match.params.sportName !== null){
              this.props.leaguePageComponentStore.sportName= this.props.match.params.sportName;
          }
          if(this.props.match.params.sportId && this.props.match.params.sportId !== '' && this.props.match.params.sportId !== null){
              this.props.leaguePageComponentStore.sportId= this.props.match.params.sportId;
          }
          if(this.props.match.params.leagueId && this.props.match.params.leagueId !== '' && this.props.match.params.leagueId !== null){
              this.props.leaguePageComponentStore.leagueId= this.props.match.params.leagueId;
          }
          if(this.props.match.params.leagueName && this.props.match.params.leagueName !== '' && this.props.match.params.leagueName !== null){
              this.props.leaguePageComponentStore.leagueName= this.props.match.params.leagueName;
          }
      }

      this.props.leaguePageComponentStore.getLeagueEvent();
    }
    componentWillReceiveProps(nextProps) { 
      if(this.props){
        if(nextProps.sportId && 
           nextProps.sportId !=='' && 
           nextProps.sportId !== null && 
           nextProps.sportId !==this.props.sportId){
          //this.props.history.push('/sports/'+this.props.sportName+'/'+this.props.sportId);
        }
      } 
    }
    /**
    * This method is used to navigate to other pages.
    * @param {string} page- contains page to navigate. 
    */
    navigateTo(page){
      switch(page){
        case 'home':
           this.props.history.push('/');
           // this.props.appStore.goToHome();
           break;
        case 'sport':
          this.props.history.push('/sports/'+this.props.match.params.sportName+'/'+this.props.match.params.sportId);
          break;
        case 'all':
          this.props.history.push('/sports/'+this.props.match.params.sportName+'/'+this.props.match.params.sportId+'/all');
      }
    }
    render() {
        const {leaguePageComponentStore,appStore,sportId,sportName} = this.props;
        const events = leaguePageComponentStore.leagueEvents;
        return (
        //   <div className="RDE-sportsPage RDE-soccer">         
        //     <div className="RDE-sportsPage-content">
              <div className="RDE-sportsPage-leagueView"> 
                <div className="RDE-sportsPage-breadcrumb">
                  <span onClick={this.navigateTo.bind(this,'home')}>Home</span> / 
                  <span onClick={this.navigateTo.bind(this,'sport')}>{leaguePageComponentStore.sportName}</span> / 
                  <span onClick={this.navigateTo.bind(this,'all')}>All {leaguePageComponentStore.sportName}</span>
                </div>
                <div className="RDE-sportsPage-leagueHeader">
                  {leaguePageComponentStore.leagueName}
                </div>   
                <Route render={(props)=><HighlightEvents {...props} events={events} 
                               componentType='leaguecomponent'
                               appStore={appStore}
                               highlightEventsStore={highlightEventsStore}
                               sportId={sportId}
                               sportName={sportName}/>}/>
                {leaguePageComponentStore.showErrorMsg?<div className="RDE-noDataMessageBox">
              Currently data is not available for this League.
                            </div>:''}
              {leaguePageComponentStore.showLoader?<div className="RDE-loader">
                                <img src={imageUrls.loaderIcon} alt="loading...!" />
                            </div>:''}                     
              </div>          
              
        //     </div>
        //   </div>
        )
    }
}
export default observer(LeaguePageComponent);
