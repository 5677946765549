import React, { Component } from 'react';
import { observer } from 'mobx-react';

class MyMarkets extends Component {
    constructor(props) {
        super(props);
        this.handleEventClick = this.handleEventClick.bind(this);
    }
    componentDidMount() {
        this.props.appStore.currentPath = "mymarkets";
        this.props.myMarketsStore.getMyMarketsInfo();
    }
    handleEventClick(sportId, sportName, eventId) {
        const { appStore } = this.props;
        appStore.sportName =sportName;
        appStore.sportId =sportId;
        appStore.eventViewId =eventId;
        this.props.history.push('/sports/'+sportName +'/'+sportId+'/event/' +eventId);
    }
    render() {
        const { myMarketsStore } = this.props;
        var self = this;
        return (
            <div className="RDE-myMarketsPage">
                <div className="RDE-mainContent">
                    <div class="RDE-sportsPage-leagueHeader">My Markets</div>
                    {myMarketsStore.myMarketsInfo.map(function (item, index) {
                        return (
                            <div class="RDE-accordion RDE-singleLineOutcomeItem" key={index}>
                                <div class="RDE-singleLineOutcomeItem__OutcomeContainer"
                                    onClick={() => self.handleEventClick(item.sportId, item.sportName, item.eventId)}>
                                    <div class="RDE-singleLineOutcomeItem__outComeDetails">
                                        <div class="RDE-singleLineOutcomeItem__outComeName">{item.eventName}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default observer(MyMarkets);