import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dropdown } from "react-bootstrap";
import EventBox from "./eventbox";

class OpenBets extends Component {
    constructor(props) {
        super(props);
        this.props.openbetsStore.getOpenbets(this.props.appStore);
    }
    componentDidMount() {
        var self = this;
        window.scrollTo(0,0)
        self.props.openbetsStore.isMounted = true;
        // this.props.openbetsStore.getOpenbets(this.props.appStore);
        this.props.appStore.isBetEditMode = false;
        var timeout = setTimeout(function reCallService() {
            if (self.props.openbetsStore.isMounted) {
                self.props.openbetsStore.getOpenbets(self.props.appStore);
                setTimeout(reCallService, 3 * 1000);
            }
            else {
                clearTimeout(timeout);
            }
        }, 3 * 1000);
    }
    componentWillUnmount() {
        this.props.openbetsStore.isMounted = false;
    }
    /**
     * 
     * @param {object holds the category information} categry 
     * @param {string holds the name of the category} categoryName 
     */
    renderCategory(categry, categoryName) {
        let category = '';
        if (categry && categry.backBets && categry.backBets.length > 0 ||
            categry && categry.layBets && categry.layBets.length > 0) {
            if (categoryName === "matchedBets") {
                category = <div className="RDE-openbets-categoryHeader"> Matched </div>;
            }
            else if (categoryName === "unMatchedBets") {
                category = <div className="RDE-openbets-categoryHeader"> UnMatched </div>;
            }
        }
        return category;
    }
    /**
     * 
     * @param {string- holds the name of the bet type.} betTypeName 
     */
    renderBetType(betTypeName) {
        let betType = "";
        if (betTypeName === "backBets") {
            betType = <div className="RDE-betslip__backOdds-header">Back (Bet For)</div>;
        }
        else if (betTypeName === "layBets") {
            betType = <div className="RDE-betslip__layOdds-header">lay (Bet For)</div>;
        }
        return betType;
    }
    goBackClick() {
        this.props.history.push(this.props.history.go(-1));
    }
    naviGateTOEventView(appStore) {

        this.props.history.push('/sports/' + appStore.sportName + '/' + appStore.sportId + '/event/' + appStore.eventViewId);
    }
    render() {
        const { openbetsStore, appStore } = this.props;
        var self = this;
        return (
            <div className="RDE-mainContent">
                <div className="space-5"></div>
                <div className="RDE-betslip RDE-openbets">
                    <div className="RDE-openbets-header">
                        <div className="grid-middle-noGutter">
                            <div className="col-12 text-center">
                                <div className="RDE-backBtn" onClick={self.goBackClick.bind(this)}>
                                    <i className="RDE-icon-go-back"></i>
                                </div>
                                <div className="RDE-dashboard-header__title truncate">Open Bets</div>
                            </div>
                        </div>
                    </div>
                    <div className="RDE-openbets-content">
                        {openbetsStore.openbetsData.length > 0 ?
                            <>
                                <Dropdown className="RDE-DB-dropdownDefault">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {appStore.selectedOpenBetsEvent.eventName}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            {openbetsStore.openbetsData.map(function (openBet, openBetIndex) {
                                                //if (openBet.eventId !== openbetsStore.selectOpenbetsEvent.eventId) {
                                                return (
                                                    <li key={"openbets_events_" + openBetIndex}
                                                        onClick={openbetsStore.selectOpenbetsEvent.bind(openbetsStore, openBet.eventId, openBet.eventName, appStore)}>
                                                        <Dropdown.Item eventKey={openBet.eventId}>{openBet.eventName}</Dropdown.Item>
                                                    </li>
                                                )
                                                // }
                                            })
                                            }
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {openbetsStore.openbetsData.map(function (openBet, openBetIndex) {
                                    if (openBet.eventId === appStore.selectedOpenBetsEvent.eventId) {
                                        return (
                                            <React.Fragment key={openBetIndex+"opneBets"}>
                                                {Object.keys(openBet).map(function (categoryName, categoryIndex) {
                                                    let category = openBet[categoryName];
                                                    if (category && typeof category === 'object' && category.constructor === Object
                                                        && Object.keys(category).length > 0) {
                                                           // console.log(category,"cate")
                                                        return (
                                                            <>
                                                             
                                                                    {/* {self.renderCategory(category, categoryName)} */}
                                                                    {categoryName === "unMatchedBets" && (category.bets.length > 0) && <div className="RDE-betslip__stakeReturnsBox RDE-openbets__stakeReturnsBox"><button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-small bg-red"
                                                                        onClick={openbetsStore.cancelAllBets.bind(openbetsStore, appStore)}>Cancel All Bets
                                                                    </button></div>}
                                                              
                                                                {Object.keys(category).map(function (betTypeName, betTypeIndex) {
                                                                    let betData = category[betTypeName];
                                                                    if (Array.isArray(betData) && betData.length > 0) {
                                                                        return (
                                                                            <React.Fragment key={betTypeIndex+"bet_type"}>
                                                                                {self.renderBetType(betTypeName)}
                                                                                {betData.map(function (bet, betIndex) {
                                                                                    return (
                                                                                        <>
                                                                                            <EventBox key={"eventBox" + betIndex} betInfo={bet} openbetsStore={openbetsStore} />
                                                                                            {categoryName === "unMatchedBets" &&
                                                                                                <div className="RDE-betslip__stakeReturnsBox">
                                                                                                    <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-small bg-red"
                                                                                                        onClick={openbetsStore.cancelBets.bind(openbetsStore, bet.betId, appStore)}>Cancel Bet
                                                                                                    </button>
                                                                                                    <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-small"
                                                                                                        onClick={openbetsStore.editOpenBet.bind(openbetsStore, appStore, bet, self)}>Edit Bet</button>
                                                                                                </div>}
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                })}
                                                            </>
                                                        )
                                                    }
                                                })}

                                                <div>
                                                    {openbetsStore.openBetsErr.length > 0 ? <div className="RDE-betslip-errorMsgBox">{openbetsStore.openBetsErr}</div> : ''}
                                                    {/* {(openBet.unMatchedBets && openBet.unMatchedBets.layBets && openBet.unMatchedBets.layBets.length > 0) ||
                                                (openBet.unMatchedBets && openBet.unMatchedBets.backBets && openBet.unMatchedBets.backBets.length > 0) ?
                                                <div className="RDE-betslip__stakeReturnsBox">
                                                    {!openbetsStore.editOpenBets ? <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md bg-red"
                                                        onClick={openbetsStore.cancelAllBets.bind(openbetsStore,appStore)}>Cancel Bets</button> :
                                                        <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md RDE-betslip-header__clearAll"
                                                            onClick={openbetsStore.resetOpen.bind(openbetsStore)}>Reset</button>}
                                                    {!openbetsStore.editOpenBets ? <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md"
                                                        onClick={openbetsStore.editOpenBet.bind(openbetsStore,appStore,self)}>Edit Bets</button> :
                                                        <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md RDE-betslip-header__clearAll"
                                                            onClick={openbetsStore.updateOpenbets.bind(openbetsStore,appStore)}>Update Bets</button>}
                                                </div> : ''} */}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </>
                            : <div>Currently there are no open bets.</div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(OpenBets);