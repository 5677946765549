import { extendObservable } from "mobx";

class FancyOutcomesStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore:{},
      parentStore:{},
      component:"",
      accordionOpenIndex:[0]
    });
  }
 
  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, marketId, providerId, isPinned, eventProps){
    this.appStore.saveEventPin(eventId, marketId, '', providerId, isPinned, (success)=>{
      eventProps.data.isPinned = !eventProps.data.isPinned;
      this.parentStore.updateData();
    })
  }

  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
}

export default FancyOutcomesStore;
