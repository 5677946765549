import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageURLs from '../../sharedFiles/imageUrls';
import logo from '../../assets/images/american-exchange-logo.svg';

class ForgotPassword extends Component {
    componentWillMount() {
      if(this.props.match && this.props.match.params.token !== null ){
            this.props.forgotPasswordStore.token = this.props.match.params.token;
            
        }
    }
    gotoLogin(){
      this.props.history.push('/login')
    }
    componentDidMount(){
    }
    componentWillUnmount(){
    }
    render() {
      const {forgotPasswordStore, appStore} = this.props;
        return (
        <div className="RDE-loginSignupPage">
          <div className="RDE-modal">
          {/* Change Password Popup */}
          <div className="RDE-changePassword-modal RDE-loginSignup-modal">
          <div className="RDE-loginSignup-modal-content">
              <div className="RDE-loginSignup-form">
                  <div className="RDE-loginSignupBox">
                        <div className="RDE-loginLogo">
                            <img src={imageURLs.xchangeLogo} alt="logo" />
                        </div>
                        <div className="RDE-loginSignupBox-header">
                            Change Password
                        </div>
                            {!forgotPasswordStore.showForgotSuccess?<div className="RDE-loginSignupBox-content">
                                <div className="RDE-formGroup">
                                    {/* <input type="text" className="RDE-formControl" placeholder="Username/Email" /> */}
                                    <input type="password" className="RDE-formControl" placeholder="New Password" autoComplete="off" value={forgotPasswordStore.resetFields.newPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'newPassword')}/>
                                </div>
                                <div className="RDE-formControl-errorMessage">
                                {forgotPasswordStore.resetFieldsError.newPassword} 
                                </div>
                                <div className="RDE-formGroup">
                                <input type="password" className="RDE-formControl" placeholder="Confirm Password" autoComplete="off" value={forgotPasswordStore.resetFields.confirmPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'confirmPassword')}/>
                                </div>
                                <div className="RDE-formControl-errorMessage">
                                {forgotPasswordStore.resetFieldsError.confirmPassword} 
                                </div>
                                <div className="space-5"></div>
                                <div className="RDE-formGroup text-center">
                                    <button className="btn RDE-btn-primary" onClick={forgotPasswordStore.submitReset.bind(forgotPasswordStore)}>Submit</button>
                                </div>
                            </div>:<div>{forgotPasswordStore.message}</div>}
                            <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                <div className="RDE-loginSignupBox-footer__goBack" onClick={this.gotoLogin.bind(this)}>
                                    <i className="RDE-icon-go-back"></i>
                                    <span className="RDE-forgotLink">Back to Login</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
          </div>
        </div>
        )
    }
}
export default observer(ForgotPassword);
