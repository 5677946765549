import React, { Component } from 'react';
import { observer } from 'mobx-react';

class TermsConditions extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount(){}
  componentDidMount() {}

  componentWillUnmount() {}
  onInitialFetch(groups) {}

  componentWillReceiveProps(nextProps){
  }

  render() {
    return (   
        <div className="RDE-privacyTermsAndConditions">
          <div className="RDE-privacyTermsAndConditions-header">
              <div className="RDE-privacyTermsAndConditions-container">
                  <div className="RDE-privacyTermsAndConditions-header__title">Terms and Conditions</div>
              </div>
          </div>
          <div className="RDE-privacyTermsAndConditions-content">
              <div className="RDE-privacyTermsAndConditions-container">
                  <div className="RDE-counterList__title">General Introduction, definitions and stipulations</div>
                  <ol className="RDE-counterList">
                      <li>
                          <div className="RDE-privacyTermsAndConditions-heading">Terms and Conditions</div>
                          <p>
                                  Victory Exchange is operated by Victory Infotech Limited a company.
                          </p>
                          <p>
                                   Email: info@victoryexch.com; website: www.victoryexch.com.
                          </p>
                          <p>
                              
                          </p>
                      </li>
                      <li>
                          <div>Victory Exchange Rules</div>
                          <p>
                                  The “Victory Exchange Rules” are the terms and conditions constituting the complete, final and exclusive agreement between the Account Holder (“you”) and Victory Exchange, and governing the contractual relationship between Victory Exchange and the Account Holder. The Victory Exchange Rules supersede and merge all prior agreements, representations and understandings between the Account Holder and Victory Exchange. By opening a Victory Exchange Account, registering, logging-in, using the Services, participating in the games, or accepting any Prize, a visitor or an Account Holder fully understands and agrees:
                          </p>
                          <p>
                                  <p>1. to become a party to the Victory Exchange Rules,</p>
                                  <p>2. that he/she has read, understood and will be adhering to these Terms and Conditions, and</p>
                                  <p>3. that he/she shall abide by all its terms.</p>
                          </p>
                          
                          
                          <p>
                              <b>
                                      Victory Exchange may update, amend, edit and supplement the Victory Exchange Rules at any time. Any substantial amendment to the Victory Exchange Rules shall be notified in advance by Victory Exchange to the Account Holder, before coming into effect and will require your consent. If you do not agree with the amendment(s) to the Victory Exchange rules, you will not be permitted to continue using the Services.
                              </b>
                          </p>
                          <p>
                              <b>
                                      All references in the Victory Exchange Rules to the singular shall include the plural and vice versa. All references to “the” shall include “a/an” and vice versa.
                              </b>
                          </p>
                      </li>
                      <li>
                          <div>Account Holder</div>
                          <p>An “Account Holder” is an individual having a contractual relationship with Victory Exchange by creating a Victory Exchange Account on the Website.</p>
                      </li>
                      <li>
                          <div>Victory Exchange Account</div>
                          <p>
                              A “Victory Exchange Account” is an account held by an Account Holder, for bona fide transactions, with a strict aim to establish a normal commercial relationship with Victory Exchange and with the strict purpose of conducting betting and other gaming and gambling transactions.
                          </p>
                      </li>
                      <li>
                          <div>Contract</div>
                          <p>
                                  The “Contract” is the contractual relationship between Victory Exchange and an Account Holder and shall be constituted under and governed by the Victory Exchange Rules, i.e. the “Terms and Conditions”
                          </p>
                      </li>
                      <li>
                              The “Governing Authorities” are the relevant authorities for Curacao
                      </li>
                      <li>
                              The “Governing Law” is the law of Curacao.
                              <ol>
                                  <li>
                                          The <b>“Place of the Contract”</b> is Curacao.
                                  </li>
                                  <li>
                                          The <b>“Regulator”</b> is the Curacao Gaming Authority (Gaming Services Provider N.V), which is a regulatory authority established by Curacao law to govern all forms of gaming in Curacao. For more information, please go to “https://www.curacao-egaming.com/
                                  </li>
                                  <li>
                                          The <b>“Website”</b> is the internet gateway accessible through the internet address www.victoryexch.com where all current and relevant information regarding Victory Exchange’s operations is published, and through which the Services are provided to the Account Holders.
                                  </li>
                                  <li>
                                          The <b>“Services”</b> are the gaming and betting offers provided by Victory Exchange to the Account Holder through the Website.
                                  </li>
                                  <li>
                                          The “Gaming and Betting Duty” refers to the special tax applicable on gaming and betting in Curacao.
                                  </li>
                                  <li>
                                      A <b>"Card"</b> refers to all types of cards with a function of “payment”, “charge”, “debit”, “credit”, “virtual” and/or similar.
                                  </li>
                                  <li>
                                          A <b>“Payment Solution Provider”</b> is an intermediary acting as a payment agent for various different payment methods.
                                  </li>
                                  <li>
                                          A <b>Financial Institution”</b> is a bank and/or other institution regulated by an applicable domestic financial services Act or similar.
                                  </li>
                                  <li>
                                          <b>“Force Majeure” </b> refers to any occurrence or condition beyond one’s reasonable control which leads to a delay or default in the performance of the affected party’s contractual obligation and shall, for the purposes of the Victory Exchange Rules, include Acts of God, government restrictions (including the denial or cancellation of any necessary licence where such denial or cancellation is made through no fault of the affected party), wars, outbreak of hostilities, riots, civil disturbances, insurrections, acts of terrorism, fire, explosions, floods, theft, malicious damage, strikes, lockouts, and/or any other cause beyond the reasonable control of the party whose performance is affected.
                                  </li>
                                  <li>
                                          <b>“Client Application” </b> shall mean the web browser application opened by the Account Holder in order to use the Services.
                                  </li>
                                  <li>
                                          <b> “Software” </b> shall mean all software used by Victory Exchange to offer and/or operate the Services and/or run the Client Application.
                                  </li>
                                  <li>
                                          <b> A “Prize” </b> is an amount, a bonus or a reward that can be won by the Account Holder.
                                  </li>
                                  <li>
                                          <b> An “Inactive Account” </b> is a Victory Exchange Account which has not recorded any log-in and/or logout for a period exceeding 12 consecutive months.
                                  </li>
                                  <li>
                                           All trademarks, service marks and trade names as well as images, graphics, text, concepts or methodologies (collectively the “Intellectual Assets”) found on the Website, the Client Application and the material contained therein are the exclusive property of Victory Exchange and/or Victory Exchange’s suppliers and partners. The Account Holder is not entitled to exploit or otherwise use any Intellectual Asset for whatever purpose, except for what is allowed by law.
                                  </li>
                              </ol>
                      </li>
                      <li>
                          <div>Licences</div>
                          <p>
                                  Victory Exchange is duly licensed in Curacao and is regulated by the Gaming Services Provider N.V to provide fixed odds betting services: Licence No sublicense with our company under License no 365/JAZ Sub-License GLH-OCCHKTW0707072017, issued on 06/07/2017 and is valid till July 2018 and will be automatically renewed for 12 months.
                          </p>
                      </li>
                      <li>
                          <div>Account Rules</div>
                          <ol>
                              <li>
                                  <div>Creation of a Victory Exchange Bet Account</div>
                                  <ol>
                                      <li>
                                              An individual cannot participate in a game for money unless that individual is an Account Holder. To be registered as a player, an individual must register personally and submit an application for registration. The following information must be provided:
                                      </li>
                                      <li>
                                              date of birth and provide valid identification showing that the player is over eighteen (18) years of age or the applicable legal age of majority as stipulated in the jurisdiction of your residence (identification documents which must be submitted include: copy of a valid identity card, passport or driving licence);
                                      </li>
                                      <li>
                                              player’s first and last name;
                                      </li>
                                      <li>player’s full residential address;</li>
                                      <li>player’s valid email address; and</li>
                                      <li>a username and a password</li>
                                  </ol>
                              </li>
                              <li>
                                 
                                  An individual applying to become an Account Holder warrants and represents not to be under the age of eighteen (18) years. Minors cannot register as a player and cannot hold a Victory Exchange Account. Victory Exchange reserves the right to request additional proof of age and perform additional checks in order to verify the information provided. A Victory Exchange Account may be suspended until satisfactory proof of age is provided.
                                  
                              </li>
                              <li>
                                      An individual applying to become an Account Holder furthermore warrants and represents:
                                      <ol>
                                          <li>
                                                to be a physical person (a legal entity will not be accepted as an Account Holder);
                                          </li>
                                          <li>
                                                  not to be a resident of Afghanistan, Australia, Denmark, Estonia, Ethiopia, France and other French territories, Hong Kong, Iran, Iraq, Italy, Jordan, Kuwait, North Korea, Pakistan, Philippines, Spain, Syria, Turkey, United States of America and other U.S. territories, or Yemen (please be aware of particular country exclusions for Casino – Games – Live Casino, Poker and Bingo);
                                          </li>
                                          <li>
                                                  not to be a professional player in any sport, competition or league where Victory Exchange offers betting;
                                          </li>
                                          <li>
                                                  not to be restricted by limited legal capacity;
                                          </li>
                                          <li>
                                                  not to be acting on behalf of another party;
                                          </li>
                                          <li>
                                                  not to be classified as a compulsive problem gambler, and/or be included (whether voluntarily or involuntarily) on any register or database of excluded players;
                                          </li>
                                          <li>
                                                not to be depositing monies originating from criminal and/or other illegal activities;
                                          </li>
                                          <li>
                                                  not to be depositing monies through a Card which the Account Holder is not authorised to use and/or utilizing a Card in a jurisdiction in which betting and gaming are prohibited;
                                          </li>
                                          <li>
                                                  not to be conducting criminal activities whereby a Victory Exchange Account is directly or indirectly involved;
                                          </li>
                                          <li>
                                                  not to be holding an active account with another legal entity which is part of the same group as Victory Exchange, meaning other entities directly or indirectly controlled by Victory Infotech Limited.
                                          </li>
                                          <li>
                                                  not to use the Services if it is illegal in his/her country of residence or otherwise restricted for him/her to open a gaming account, purchase or use services from Victory Exchange and/or otherwise participate in the games offered. It is the Account Holder’s responsibility to ensure his/her use of Victory Exchange’s Website and Services is legal;
                                          </li>
                                          <li>
                                                  not to find the Website or the Services offensive, objectionable, unfair, nor indecent; and
  To maintain his/her Victory Exchange Account details up-to-date in terms of the following: first and last name, country of residence, valid email address and phone number.
                                          </li>                                        
                                      </ol>
                              </li>
                          </ol>
                      </li>
                      <li>
                          <div>Victory Exchange, meaning other entities directly or indirectly controlled by Victory Infotech Limited;</div>
                          <p>
                                  not to use the Services if it is illegal in his/her country of residence or otherwise restricted for him/her to open a gaming account, purchase or use services from Victory Exchange and/or otherwise participate in the games offered. It is the Account Holder’s responsibility to ensure his/her use of Victory Exchange’s Website and Services is legal;
                          </p>
                          <ol>
                              <li>
                                      not to find the Website or the Services offensive, objectionable, unfair, nor indecent; and
                              </li>
                              <li>
                                      To maintain his/her Victory Exchange Account details up-to-date in terms of the following: first and last name, country of residence, valid email address and phone number.
                              </li>
                          </ol>
                      </li>
                      <li>
                          <div>An individual applying for a Victory Exchange Account acknowledges and accepts:</div>
                          <ol>
                              <li>
                                      all definitions and stipulations as stated under the Victory Exchange Rules;
                              </li>
                              <li>
                                      the Victory Exchange Rules as currently published on the Website, as well as any possible future changes to the Victory Exchange Rules;
                              </li>
                              <li>
                                      that the Place of contract is Curacao;
                              </li>
                              <li>
                                      that the Contract is governed by: the Victory Exchange Rules as published in English;
                              </li>
                              <li>
                                      any regulatory provisions or other decisions made from time to time by the Governing Authorities; and
                              </li>
                              <li>
                                      the Governing Law of the Place of the Contract.
                              </li>
                          </ol>
                      </li>
                      <li>
                          <div>In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will be cancelled.</div>
                      </li>
                      <li>
                          <div>If the same IP is logged in multiple user accounts, the bets in question can be removed just on the basis of this. Final decision will be made by Victory Exchange in regards to this.</div>
                      </li>
                      <li>
                          <div>Victory Exchange reserves the right when fraudulent activities have taken place to either A) Void the bet in question, or B) To Void all bets in the market in question. Victory Exchange will remove either A or B depending on whichever amount is higher. Final decision will be taken by Victory Exchange in this regard.</div>
                      </li>
                  </ol>
              </div>
          </div>
      </div>
    );
  }
}

export default observer(TermsConditions);
