import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import imageUrls from '../../../../../sharedFiles/imageUrls';
import { Collapse } from "react-bootstrap";

class BetHistory extends Component {
    componentWillMount() {
        this.props.betHistoryStore.resetFields();
    }
    componentWillReceiveProps(nextProps) { }
    handleIconClick(store,index) {
        if(index>=0){
            var eventIndex = store.accordionOpenIndex.indexOf(index);
            if(eventIndex===-1){
                store.accordionOpenIndex.push(index);
            }else{
                store.accordionOpenIndex.splice(eventIndex,1);
            }
          }
    }
    render() {
        var self = this;
        const { betHistoryStore } = this.props;
        return (
            <div className="RDE-DB-myBets-filters">
                <div className="grid-noGutter">
                    <div className="col-12">
                        <div className="RDE-DB-myBets-filter__dropdown">
                            <span className="RDE-DB-myBets-filters__label">Sport Name:&nbsp;</span>
                            <Dropdown className="RDE-DB-dropdownDefault dropdown">
                                <Dropdown.Toggle variant="dropdown-toggle btn btn-success" id="dropdown-basic">
                                    {betHistoryStore.betStatus}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Open bets')}><Dropdown.Item eventKey="openbets">Open bets</Dropdown.Item></li>
                                        <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Settled bets')}><Dropdown.Item eventKey="settled">Settled bets</Dropdown.Item></li>
                                        <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Suspended')}><Dropdown.Item eventKey="suspended">Suspended</Dropdown.Item></li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                  
                </div>

                <div className="RDE-hr"></div>

                <div className="grid">
                    {/* <div className="col-12 mb-5">
                        <span className="RDE-DB-myBets-filters__label">Period:</span>
                    </div> */}
                    <div className="col-12">
                        <div className="RDE-DB-dateBox-container mb-5">
                            <div className="RDE-DB-dateBox">
                                <span className="RDE-DB-myBets-filters__label">From:&nbsp;</span>
                                <div className="RDE-DB-myBets-filters__datePicker">
                                        <DatePicker selected={betHistoryStore.fromDate}
                                            dateFormat="dd/MM/yyyy"
                                            popperPlacement="bottom-start"
                                            onChange={betHistoryStore.selectFromDate.bind(betHistoryStore)}
                                            className="RDE-DB-datePickerInput"
                                            maxDate={new Date()} />
                                </div>
                            </div>
                            <div className="RDE-DB-dateBox">
                                <span className="RDE-DB-myBets-filters__label">To:&nbsp;</span>
                                <div className="RDE-DB-myBets-filters__datePicker">
                                        <DatePicker selected={betHistoryStore.toDate}
                                            selectsEnd
                                            dateFormat="dd/MM/yyyy"
                                            popperPlacement="bottom-end"
                                            onChange={betHistoryStore.selectToDate.bind(betHistoryStore)}
                                            className="RDE-DB-datePickerInput"
                                            maxDate={new Date()} />
                                </div>
                            </div>
                        </div>
                        <div className="RDE-DB-datePickerErrorMessage text-center">{betHistoryStore.dateErrMsg}</div>

                        <div className="space-2"></div>
                        <div className="grid-noGutter-middle">
                            <div className="col-12 text-center">
                                <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={betHistoryStore.getHistory.bind(betHistoryStore)}>Get History</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RDE-hr"></div>
                <div className="grid-noGutter">
                    <div className="col-12">
                        <ul className="RDE-DB-myBets-filters__todayYesterdayList">
                            <li className={betHistoryStore.selectedOption === "today" ? "active" : ""} onClick={betHistoryStore.selectTodayYesterday.bind(betHistoryStore, 'today')}>Just For Today</li>
                            <li className={betHistoryStore.selectedOption === "yesterday" ? "active" : ""} onClick={betHistoryStore.selectTodayYesterday.bind(betHistoryStore, 'yesterday')}>From Yesterday</li>
                        </ul>
                    </div>
                </div>
                <div className="space-5"></div>
                {!betHistoryStore.showErrorMsg && !betHistoryStore.showLoader ? <div className="RDE-DB-myBets__betHistory"> 
                    {betHistoryStore.userBetDetails.map(function (item, index) {
                        return (
                            <div className={betHistoryStore.accordionOpenIndex.indexOf(index) === -1?"RDE-accordion RDE-myBets collapsed":"RDE-accordion RDE-myBets"} >
                            <div className="RDE-accordion-header" onClick={()=>self.handleIconClick(betHistoryStore,index)}>
                                <div className="RDE-myBets__headerDetails">
                                    <div className="RDE-myBets__headerContent RDE-myBets__heading">
                                        <span className="RDE-headingText">Market:</span>
                                        <span className="RDE-marketText">{item.bMarket && item.bMarket.length > 0 ? item.bMarket : '--'}</span>
                                    </div>
                                    {/* <div className="RDE-myBets__expandArrow">
                                        <i className="RDE-icon-down-arrow"></i>
                                    </div> */}
                                </div>
                                </div>
                                <Collapse in={betHistoryStore.accordionOpenIndex.indexOf(index) === -1}>
                                    <div className="RDE-accordion-body collapse">
                                        <div className="RDE-myBets__content">
                                            <div className="RDE-myBets__contentBox">
                                                <div className="RDE-myBets__contentBox-left">
                                                    <div className="RDE-label">Bet ID:<span>{item.betSlipId}</span></div>
                                                    {/* <span className="RDE-labelRight-text">{item.betSlipId}</span> */}
                                                    {/* <div className="RDE-label">Bet ID:<span>{item.betSlipId}</span></div> */}
                                                    <div className="RDE-label">PL ID: <span>{item.profileId}</span></div>
                                                    <div className="RDE-label">Selection: <span>{item.bSelection && item.bSelection.length > 0 ? item.bSelection : '--'}</span></div>
                                                    <div className="RDE-label">Avg.Odds matched: <span>{item.totalMatched > 0 ? item.totalMatched : "--"}</span></div>
                                                    <div className="RDE-label RDE-label-fullWidth">Bet placed: <span>{item.betPlaced && item.betPlaced.length > 0 ? item.betPlaced : '--'}</span></div>
                                                </div>
                                                <div className="RDE-myBets__contentBox-right text-right">
                                                    <div className="RDE-label">Odds : <span>{item.betOdds > 0 ? item.betOdds : "--"}</span></div>
                                                    <div className="RDE-label">Type: <span>{item.betType && item.betType.length > 0 ? item.betType : '--'}</span></div>
                                                    <div className="RDE-label">Stake: <span>{item.betStake > 0 ? item.betStake : '--'}</span></div>
                                                    {/* <span className="RDE-label">Odds req:</span>
                                                    <span className="RDE-labelRight-text">{item.betOdds > 0 ? item.betOdds : "--"}</span> */}
                                                </div>
                                                {/* <div className="RDE-myBets__contentBox-right text-right">
                                                    <span className="RDE-label">Selection:</span>
                                                    <span>{item.bSelection && item.bSelection.length > 0 ? item.bSelection : '--'}</span>
                                                </div> */}
                                            </div>
                                            {/* <div className="RDE-myBets__contentBox">
                                                <div className="RDE-myBets__contentBox-left">
                                                    <span className="RDE-label">PL ID:</span>
                                                    <span className="RDE-labelRight-text">{item.profileId}</span>
                                                </div>
                                                <div className="RDE-myBets__contentBox-right text-right">
                                                    <span className="RDE-label">Type:</span>
                                                    <span className="RDE-labelRight-text">{item.betType && item.betType.length > 0 ? item.betType : '--'}</span>
                                                </div>
                                            </div> */}
                                            {/* <div className="RDE-myBets__contentBox">
                                                 <div className="RDE-myBets__contentBox-left">
                                                    <span className="RDE-label">Profit/Loss</span>
                                                    <span className="RDE-labelRight-text">{item.profitLoss}</span>
                                                </div> 
                                                 <div className="RDE-myBets__contentBox-right text-right">
                                                    <span className="RDE-label">Odds req:</span>
                                                    <span className="RDE-labelRight-text">{item.betOdds > 0 ? item.betOdds : "--"}</span>
                                                </div> 
                                                 <div className="RDE-myBets__contentBox-left">
                                                    <span className="RDE-label">Selection:</span>
                                                    <span>{item.bSelection && item.bSelection.length > 0 ? item.bSelection : '--'}</span>
                                                </div>
                                            </div> */}
                                            {/* <div className="RDE-myBets__contentBox">
                                                <div className="RDE-myBets__contentBox-left">
                                                    <span className="RDE-label">Avg.Odds matched:</span>
                                                    <span className="RDE-labelRight-text">{item.totalMatched > 0 ? item.totalMatched : "--"}</span>
                                                </div>
                                                <div className="RDE-myBets__contentBox-right text-right">
                                                    <span className="RDE-label">stake</span>
                                                    <span className="RDE-labelRight-text">{item.betStake > 0 ? item.betStake : '--'}</span>
                                                </div>
                                            </div> */}
                                            {/* <div className="RDE-myBets__contentBox">
                                                <div className="RDE-myBets__contentBox-text">
                                                    <span className="RDE-label">Bet placed:</span>
                                                    <span className="RDE-labelRight-text">{item.betPlaced && item.betPlaced.length > 0 ? item.betPlaced : '--'}</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        )
                    })}
                </div> : ""}
                {/* <div className="RDE-DB-datePickerErrorMessage text-center">{betHistoryStore.dateErrMsg}</div> */}
                {betHistoryStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
                    Currently data is not available for your selection.
                            </div> : ''}
                {betHistoryStore.showLoader ? <div className="RDE-loader">
                    <img src={imageUrls.loaderIcon} alt="loading...!" />
                </div> : ''}
            </div>
        
        )
    }
}
export default observer(BetHistory);
