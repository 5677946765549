import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

class OneClickBet extends Component {
    componentWillMount() {
        this.props.oneClickBetStore.isEdit = false;
        // console.log(!cookie.load("oneConfirm"))
        if (cookie.load("oneConfirm") && cookie.load("oneConfirm") === 'false') {
            this.props.oneClickBetStore.showConfirmation = false;
        }
        this.props.oneClickBetStore.getOneClickValues();
    }
    // componentDidMount() {
    //     var body = document.getElementsByTagName('body')[0];
    //     var except = document.getElementsByClassName('RDE-oneClickBetDropdown1')[0];

    //     body.addEventListener("click", (ev) => {
    //         //   console.log(ev.target.id, "==", ev.target.id === "allowClick")
    //         if (ev.target.id === "allowClick") {

    //         } else {
    //             //this.props.subHeaderStore.showSettings = false;
    //             this.props.appStore.footerSelItem = "";
    //             this.props.appStore.showHideBetSlipSettings('');
    //         }

    //     }, false);
    //     except.addEventListener("click", function (ev) {
    //         // console.log(ev.target.id, "==", ev.target.id === "allowClick")
    //         if (ev.target.id === "allowClick") {

    //         } else {
    //             ev.stopPropagation();
    //         }
    //     }, false);
    // }
    componentWillReceiveProps(nextProps) { }
    render() {
        const { oneClickBetStore,appStore } = this.props;
        return (
           
            <div className="RDE-mobileSubFooter">
                <div className="RDE-oneClickBet">
                    <div className='RDE-oneClickBet__header'>
                        <div className='RDE-oneClickBet__title'>1-Click Bet</div>
                        <div className='RDE-modal-close' onClick={()=>appStore.toggleOneClick()}><i className="RDE-icon-close"></i></div>
                    </div>
                    <div className='RDE-oneClickBet__content'>
                            <div className="RDE-settingsDropdown__stakeSet">
                            <div className='RDE-stakeLabel'>Stake</div>
                                <ul className="RDE-settingsDropdown__stakeSetGroup">
                                    {/* <li className="">
            <div className="RDE-item active">10</div>
        </li> */}
                                    {
                                        oneClickBetStore.stakes.map((stake, index) => {
                                            return <li key={`coin${index}`}>
                                                {!oneClickBetStore.isEdit ? <div onClick={oneClickBetStore.stakeSelected.bind(oneClickBetStore, stake)} className={!oneClickBetStore.isEdit && oneClickBetStore.selectedStake.coinId === stake.coinId ? "RDE-item active" : "RDE-item"}> <div className=" ">{stake.coinValue}</div></div> :
                                                    <div className="RDE-edit"><div className="RDE-skewTxt"><input type="text" className="RDE-formControl" onChange={oneClickBetStore.changeValue.bind(oneClickBetStore, stake.coinId)} value={stake.coinValue} /></div></div>}
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            {oneClickBetStore.isEdit ? <button className="btn RDE-btn-primary" onClick={oneClickBetStore.updateCoins.bind(oneClickBetStore)}>Submit</button> :
                    <button className="btn RDE-btn-primary" onClick={oneClickBetStore.clickEdit.bind(oneClickBetStore)}>Edit</button>}
                {/* <div className="RDE-btnOneClick-close" onClick={subHeaderStore.toggleOneClick.bind(subHeaderStore)}><i className="RDE-icon-close"></i></div> */}

                        {/* <li>
                            <div className="RDE-settingsDropdown__title">Odds :</div>
                            <div className="RDE-checkbox"><input id="OddsHighlights" type="checkbox" checked={settingsStore.isOddsHighlight} onChange={settingsStore.oddsHighlightsChange.bind(settingsStore)} />
                                <label htmlFor="OddsHighlights"></label>
                                <span className="RDE-checkboxLabel">Highlight when odds change</span>
                            </div>
                        </li> */}
                    {/* <div className="space-5"></div> */}
                    </div>
                    {oneClickBetStore.showConfirmation ? <div className="RDE-oneClickBet-infoMsg RDE-arrow">
                <div className="RDE-oneClickBet-infoMsg__header">
                One Click Bet ON
                </div>
                <div className="RDE-oneClickBet-infoMsg__content">
                <div className="">Stake selected will be placed immediately once you click on the market odds.</div>
                <div className="RDE-oneClickBet-infoMsg__attentionTxt">Attention: Back / Lay at your own risk</div>
                </div>
                <div className="RDE-oneClickBet-infoMsg__footer">
                <button className="btn RDE-btn-primary" onClick={oneClickBetStore.clickConfirmation.bind(oneClickBetStore)}>OK</button>
                </div>
            </div>: ""}
                </div>
            </div>
        )
    }
}
export default observer(OneClickBet);
