import React, { Component } from 'react';
import { extendObservable } from "mobx";
import { observer } from 'mobx-react';
import { formatDate } from '../../sharedFiles/helper';
import { Collapse } from "react-bootstrap";
import imageUrls from '../../sharedFiles/imageUrls';
import OddsComponent from '../oddscomponent/oddsComponent';
import BetSlipComponent from '../../sharedComponents/betslipcomponent/BetSlipComponent';
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";
class EventComponent extends Component {
    constructor(props) {
        super(props);
        extendObservable(this, {
            updatedOdds: {},
            isMounted: true,
            oddsUpdated: false,
            showOdds: false
        });
        this.setOutcomeAccordionIndex = this.setOutcomeAccordionIndex.bind(this);
    }

    componentWillMount() {
        this.props.eventComponentStore.appStore = this.props.appStore;
        this.props.appStore.currentPath = "eventview";
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.event.isBlocked !== nextProps.event.isBlocked) {

        }
        if (this.props.event.isSuspended !== nextProps.event.isSuspended) {

        }
        if (this.props.betSlipStatusChange !== nextProps.betSlipStatusChange) {
            // console.log("betslip status changed======Event component")
        }
    }
    componentDidMount() {
        var self = this;
        setTimeout(function reCallService() {
            if (self.isMounted) {
                self.getOdds();
                setTimeout(reCallService, 1000);
            }
        }, 1000);
    }
    componentWillUnmount() {
        this.isMounted = false;
    }
    getOdds() {
        const self = this;
        //+this.props.clMarketId
        this.testCounter = this.testCounter + 1
        apiCaller(masterPage.getUpdatedOdds + "?marketId=" + this.props.event.markets[0].clMarketId, 'GET').then(response => {
            if (response && response.data) {
                this.updatedOdds = response.data;
                this.props.appStore.betSlipStore.updateBetSlipOdds(response.data);
                this.showOdds = true;
                this.oddsUpdated = !this.oddsUpdated;
                // if(market.marketId === self.props.clMarketId){
                //   for(var outcome of market.runners){
                //     if(outcome.selectionId === Number(self.props.outcome.clOutcomeId)){
                //       var changedBackOdds = 0;
                //       var backOddsIndex=0
                //       for(var backSelection of outcome.ex.availableToBack){
                //         if(backOddsIndex<3){
                //           if(backSelection.price > changedBackOdds){
                //             changedBackOdds = backSelection.price
                //           }
                //         }
                //         backOddsIndex++;
                //       }
                //       var changedLayOdds = 0;
                //       var layOddsIndex=0
                //       for(var laySelection of outcome.ex.availableToLay){
                //         if(layOddsIndex<3){
                //           if(layOddsIndex === 0){
                //             changedLayOdds = laySelection.price
                //           }else if(laySelection.price < changedLayOdds){
                //             changedLayOdds = laySelection.price
                //           }
                //         }
                //         layOddsIndex++;
                //       }
                //       if(changedBackOdds>1){
                //         self.checkOddsUpdate(self.props.outcome.backOdds,changedBackOdds,'BO');
                //         self.props.outcome.backOdds = changedBackOdds;
                //       }else{
                //         self.props.outcome.backOdds = '-';
                //       }
                //       if(changedLayOdds>1){
                //         self.checkOddsUpdate(self.props.outcome.layOdds,changedLayOdds,'LO');
                //         self.props.outcome.layOdds = changedLayOdds;
                //       }else{
                //         self.props.outcome.layOdds = '-';
                //       }
                //     }
                //   }
                // }
            }
        });

    }

    /**
    * This method is used to highlight the pin
    */
    pinSuccess() {
        this.props.event.isPinned = !this.props.event.isPinned;
    }
    navigateEventView() {
        this.props.appStore.sportId = this.props.sportId;
        this.props.appStore.sportName = this.props.sportName;
        this.props.appStore.eventViewId = this.props.event.id;
        this.props.history.push('/sports/' + this.props.sportName.toLowerCase() + '/' + this.props.sportId + '/event/' + this.props.event.id);
    }
    setOutcomeAccordionIndex(id, isAdd) {
        // debugger;
        this.props.appStore.selectedOutcomeUniqueId = 0;
        if (isAdd) {
            this.props.appStore.selectedOutcomeUniqueId = id
        }

        // if (this.props.eventComponentStore.outcomeAccordionIndex===id) {

        // } else {
        //     this.props.eventComponentStore.outcomeAccordionIndex=id;
        // }

    }
    render() {
        const { event, sportId, sportName, eventComponentStore, appStore, upcomingStore, eventIndex } = this.props;
        var self = this;
        return (
            <div className={"RDE-accordion RDE-singleLineEventItem" + (eventComponentStore.accordionOpenIndex.indexOf(eventIndex) === -1 ? " collapsed" : "")}>
                <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                        {event.isLive == 1 ?
                            <div className="RDE-singleLineEventItem__liveScore">Live</div>
                            :
                            <span>{formatDate(event.kickOffTime, this.props.componentType)}</span>}
                        <div className={"RDE-singleLineEventItem__pin " + (event.isPinned == 1 ? "active" : '')} onClick={eventComponentStore.savePin.bind(eventComponentStore, event.id, event.isPinned, this.props)}>
                            <i className="RDE-icon-pin"></i>
                        </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails" onClick={this.navigateEventView.bind(this)}>
                        <div className="RDE-singleLineEventItem__fixture">{event.homeTeamName} v {event.awayTeamName}</div>

                        {event.isFancy == 1 ? <div className="RDE-singleLineEventItem__fancy">
                            <img src={imageUrls.fancyIcon} alt="Fancy" />
                        </div> : ''}
                    </div>
                    <div className="RDE-singleLineEventItem__expandArrow" onClick={eventComponentStore.openAccordion.bind(eventComponentStore, eventIndex)}>
                        <i className={eventComponentStore.accordionOpenIndex.indexOf(eventIndex) === -1 ? "RDE-icon-up-arrow" : "RDE-icon-down-arrow"}></i>
                    </div>
                </div>
                <Collapse in={eventComponentStore.accordionOpenIndex.indexOf(eventIndex) === -1}>
                    <div className="RDE-singleLineEventItem__eventDetails__content">
                        <div className="RDE-singleLineEventMarketHeader">
                            <div className="RDE-singleLineEventMarketHeader__marketSection">
                                <ul>
                                    {event.markets[0].outcomes.map(function (outcome, ocIndex) {
                                        return (<li key={'outcomename' + ocIndex}>
                                            <div className="RDE-singleLineEventMarketHeader__marketItem">{outcome.name}</div>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="RDE-singleLineEventItem__outcomeDetails">
                            <ul>
                                {
                                    event.markets && event.markets.length > 0 && event.markets[0].outcomes.map((outcome, OCIndex) => {
                                        return (
                                            <>
                                                <OddsComponent key={"EC_OC_" + event.id + '_' + OCIndex} outcome={outcome} eventSuspended={event.isSuspended}
                                                    marketSuspended={event.markets[0].isSuspended}
                                                    eventBlocked={event.isBlocked}
                                                    marketBlocked={event.markets[0].isBlocked}
                                                    eventId={event.id}
                                                    clEventId={event.clEventId}
                                                    updatedOdds={this.updatedOdds}
                                                    oddsUpdated={this.oddsUpdated}
                                                    marketId={event.markets[0].marketGroupId}
                                                    clMarketId={event.markets[0].clMarketId}
                                                    eventName={event.homeTeamName + " v " + event.awayTeamName}
                                                    isLive={event.hasOwnProperty('isLive') ? event.isLive : 0}
                                                    providerId={event.providerId}
                                                    homeTeam={event.homeTeamName}
                                                    awayTeam={event.awayTeamName}
                                                    marketName={event.markets[0].marketName}
                                                    appStore={appStore}
                                                    maxStake={event.markets[0].maxStake}
                                                    minStake={event.markets[0].minStake}
                                                    betSlipStatusChange={appStore.betSlipStatusChange}
                                                    showOdds={this.showOdds}
                                                    setOutcomeAccordionIndex={this.setOutcomeAccordionIndex}
                                                />

                                            </>
                                        )
                                    })
                                }

                            </ul>
                            <Collapse in={appStore.selectedOutcomeUniqueId === event.id + "_" + event.markets[0].marketGroupId}>
                                <div>
                                    <BetSlipComponent betslipComponentStore={appStore.betSlipStore} appStore={appStore}
                                        coinValueChange={appStore.coinValuesChanged} />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}
export default observer(EventComponent);
