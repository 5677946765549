import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageURLs from '../../sharedFiles/imageUrls';
import { getAuthenticationNumber } from "../../sharedFiles/helper";
import signUploader from '../../assets/images/loader.svg'


class LoginSignup extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.loginSignupStore.authNumber = getAuthenticationNumber();
        this.props.loginSignupStore.loginSignupProps = this.props;
        this.props.loginSignupStore.resetFields();
        this.props.loginSignupStore.appStore = this.props.appStore;
    }

    selectSport(sportName, sportId) {
    }
    handleKeyPress(e) {
        if (e.key == 'Enter') {

            this.props.loginSignupStore.login(this.props.settingsStore)
        }
    }
    handleSignUpClick = () => {
        this.props.loginSignupStore.errorMsg = "";
        this.props.appStore.hideSignup(this.props.appStore);
    }

    render() {
        const { appStore, loginSignupStore, settingsStore } = this.props;
        return (
            <div className="RDE-loginSignupPage">
                <div className="RDE-modal">
                    <div className="RDE-loginSignup-modal">
                        <div className="RDE-loginSignup-modal-content">
                            <div className="RDE-loginSignup-form">

                                {/* Signup Form */}
                                <div className={appStore.showSignupPopup == true ? "RDE-loginSignupBox RDE-signUp" : "RDE-loginSignupBox RDE-signUp d-none"}>
                                    <div className="RDE-loginLogo">
                                        <img src={imageURLs.xchangeLogo} alt="logo" />
                                    </div>
                                    {!loginSignupStore.showSignupSuccess ? <div className="">
                                        <div className="RDE-loginSignupBox-header">
                                            SignUp
                                        </div>
                                        <div className="RDE-loginSignupBox-content">
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Name" value={loginSignupStore.signupFields.name} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'name')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.name}</div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Email" value={loginSignupStore.signupFields.email} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'email')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.email}</div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Phone Number" maxLength="16" value={loginSignupStore.signupFields.phoneNumber} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'phoneNumber')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.phoneNumber}</div>
                                            <div className="RDE-formGroup">
                                                <input type="password" className="RDE-formControl" placeholder="Password" value={loginSignupStore.signupFields.password} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'password')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.password}</div>
                                            <div className="RDE-formGroup">
                                                <input type="password" className="RDE-formControl" placeholder="Confirm Password" value={loginSignupStore.signupFields.confirmPassword} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'confirmPassword')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.confirmPassword}</div>
                                            <div className="space-2"></div>

                                            <div className="RDE-formGroup mb-0 text-center">
                                                {loginSignupStore.errorMsg && <div className="RDE-formControl-errorMessage">{loginSignupStore.errorMsg}</div>}
                                                <button className="btn RDE-btn-primary RDE-btn-large" onClick={loginSignupStore.signUp.bind(loginSignupStore)}>Sign Up</button>

                                            </div>
                                            <div className="space-5"></div>
                                            <div className="RDE-loginSignupBox-footer__goBack text-center">
                                                Already Registered?
                                                <span className="RDE-forgotLink" onClick={appStore.showLogin.bind(appStore)}> Login</span>
                                            </div>
                                        </div>
                                        <div className="RDE-loginSignupBox-footer">
                                            <div className="grid-middle-noGutter">
                                                <div className="col-12 text-center">
                                                    <div className="RDE-loginSignupBox-footer__title">For assistance please contact us at</div>
                                                </div>
                                            </div>
                                            <div className="grid-middle-center">
                                                    <i className="RDE-icon-email"></i>
                                                    {/* <a href="mailto@cs@victoryexch.com">info@victoryexch.com</a> */}
                                                    <i className="RDE-icon-whatsapp"></i>
                                                    {/* +44 7566742924 */}
                                            </div>
                                        </div>
                                    </div> :
                                        <div className="RDE-loginSignupBox-signUpSuccess">
                                            <div className="RDE-loginSignupBox-signUpSuccess-content text-center">
                                                <div className="RDE-signUpSuccess__headerTxt">
                                                    <i className="RDE-icon-success"></i>
                                                    Thank you...!
                                                </div>
                                                <div className="RDE-signUpSuccess__mainTxt">Your registration is successfull</div>
                                                <div className="RDE-loginSignupBox-footer__goBack">
                                                    <span className="">Please verify your email before login, verification link is sent to your email</span>

                                                    <div className="" ><i className="RDE-icon-go-back"></i><span className="RDE-forgotLink" onClick={appStore.showLogin.bind(appStore)}>Go to login</span></div>
                                                </div>
                                            </div>
                                        </div>}
                                    {loginSignupStore.showSignUpLoader ? <div className="RDE-signUp-loader d-none">
                                        <img src={imageURLs.loaderIcon} />
                                    </div> : ''}
                                </div>

                                {/* Login Popup */}
                                <div className={appStore.showLoginPopup == true ? "RDE-loginSignupBox RDE-logIn" : "d-none"} onKeyPress={(event) => this.handleKeyPress(event)}>
                                    <div className="RDE-loginLogo">
                                        <img src={imageURLs.xchangeLogo} alt="logo" />
                                    </div>
                                    <div className="">
                                        <div className="RDE-loginSignupBox-header">
                                            Login
                                        </div>
                                        {!loginSignupStore.showLoginLoader ? <div className="RDE-loginSignupBox-content">
                                            <div className="RDE-formGroup">
                                                {/* <input type="text" className="RDE-formControl" placeholder="Username/Email" value={loginSignupStore.email} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'email')} /> */}
                                                <input type="text" className="RDE-formControl" placeholder="Email" value={loginSignupStore.email} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'email')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="RDE-formGroup">
                                                <input type="password" className="RDE-formControl" placeholder="Password" value={loginSignupStore.password} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'password')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="RDE-formGroup RDE-captchaBox">
                                                <input type="number" min="0" inputmode="numeric" pattern="[0-9]*"
                                                    className="RDE-formControl" placeholder="Validation"
                                                    value={loginSignupStore.reAuthNumber} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'reAuthNumber')} maxLength={4} />
                                                <div className="RDE-captchaTxt RDE-skew">
                                                    <span className="RDE-skewTxt">{loginSignupStore.authNumber}</span>
                                                </div>
                                            </div>
                                            <div className={loginSignupStore.errorMsg.includes('Email is not verified') ? "RDE-formControl-errorMessage RDE-login-errorMsg" : "RDE-formControl-errorMessage"}>{loginSignupStore.errorMsg}</div>
                                            <div className="RDE-formGroup text-center">
                                                <button className="btn RDE-btn-primary RDE-btn-large" onClick={loginSignupStore.login.bind(loginSignupStore, settingsStore)}>Login</button>
                                            </div>
                                            <div className="space-5"></div>
                                            <div className="RDE-loginSignupBox-footer__goBack text-center">
                                                {/* <i className="RDE-icon-go-back"></i> */}
                                                <span className="RDE-forgotLink" onClick={() => loginSignupStore.forgotLoginclick(appStore)}>Forgot Password?</span>
                                            </div>
                                        </div> : <div className="RDE-signUp-loader">
                                            <img src={imageURLs.loaderIcon} alt="loading...!" />
                                        </div>}
                                        <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                            <div className="RDE-loginSignupBox-footer__goBack">
                                                Not Registered?
                                                <span className="RDE-forgotLink" onClick={() => loginSignupStore.onSignupClick(appStore)}> Signup</span>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Forgot Password screen after mail sending link  */}

                                    <div className={appStore.showForgetPasswordPopup ? "RDE-loginSignupBox-forgotPassword-box d-none" : "RDE-loginSignupBox-forgotPassword-box d-none"}>
                                        <div className="RDE-loginSignupBox-header">
                                            Forgot Password
                                        </div>
                                        <div className="RDE-loginSignupBox-content">
                                            <div className="RDE-formGroup">
                                                {/* <input type="text" className="RDE-formControl" placeholder="Username/Email" /> */}
                                                <input type="text" className="RDE-formControl" placeholder="New Password" />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Confirm Password" />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="space-5"></div>
                                            <div className="RDE-formGroup text-center">
                                                <button className="btn RDE-btn-primary">Submit</button>
                                            </div>
                                        </div>
                                        <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                            <div className="RDE-loginSignupBox-footer__goBack" onClick={appStore.showLogin.bind(appStore)}>
                                                <i className="RDE-icon-go-back"></i>
                                                <span className="RDE-forgotLink">Back to Login</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Change Password Popup */}
                                    <div className={appStore.showForgetPasswordPopup ? "RDE-loginSignupBox-changePassword d-none" : "RDE-loginSignupBox-changePassword d-none"}>
                                        <div className="RDE-loginSignupBox-header">
                                            Change Password
                                        </div>
                                        <div className="RDE-loginSignupBox-content">
                                            <div className="RDE-formGroup">
                                                {/* <input type="text" className="RDE-formControl" placeholder="Username/Email" /> */}
                                                <input type="text" className="RDE-formControl" placeholder="New Password" />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Confirm Password" />
                                            </div>
                                            <div className="RDE-formControl-errorMessage"></div>
                                            <div className="space-5"></div>
                                            <div className="RDE-formGroup text-center">
                                                <button className="btn RDE-btn-primary">Submit</button>
                                            </div>
                                        </div>
                                        <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                            <div className="RDE-loginSignupBox-footer__goBack" onClick={appStore.showLogin.bind(appStore)}>
                                                <i className="RDE-icon-go-back"></i>
                                                <span className="RDE-forgotLink">Back to Login</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Forgot Password Popup */}
                                <div className={appStore.showForgetPasswordPopup ? "RDE-loginSignupBox RDE-loginSignupBox-forgotPassword" : " RDE-loginSignupBox RDE-loginSignupBox-forgotPassword d-none"}>


                                    <div className="RDE-loginSignupBox-header">
                                        Forgot Password
                                    </div>
                                    {!loginSignupStore.showForgotSuccess && !loginSignupStore.showForgotLoader ? <div className="RDE-loginSignupBox-content">
                                        <div className="RDE-formGroup">
                                            {/* <input type="text" className="RDE-formControl" placeholder="Username/Email" /> */}
                                            <input type="text" className="RDE-formControl" placeholder="Email"
                                                onChange={loginSignupStore.onForgotFieldChange.bind(loginSignupStore, 'email')}
                                                value={loginSignupStore.forgotFields.email} />
                                        </div>
                                        <div className="RDE-formControl-errorMessage">{loginSignupStore.forgotFieldsError.email}</div>
                                        <div className="RDE-formGroup text-center">
                                            <button className="btn RDE-btn-primary" onClick={loginSignupStore.submitForgot.bind(loginSignupStore)}>Submit</button>
                                        </div>
                                    </div> : ""}
                                    {loginSignupStore.showForgotSuccess && !loginSignupStore.showForgotLoader ?
                                        <div className={loginSignupStore.forgotMessage.includes("Link for") ? "text-center RDE-DB-successMessage" : "text-center RDE-formControl-errorMessage"}>{loginSignupStore.forgotMessage}</div> : ''}
                                    {loginSignupStore.showForgotLoader ? <div className="RDE-signUp-loader">
                                        <img src={signUploader} alt="loading...!" />
                                    </div> : ''}
                                    <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                        <div className="RDE-loginSignupBox-footer__goBack" onClick={() => loginSignupStore.resetForgot(appStore)}>
                                            <i className="RDE-icon-go-back"></i>
                                            <span className="RDE-forgotLink">Back to Login</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <img src={imageURLs.loginSignupClose} className="RDE-loginSignUp-closeIcon" onClick={()=>this.handleSignUpClick()} /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(LoginSignup);
