
var endPoint="";
var updateOddsEndpoint="";
var devEndPoint = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/";

var qaEndPoint = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/";

var prodEndPoint = "http://ec2-3-10-60-18.eu-west-2.compute.amazonaws.com:8081/bm-exchange-middle-layer/";

var updateOddsDev = "https://exchange-qa-marketodds.sportsit-tech.net/bm-exchange-middle-layer/get-market-odds";

var updateOddsQA ='https://exchange-qa-marketodds.sportsit-tech.net/bm-exchange-middle-layer/get-market-odds';

var updateOddsProd='http://ec2-3-10-60-18.eu-west-2.compute.amazonaws.com:8083//bm-exchange-middle-layer/get-market-odds';

if(window.location.hostname.includes('mqa.victoryexch.com')){
	endPoint = qaEndPoint;
	updateOddsEndpoint = updateOddsQA;
} else if(window.location.hostname.includes('localhost')){
	endPoint = devEndPoint;
	updateOddsEndpoint = updateOddsDev;
}else if (window.location.hostname.includes('mpromo.victoryexch.com')) { 
	endPoint = qaEndPoint;
	updateOddsEndpoint = updateOddsQA;
}else if (window.location.hostname.includes('mgame.americanexch.com')) {
	endPoint = qaEndPoint;
	updateOddsEndpoint = updateOddsQA;
}else if (window.location.hostname.includes('m.gamex.group')) {
	endPoint = prodEndPoint;
	updateOddsEndpoint = updateOddsProd;
}else {
	endPoint = qaEndPoint;
	updateOddsEndpoint = updateOddsQA;
}

export const signup={
	"register":endPoint+"user-sign-up",
	"emailValidation": endPoint + "user-email-id-validation",
	"userNameValidation":endPoint+"user-name-validation",
	"forgotPassWord":endPoint+"send-email-forget-password-confirmation",
	"resetPassword":endPoint+"update-forget-password"
}
export const login={
	"login":endPoint+"user-login"
}

export const homePage={
	"getBanners":endPoint+"banners"
}

export const masterPage = {
	"getLeftMenu":endPoint+"left-menu",
	"getHeader":endPoint+"headers",
	"getSubHeader":endPoint+"sub-header",
	"getFooter":endPoint+"get-footer-controls",
	"userMenu":endPoint+"get-user-menu",
	"logout":endPoint+"user-logout",
	"search":endPoint+"search",
	"getOneClick":endPoint+"one-click-details",
	"updateOneClick":endPoint+"update-one-click",
	"getUserCoin":endPoint+"user-coin",
	"updateUserCoins":endPoint+"update-user-coin",
	"saveSettings":endPoint+"save-user-coin",
	"savePin":endPoint+"save-pin-details",
	"removePin":endPoint+"remove-pin-details",
	"saveBets":endPoint+"save-bet-slip-details",
	"getBets": endPoint + "get-bet-slip-details",
	"getBalance":endPoint+"/user-balance",
	"getCompetitions": endPoint + "get-fancy-leagues",
	"getEvents": endPoint + "get-fancy-events",
	"getOpenbets":endPoint+"/open-bets/get-open-bet-details",
	"updateOpenBets":endPoint+"/open-bets/update-open-bet-details",
	"cancelOpenBets":endPoint+"/open-bets/cancel-open-bets",
	"getUpdatedOdds":updateOddsEndpoint,
	"saveProfitLoss":endPoint+"/save-market-profit-loss",
	"getProfitLoss":endPoint+"/get-market-profit-loss",
}

export const upcomingHighlights={
	"getUpcomingOptions":endPoint+"get-upcoming-options",
	"getUpcomingEvents":endPoint+"get-upcoming-events",
	"getHighlightsSports":endPoint+"get-highlights-sports",
	"getHighlightsEvents":endPoint+"get-highlights"
}

export const sportsPage={
	"getLeagues":endPoint+"popular-leagues",
	"getLeagueEvents":endPoint+"sport-highlights",
	"getLiveEvents":endPoint+"get-live-events",
	"getCountryLeagues":endPoint+"get-sport-wise-leagues",
	"getLeagueWiseEvents":endPoint+"get-league-wise-events"
}

export const eventView={
	"getEvent":endPoint+"get-event-view-details"
}

export const multiMarket={
	"getEvents":endPoint+"get-multi-markets"
}

export const profile={
	"betBalanceOverview":endPoint+"trans-details",
	"getUserProfile": endPoint + "user-profile",
	"getBetHistory":endPoint+"user-bet-history",
	//"getProfitLoss": endPoint + "profit-loss",
	"changePassword":endPoint+"password-update",
	"getProfitLoss":endPoint+"trans-details",
	"gettransdetailseventview": endPoint + "trans-details-event-view",
	"getSports":endPoint+"get-sportwise-profit-loss"
}

export const games={
	"getEzugiToken":endPoint+"getEzugiToken"
}

export const scoreCard={
	"cricketScore": "https://jeoad.com/santosh/cricket.php",
	"otherSportScores":"https://jeoad.com/santosh/tennis.php"
}
export const interval={
	"liveScore":4*1000
}