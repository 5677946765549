import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';

class MyProfileStore {
  constructor(props) {
    extendObservable(this, {
      userData: {},
    });
  }
  /**
  * This method is used to get the balance overview data
  *
  */
  getUserProfile(){
  	var token="";
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
  	apiCaller(profile.getUserProfile+"?userToken="+token,'GET').then(response => {
  	  if(response && response.data){
  	      this.userData = response.data;
  	  }
    });
  }
}

export default MyProfileStore;
