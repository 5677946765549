import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

import BalanceOverviewTable from '../balanceoverviewtable/balanceOverviewTable';
import imageUrls from '../../../../sharedFiles/imageUrls';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import BalanceOverviewStore from '../balanceoverviewtable/balanceOverviewTableStore';
// import PaginationComponent from '../../../../sharedComponents/pagination/pagination'
// import PagiNationStore from '../../../../sharedComponents/pagination/paginationstore';
// const pagiNationStore = new PagiNationStore();

const balanceOverviewStore = new BalanceOverviewStore();

class AccountStatement extends Component {
  componentWillMount() {
    this.props.accountStatementStore.resetFields();

  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { accountStatementStore } = this.props;
    return (

      <div className="RDE-DB-accountStatements">
        {!balanceOverviewStore.showEventDetails && <div className="RDE-DB-myBets-filters">
          <div className="grid">
            {/* <div className="col-12 mb-10">
              <span className="RDE-DB-myBets-filters__label">Period:&nbsp;</span>
            </div> */}
            <div className="col-12">
              <div className="RDE-DB-dateBox-container mb-10">
                <div className="RDE-DB-dateBox">
                  <span className="RDE-DB-myBets-filters__label">From:&nbsp;</span>
                  <div className="RDE-DB-myBets-filters__datePicker">
                      <DatePicker selected={accountStatementStore.fromDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-start"
                        onChange={accountStatementStore.selectFromDate.bind(accountStatementStore)}
                        className="RDE-DB-datePickerInput"
                        maxDate={new Date()} />
                  </div>
                </div>
                <div className="RDE-DB-dateBox">
                  <span className="RDE-DB-myBets-filters__label">To:&nbsp;</span>
                  <div className="RDE-DB-myBets-filters__datePicker">
                      <DatePicker selected={accountStatementStore.toDate}
                        selectsEnd
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                        onChange={accountStatementStore.selectToDate.bind(accountStatementStore)}
                        className="RDE-DB-datePickerInput"
                        maxDate={new Date()} />
                  </div>
                </div>
              </div>
              <div className="RDE-DB-datePickerErrorMessage text-center">{accountStatementStore.dateErrMsg}</div>
              <div className="grid-noGutter-middle">
                <div className="col-12 text-center">
                  <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto"
                    onClick={accountStatementStore.getSearchData.bind(accountStatementStore)}>Search</button>
                </div>
              </div>

            </div>
          </div>
        </div>}

        {accountStatementStore.showLoader ? <div className="RDE-loader">
          <img src={imageUrls.loaderIcon} alt="loading...!" />
        </div>
          : <><BalanceOverviewTable data={accountStatementStore.data} component="AS" store={accountStatementStore}
            balanceOverviewStore={balanceOverviewStore} />
            {accountStatementStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
              Currently data is not available.
                            </div> : ''}
          </>}
        {/* <PaginationComponent showItems={3} pagiNationStore={pagiNationStore}/> */}
        {!balanceOverviewStore.showEventDetails && accountStatementStore.showPagination && <div className="RDE-DB-pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            // breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={accountStatementStore.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={accountStatementStore.handlePageChange.bind(accountStatementStore)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>}
      </div>

    )
  }
}
export default observer(AccountStatement);
