import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";

import imageUrls from '../../../sharedFiles/imageUrls';
import FancyOutcomes from '../../../sharedComponents/fancyoutcomes/fancyOutcomes';
import FancyOutcomesStore from '../../../sharedComponents/fancyoutcomes/fancyOutcomesStore';
import {liveMatchRefreshTime} from '../../../sharedFiles/helper';

const fancyOutcomesStore = new FancyOutcomesStore();

class ElectionComponent extends Component {
    componentWillMount() {
        this.props.appStore.currentPath = 'election';
        this.props.electionStore.getCommonEventView();
    }
    componentWillReceiveProps(nextProps) { }
    componentDidMount(){
        var self = this;
        setTimeout(function reCallService() {
          if(self.props.electionStore.mounted){
            self.props.electionStore.getCommonEventView();
            setTimeout(reCallService, liveMatchRefreshTime);
          }
        }, liveMatchRefreshTime);
    }
    componentWillUnmount(){
        this.props.electionStore.mounted = false;
    }
    render() {
        const { appStore, electionStore } = this.props
        return (
            <div className="RDE-mainContent">
                <div className="RDE-elections">
                    <div className="RDE-accordion-group">
                        {
                            electionStore.commonEvents.map((data, index) => {
                                return (
                                    <div className={"RDE-accordion" + (electionStore.accordionOpenIndex.indexOf(0) === -1 ? " collapsed" : "")} key={"election_" + index}>
                                        <div className="RDE-accordion-header RDE-singleLineEventHeader" onClick={electionStore.openAccordion.bind(electionStore, 0)}>
                                            <div className="RDE-singleLineEventHeader__left">
                                                <div className="RDE-singleLineEventHeader-title">
                                                    {data.name}
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={electionStore.accordionOpenIndex.indexOf(0) !== -1}>
                                            <div className="RDE-accordion-body">
                                                {
                                                    data.events.map((event, eventIndex) => {
                                                        return (<div>
                                                            <FancyOutcomes data={event.fancyBets ? event.fancyBets : {}}
                                                                eventSuspended={event.eventSuspended}
                                                                eventBlocked={event.eventBlocked}
                                                                eventName={event.name}
                                                                eventId={event.id}
                                                                // eventId={event.eventId}
                                                                appStore={appStore}
                                                                fancyOutcomesStore={fancyOutcomesStore}
                                                                backHeader='Yes'
                                                                layHeader='No'
                                                                mainHeader='Fancy Bets'
                                                                showPin={false}
                                                                component="fancy"/>
                                                            <FancyOutcomes data={event.fancyBets ? event.fancyBets1 : {}}
                                                                eventSuspended={event.eventSuspended}
                                                                eventBlocked={event.eventBlocked}
                                                                eventName={event.name}
                                                                eventId={event.id}
                                                                appStore={appStore}
                                                                fancyOutcomesStore={fancyOutcomesStore}
                                                                backHeader='Back'
                                                                layHeader='Lay'
                                                                showPin={false}
                                                                mainHeader='Fancy Bets1'
                                                                component="adminfancy"/>
                                                        </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </Collapse>
                                    </div>



                                )
                            })
                        }
                    </div>

                </div>
                {electionStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
                    Currently data is not available.
                              </div> : ''}
                {electionStore.showLoader ? <div className="RDE-loader">
                    <img src={imageUrls.loaderIcon} alt="loading...!" />
                </div> : ''}
            </div>
        )
    }
}
export default observer(ElectionComponent);
