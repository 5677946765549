import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import { extendObservable } from "mobx";
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage } from "../../sharedFiles/EndpointConfig";

class OddsComponent extends Component {
  constructor(props) {
    super(props)
    extendObservable(this, {
      layOddsInc: false,
      layOddsDec: false,
      backOddsInc: false,
      backOddsDec: false,
      isBOSelected: false,
      isLOSelected: false,
      isMounted: true,
      marketSuspended:0
    });
  }
  componentWillMount() {
    this.selectedBetsLocal(this.props);
    this.marketSuspended = this.props.marketSuspended;
  }
  componentDidMount(){
    var self = this;
    // setTimeout(function reCallService() {
    //   if(self.isMounted){
    //     self.getOdds(self);
    //     setTimeout(reCallService, 1000);
    //   }
    // }, 1000);
  }
  componentWillUnmount(){
    this.isMounted = false;
  }
  componentWillReceiveProps(nextProps) {
    if ((cookie.load('oddsHighights') && cookie.load('oddsHighights') === 'true') || !this.props.appStore.isLoggedIn) {
      if (this.props.outcome.backOdds !== nextProps.outcome.backOdds) {
        if (nextProps.outcome.backOdds < this.props.outcome.backOdds) {
          this.backOddsDec = true;
        } else {
          this.backOddsInc = true;
        }
        setTimeout(() => {
          this.removeOddsChange();
        }, 3000)
      }
      if (this.props.outcome.layOdds !== nextProps.outcome.layOdds) {
        if (nextProps.outcome.layOdds < this.props.outcome.layOdds) {
          this.layOddsDec = true;
        } else {
          this.layOddsInc = true;
        }
        setTimeout(() => {
          this.removeOddsChange();
        }, 3000)
      }
    }
    if (this.props.betSlipStatusChange !== nextProps.betSlipStatusChange) {
      this.selectedBetsLocal(nextProps);
    }
    if(this.props.oddsUpdated !== nextProps.oddsUpdated){
      this.getOdds();
    }
    if(this.marketSuspended !== nextProps.marketSuspended){
      this.marketSuspended = nextProps.marketSuspended;
    }
  }
  /**
  * this method is used to remove the odds change.
  */
  removeOddsChange() {
    this.backOddsInc = false;
    this.backOddsDec = false;
    this.layOddsInc = false;
    this.layOddsDec = false;

  }
  /**
  * This method is used to get the bets from local
  *
  */
  selectedBetsLocal(props) {
    if (localStorage) {
      var betSlipItems = JSON.parse(localStorage.getItem('betslip'));
      if (betSlipItems && betSlipItems.hasOwnProperty('backBets') && betSlipItems.backBets.length > 0) {
        var isFound = false;
        for (var bet of betSlipItems.backBets) {
          if (bet.eventId === props.eventId &&
            bet.marketId === props.marketId &&
            bet.selectionId === props.outcome.id &&
            Number(bet.odds) === props.outcome.backOdds) {
            this.isBOSelected = true;
            isFound = true;
          } else {
            // this.isBOSelected = false;
          }
        }
        if (!isFound) {
          this.isBOSelected = false;
        }
      } else {
        this.isBOSelected = false;
      }
      if (betSlipItems && betSlipItems.hasOwnProperty('layBets') && betSlipItems.layBets.length > 0) {
        var isFound = false;
        for (var bet of betSlipItems.layBets) {
          if (bet.eventId === this.props.eventId &&
            bet.marketId === this.props.marketId &&
            bet.selectionId === this.props.outcome.id) {
            this.isLOSelected = true;
            isFound = true;
          } else {
            // this.isLOSelected = false
          }
        }
        if (!isFound) {
          this.isLOSelected = false;
        }
      } else {
        this.isLOSelected = false;
      }
    }
  }
  /**
  * This method is used to add the bet to betslip.
  *
  */
  addToBetSlip(betType) {
    if(!this.props.showOdds){
      return;
    }
    this.props.appStore.isBetEditMode = false;
    if (betType === "BO" && this.props.outcome.backOdds < 1) {
      return
    } else if (betType === "LO" && this.props.outcome.layOdds < 1) {
      return
    }
    var obj = {
      eventId: this.props.eventId,
      clEventId: this.props.clEventId,
      marketId: this.props.marketId,
      clMarketId: this.props.clMarketId,
      selectionId: this.props.outcome.id,
      clOutcomeId: this.props.outcome.clOutcomeId,
      eventName: this.props.eventName,
      isLive:this.props.isLive,
      marketName: this.props.marketName,
      homeTeam:this.props.homeTeam,
      awayTeam:this.props.awayTeam,
      odds: '',
      selectionName: this.props.outcome.name === "1" ? this.props.homeTeam : this.props.outcome.name.toLowerCase() === 'x' ? "Draw" : this.props.awayTeam,
      betType: '',
      minStake: this.props.minStake,
      maxStake: this.props.maxStake,
      providerId:this.props.providerId,
      isFancy:this.props.providerId===1 || this.props.providerId === 3?true:false,
      isBetFair:true,
      stakeError: "",
      subMarket: this.props.outcome.subMarket ? this.props.outcome.subMarket : null,
      isOddsChange:false
    }
    var isSelected = ""
    if (betType === "BO") {
      this.isBOSelected = !this.isBOSelected;
      isSelected = this.isBOSelected;
      obj.odds = this.props.outcome.backOdds;
      obj.betType = "BO";
    } else if (betType === "LO") {
      this.isLOSelected = !this.isLOSelected;
      isSelected = this.isLOSelected
      obj.odds = this.props.outcome.layOdds;
      obj.betType = "LO";
    }
    var isAdded = this.props.appStore.addToBetSlip(obj, betType, !isSelected);
    if (betType === "BO") {
      this.isBOSelected = isAdded ? true : false;
      this.props.setOutcomeAccordionIndex(this.props.eventId+"_"+this.props.marketId,this.isBOSelected);
    } else if (betType === "LO") {
      this.isLOSelected = isAdded ? true : false;
      this.props.setOutcomeAccordionIndex(this.props.eventId+"_"+this.props.marketId,this.isLOSelected);

    }
   // this.props.appStore.showHideBetSlipSettings('betslip', 'addbetslip');
  }
  checkOddsUpdate(oddsOdds, newOdds , oddsType){
    if((cookie.load('oddsHighights') && cookie.load('oddsHighights') === 'true') || !this.props.appStore.isLoggedIn){
      if(oddsOdds !== newOdds){
        if(newOdds<oddsOdds){
          if(oddsType==='BO'){
            this.backOddsDec = true;
          }else{
            this.layOddsDec = true;
          }
          
        }else{
         
          if(oddsType==='BO'){
            this.backOddsInc = true;
          }else{
            this.layOddsInc = true;
          }
        }
        setTimeout(()=>{
          this.removeOddsChange();
        },3000)
      }
    }
  }
  getOdds() {
    const self = this;
    //+this.props.clMarketId
    this.testCounter = this.testCounter + 1
    // apiCaller(masterPage.getUpdatedOdds+"?marketId="+this.props.clMarketId,'GET').then(response => { 
    //     if(response && response.data){
    var market = this.props.updatedOdds;
    if (market.marketId === self.props.clMarketId) {
      if (market.status.toLowerCase() === 'suspended') {
        self.marketSuspended = 1;
      } else {
        self.marketSuspended = 0;
      }
      for (var outcome of market.runners) {
        if (outcome.selectionId === Number(self.props.outcome.clOutcomeId)) {
          var changedBackOdds = 0;
          var backOddsIndex = 0
          for (var backSelection of outcome.ex.availableToBack) {
            if (backOddsIndex < 3) {
              if (backSelection.price > changedBackOdds) {
                changedBackOdds = backSelection.price
              }
            }
            backOddsIndex++;
          }
          var changedLayOdds = 0;
          var layOddsIndex = 0
          for (var laySelection of outcome.ex.availableToLay) {
            if (layOddsIndex < 3) {
              if (layOddsIndex === 0) {
                changedLayOdds = laySelection.price
              } else if (laySelection.price < changedLayOdds) {
                changedLayOdds = laySelection.price
              }
            }
            layOddsIndex++;
          }
          if (changedBackOdds > 1) {
            self.checkOddsUpdate(self.props.outcome.backOdds, changedBackOdds, 'BO');
            self.props.outcome.backOdds = changedBackOdds;
          } else {
            self.props.outcome.backOdds = '-';
          }
          if (changedLayOdds > 1) {
            self.checkOddsUpdate(self.props.outcome.layOdds, changedLayOdds, 'LO');
            self.props.outcome.layOdds = changedLayOdds;
          } else {
            self.props.outcome.layOdds = '-';
          }
        }
      }
    }
    //     }
    // });

  }
  render() {
    const { outcome,showOdds } = this.props;
    var BOClasses = "";
    if (this.props.eventBlocked || this.props.marketBlocked) {
      BOClasses = "RDE-btnOddsDisabled";
    }
    if (this.backOddsInc) {
      BOClasses += " RDE-btnOddsIncreased";
    } else if (this.backOddsDec) {
      BOClasses += " RDE-btnOddsDecreased";
    }
    if (this.isBOSelected) {
      BOClasses += " active";
    }
    var LOClasses = "";
    if (this.props.eventBlocked || this.props.marketBlocked) {
      LOClasses = "RDE-btnOddsDisabled";
    }
    if (this.backOddsInc) {
      LOClasses += " RDE-btnOddsIncreased";
    } else if (this.backOddsDec) {
      LOClasses += " RDE-btnOddsDecreased";
    }
    if (this.isLOSelected) {
      LOClasses += " active";
    }
    return (
      <li>
        {this.props.eventSuspended || this.marketSuspended ? <div className="RDE-btnOddsSuspended">
          <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button>
        </div> :
          <div className="RDE-backAndLayOddsGroup">
            <button onClick={this.addToBetSlip.bind(this, "BO")} className={"btn RDE-btnOdds RDE-btnBackOdds" + (BOClasses)}>{showOdds && outcome.backOdds > 1 ? outcome.backOdds.toFixed(2) : "-"}</button>
            <button onClick={this.addToBetSlip.bind(this, "LO")} className={"btn RDE-btnOdds RDE-btnLayOdds" + (LOClasses)}>{showOdds && outcome.layOdds > 1 ? outcome.layOdds.toFixed(2) : "-"}</button>
          </div>}
      </li>

    )
  }
}
export default observer(OddsComponent);
