import { extendObservable } from "mobx";
import cookie from 'react-cookies';
import { sportsPage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

class SportsPageStore {
  constructor(props) {
    extendObservable(this, {
      liveEvents:[],
      sportId:"",
      sportName:"",
      showLeague:false
    });
  }
  
}

export default SportsPageStore;
