import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';

class AccountStatementStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showErrorMsg:false,
      showLoader: false,
      itemsPerPage: 10,
      totalPages: 0,
      pageNumber: 0,
      accordionOpenIndex: [0],
      fromDate:new Date(),
      toDate:new Date(),
      dateErrMsg: "",
      showPagination:false,
    });
  }

  resetFields(){
    this.dateErrMsg='';
    this.fromDate=new Date();
    this.toDate=new Date();
    this.getAccountStatement();
  }
  /**
  * This method is used to get the balance overview data
  *
  */
 
  getAccountStatement() {
    var self = this;
    this.totalPages = 0;
    this.data = [];
    this.showErrorMsg = false;
    this.showLoader = true;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    var fDate = fromDate.getFullYear()+"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate();
    var tDate = toDate.getFullYear()+"-"+(toDate.getMonth()+1)+"-"+toDate.getDate();
    if(new Date(fDate) > new Date(tDate)){
      this.dateErrMsg="From date should less than to date";
      this.showLoader =false;
      return;
    }
  	var token="";
  	var cookeTocken = cookie.load('UTocken');
  	if(cookeTocken && cookeTocken.length>0){
  	  token = cookeTocken;
      }
   // console.log('profile.betBalanceOverview ===== ', profile.betBalanceOverview);
    //&pageNumber="+this.pageNumber+"&pageSize="+this.itemsPerPage
    apiCaller(profile.betBalanceOverview + "?userToken=" + token + "&transactionType=as&fromDate="+fDate+"&toDate="+tDate+"&sportId=0&pageNumber="+this.pageNumber+"&pageSize="+this.itemsPerPage, 'GET').then(response => {
      this.showLoader = false;
  	  if(response && response.data && response.data.hasOwnProperty('userTransList') &&
        response.data.userTransList && response.data.userTransList.length > 0) {
          this.showPagination = true;
         this.totalPages = Math.ceil(response.data.userTransactionsCount/this.itemsPerPage);
        this.data = response.data.userTransList;
        var accInd=[]
        for(var i =0;i< this.data.length;i++){
          accInd.push(i)
        }
        this.accordionOpenIndex = accInd;
       
  	  }else{
        this.showErrorMsg = true;
        this.showPagination = false;
      }
    });
  }
 /**
  *	This method is called when user selects the from date.
  */
 selectFromDate(selectedDate){
  this.fromDate = new Date(selectedDate);
  this.dateErrMsg = "";
  }
   /**
  * This method is called when user selects the to date.
  */
 selectToDate(selectedDate){
  this.toDate = new Date(selectedDate);
  this.dateErrMsg = "";
}
  handlePageChange(pager) {
    //debugger;
    //alert(pageNumber);
    this.accordionOpenIndex = [0];
    this.pageNumber = pager.selected;
    this.getAccountStatement();
  }
  calculatePagination(data){
    // if(data && data.length>0){
    //   this.toalPages = Math.ceil(data.length/this.itemsPerPage);
    // }
  }
  getSearchData() {
    this.showPagination = false;
    this.getAccountStatement();
  }
}

export default AccountStatementStore;
