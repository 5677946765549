import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { login } from "./sharedFiles/EndpointConfig";
import { apiCaller } from './sharedFiles/apiStore';
import { masterPage } from "./sharedFiles/EndpointConfig";
import { getAuthenticationNumber } from "./sharedFiles/helper"

const re = /^[0-9\b]+$/;
const history = createBrowserHistory();

class AppStore {
  constructor() {
    extendObservable(this, {
      showSignupPopup: false,
      isLoggedIn: false,
      mounted: true,
      headerData: [],
      userMenu: [],
      authNumber: getAuthenticationNumber(),
      email: "",
      password: "",
      reAuthNumber: '',
      errorMsg: '',
      leftMenuData: [],
      showSignupBlock: true,
      searchValue: '',
      searchSuggestions: [],
      searchResults: [],
      userInfo: {},
      betSlipStore: {},
      currentPath: "",
      history: createBrowserHistory(),
      sportName: "",
      sportId: "",
      appProps: {},
      showLoginPopup: true,
      showForgetPasswordPopup: false,
      selectedSport: "",
      sportsData: [],
      showLeftMenuItems: false,
      footerSelItem: "home",
      enableSearch: false,
      searchLoading: true,
      searchValue: '',
      searchString: '',
      showTC: false,
      inPlaySportName: '',
      inPlaySportId: '',
      showBetSlipSettings: '',
      betSlipStatusChange: false,
      coinValuesChanged: false,
      showRightMenuItems: false,
      showDeposit: false,
      betsProfitLoss: [],
      betsProfitLossMain: [],
      pLStatusChanged: false,
      selectedOpenBetsEvent: {
        eventId: '',
        eventName: ''
      },
      openBetsEventIds: [],
      showLogoutPopup: false,
      selectedOutcomeUniqueId: 0,
      isBetEditMode: false,
      msgInfo: '',
      msgType: '',
      placedBetInfo: {
        type: '',
        stake: '',
        odds: '',
        isMatched: ''
      },
      showOneClick:false,
      showSettings:false
    });
    var betSlipItems = JSON.parse(localStorage.getItem("betslip"));
    if (!betSlipItems) {
      betSlipItems = {
        backBets: [],
        layBets: []
      }
      localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    }

  }

  /**
  * This method is used to get the left menu data.
  *
  */
  getHeaderData() {
    apiCaller(masterPage.getHeader, 'GET').then(response => {
      if (response?.data?.headersList?.length > 0) {
        this.headerData = response.data.headersList;
      }
    });
  }


  /**
  * This method is used to get the Main header sports.
  *
  */
  getMainHeaderSportsData() {
    apiCaller(masterPage.getLeftMenu, 'GET').then(response => {
      if (response?.data?.categories?.length > 0) {
        this.leftMenuData = response.data.categories;
        this.sportsData = response.data.categories[0].Sport;
        // this.sportsData.splice(0, 0, response.data.categories[0]);
        for (var cat of this.leftMenuData) {
          for (var sport of cat.Sport) {
            if (this.sportId === "" || this.sportId === null) {
              // this.sportId = sport.id;
              // this.selectedSport = sport.name;
            }
            break;
          }
          if (this.sportId !== "" && this.sportName !== "") {
            break
          }
        }
      }
    });
  }
  /**
* This method is used to add a bet to betslip
* @param {object} selection- contains the selection with event and market id to add to betslip
* @param {string} betType- contains BO and LO which tells the type of odds,
* BO - back odds
* LO - lay odds
*/
  addToBetSlip(selection, betType, isSelected) {
    /** Always only one bet needs to display in betslip i.e. recently added bet. */
    // localStorage.setItem("betslip", JSON.stringify({
    //   backBets: [],
    //   layBets: []
    // }));
    if (cookie.load('showOneClick') && cookie.load("showOneClick") === "true") {
      this.betSlipStore.placeOneClickBet(selection);
      return false;
    }
    var betSlipItems = JSON.parse(localStorage.getItem("betslip"));
    var isLocalAdded = false;
    if (!betSlipItems) {
      betSlipItems = {
        backBets: [],
        layBets: []
      }
    }
    var cookieSelection = {
      eventId: selection.eventId,
      marketId: selection.marketId,
      selectionId: selection.selectionId,
      isFancy: selection.isFancy,
      odds: selection.odds,
      betType: selection.betType
    }
    if (selection.betType === "BO") {
      var BOBets = betSlipItems.backBets;
      var betIndex = this.findBetIndex(BOBets, selection);
      if (betIndex >= 0) {
        if (BOBets[betIndex].odds !== selection.odds) {
          BOBets[betIndex] = selection;
          isLocalAdded = true;
        } else {
          BOBets.splice(betIndex, 1);
          isLocalAdded = false;
        }
      } else {
        BOBets = [];
        betSlipItems.layBets = [];
        BOBets.push(cookieSelection);
        isLocalAdded = true;
      }
      betSlipItems.backBets = BOBets;
    } else {
      var LOBets = betSlipItems.layBets;
      var betIndex = this.findBetIndex(LOBets, selection);
      if (betIndex >= 0) {
        if (LOBets[betIndex].odds !== selection.odds) {
          LOBets[betIndex] = selection;
          isLocalAdded = true;
        } else {
          LOBets.splice(betIndex, 1);
          isLocalAdded = false;
        }
      } else {
        LOBets = [];
        betSlipItems.backBets = [];
        LOBets.push(cookieSelection);
        isLocalAdded = true;
      }
      betSlipItems.layBets = LOBets;
    }
    localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    var addedBetSlip = this.betSlipStore.addBetSlipItem(selection);
    this.betSlipStatusChange = !this.betSlipStatusChange;
    //this.betsProfitLoss = [...this.betsProfitLossMain];
    this.calProfitLoss();
    return addedBetSlip;
  }
  /**
  * This method is used to calculate the profit and loss
  */
  calProfitLoss() {
    //debugger;
    var betsProfitLoss = [];
    for (var bet of this.betSlipStore.betSlipItems.backBets) {
      var findbet = this.findPLBets(bet)

      betsProfitLoss.push(findbet);
    }
    for (var bet of this.betSlipStore.betSlipItems.layBets) {
      var findbet = this.findPLBets(bet)

      betsProfitLoss.push(findbet);
    }

    for (var mainPL of this.betsProfitLoss) {
      var isFound = false
      for (var newPL of betsProfitLoss) {
        if (mainPL.eventId === newPL.eventId &&
          mainPL.marketId === newPL.marketId &&
          mainPL.providerId === newPL.providerId &&
          mainPL.subMarket === newPL.subMarket) {
          isFound = true;
        }
      }
      if (!isFound) {
        betsProfitLoss.push(mainPL);
      }
    }
    //this.betsProfitLoss= [...betsProfitLoss];
    this.betsProfitLoss = betsProfitLoss;
    this.calculatePL();
  }
  removePL(bet) {

    //removedBet = betSlipBets.splice(betIndex,1);
    var foundIndex = -1;
    var index = 0;
    var betsProfitLoss = []
    for (var betPL of this.betsProfitLoss) {
      if (betPL.eventId === bet.eventId &&
        betPL.marketId === bet.marketId &&
        betPL.providerId === bet.providerId &&
        betPL.subMarket === bet.subMarket) {
        betPL.homeProfitLoss = null;
        betPL.awayProfitLoss = null;
        betPL.drawProfitLoss = null;

        betPL.awayLoss = null;
        betPL.awayLossStake = null;
        betPL.awayProfit = null;
        betPL.awayProfitStake = null;

        betPL.drawLoss = null;
        betPL.drawLossStake = null;
        betPL.drawProfit = null;
        betPL.drawProfitStake = null;

        betPL.homeLoss = null;
        betPL.homeLossStake = null;
        betPL.homeProfit = null;
        betPL.homeProfitStake = null;

        betsProfitLoss.push(betPL)
      } else {
        betsProfitLoss.push(betPL)
      }
    }
    this.betsProfitLoss = [...betsProfitLoss];
    this.pLStatusChanged = !this.pLStatusChanged;
  }
  findPLBets(bet) {
    var isFound = false;
    for (var betPL of this.betsProfitLoss) {
      if (bet.marketId === 24 || bet.marketId === 2 || bet.marketId === 3 || bet.isFancy) {
        if (betPL.eventId === bet.eventId &&
          betPL.marketId === bet.marketId &&
          betPL.providerId === bet.providerId &&
          betPL.subMarket === bet.subMarket) {
          isFound = true;
          if (bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName || bet.selectionName.toLowerCase().includes('under')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.homeProfitStake = Number(bet.userStake);
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.homeLossStake = Number(bet.userStake);
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName || bet.selectionName.toLowerCase().includes('over')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.awayProfitStake = Number(bet.userStake);
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.awayLossStake = Number(bet.userStake);
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('draw')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.drawProfitStake = Number(bet.userStake);
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.drawLossStake = Number(bet.userStake);
            }
          }
          return betPL;
        }
      } else {

        if (betPL.eventId === bet.eventId &&
          betPL.marketId === bet.marketId &&
          betPL.providerId === bet.providerId) {
          isFound = true;
          if (bet.homeTeam === bet.selectionName) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.homeProfitStake = Number(bet.userStake);
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.homeLossStake = Number(bet.userStake);
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.awayTeam === bet.selectionName) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.awayProfitStake = Number(bet.userStake);
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.awayLossStake = Number(bet.userStake);
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('draw')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.drawProfitStake = Number(bet.userStake);
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.drawLossStake = Number(bet.userStake);
            }
          }
          return betPL;
        }
      }

    }
    if (!isFound) {
      if (bet.marketId === 24 || bet.marketId === 2 || bet.marketId === 3 || bet.isFancy) {

        return {
          eventId: bet.eventId,
          marketId: bet.marketId,
          odds: bet.userOdds,
          providerId: bet.providerId,
          isFancy: bet.isFancy,
          homeTeamProfitLoss: null,
          awayTeamProfitLoss: null,
          drawTeamProfitLoss: null,
          subMarket: bet.subMarket ? bet.subMarket : null,
          homeProfit: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'BO' ? bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake) : 0,
          homeProfitStake: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          drawProfit: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawProfitStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          awayProfit: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayProfitStake: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          homeLoss: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeLossStake: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          drawLoss: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawLossStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          awayLoss: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayLossStake: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.userStake) : 0,
        }
      } else {
        return {
          eventId: bet.eventId,
          marketId: bet.marketId,
          providerId: bet.providerId,
          odds: bet.userOdds,
          isFancy: bet.isFancy,
          homeTeamProfitLoss: null,
          awayTeamProfitLoss: null,
          drawTeamProfitLoss: null,
          subMarket: bet.subMarket ? bet.subMarket : null,
          homeProfit: bet.homeTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeProfitStake: bet.homeTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          drawProfit: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawProfitStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          awayProfit: bet.awayTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayProfitStake: bet.awayTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          homeLoss: bet.homeTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeLossStake: bet.homeTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          drawLoss: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawLossStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          awayLoss: bet.awayTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayLossStake: bet.awayTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.userStake) : 0,
        }
      }

    }
  }

  /**
  *
  */
  calculatePL() {
    for (var i = 0; i < this.betsProfitLoss.length; i++) {
      if (!this.betsProfitLoss[i]['isFancy']) {
        if (this.betsProfitLoss[i]['marketId'] === 1 && (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0 ||
          this.betsProfitLoss[i]['drawProfitStake'] > 0 || this.betsProfitLoss[i]['drawLossStake'] > 0)) {
          //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
          //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
          // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
          //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
          this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
          this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
          this.betsProfitLoss[i]['drawProfitLoss'] = this.betsProfitLoss[i]['drawTeamProfitLoss'] + ((this.betsProfitLoss[i]['drawProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['homeLossStake']) -
            (this.betsProfitLoss[i]['drawLoss'] + this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayProfitStake']))
        } else if ((this.betsProfitLoss[i]['marketId'] === 2 || this.betsProfitLoss[i]['marketId'] === 3 || this.betsProfitLoss[i]['marketId'] === 24) && (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0)) {
          //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
          //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
          // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
          //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
          this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
          this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
        }
      } else {

        if (this.betsProfitLoss[i]['marketId'] === 1) {
          if (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
            this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0 ||
            this.betsProfitLoss[i]['drawProfitStake'] > 0 || this.betsProfitLoss[i]['drawLossStake'] > 0) {
            //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
            //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
            // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
            //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
            this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
              (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
            this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
              (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
            this.betsProfitLoss[i]['drawProfitLoss'] = this.betsProfitLoss[i]['drawTeamProfitLoss'] + ((this.betsProfitLoss[i]['drawProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['homeLossStake']) -
              (this.betsProfitLoss[i]['drawLoss'] + this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayProfitStake']))
          }
        } else if (this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLoss'] > 0) {
          this.betsProfitLoss[i]['homeProfitLoss'] = this.betsProfitLoss[i]['homeTeamProfitLoss'] + (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss']);
          this.betsProfitLoss[i]['awayProfitLoss'] = ''
        }
      }
    }
    this.pLStatusChanged = !this.pLStatusChanged;
  }
  /**
  * This method used to find the index of the bet to remove.
  * @param {array} bets - contains the betslip bets,
  *
  */
  findBetIndex(bets, selection) {
    var betIndex = -1;
    for (var i = 0; i < bets.length; i++) {
      if (bets[i]['eventId'] === selection.eventId &&
        bets[i]['marketId'] === selection.marketId &&
        bets[i]['selectionId'] === selection.selectionId) {
        betIndex = i
      }
    }
    return betIndex;
  }
  /**
  * This method is called when betslip is updated( add or remove bets)
  */
  updateBetSlipStatus() {
    this.betSlipStatusChange = !this.betSlipStatusChange;
    //  console.log("betslip status===", this.betSlipStatusChange)
  }
  selectSport(sportName, sportId) {
    this.sportName = sportName;
    this.sportId = sportId;

  }

  showSignup() {
    this.showSignupPopup = true;
    this.showSignupBlock = true;
    this.showLoginPopup = false;
    this.showForgetPasswordPopup = false;
  }

  hideSignup() {
    this.showSignupPopup = false;
    this.showLoginPopup = false;
    this.showForgetPasswordPopup = false;
  }

  showLogin() {
    this.showSignupPopup = false;
    this.showSignupBlock = false;
    this.showLoginPopup = true;
    this.showForgetPasswordPopup = false;
  }

  showForgetPassword() {
    this.showSignupPopup = false;
    this.showSignupBlock = false;
    this.showLoginPopup = false;
    this.showForgetPasswordPopup = true;
  }

  validateLoginFields(data, callback) {
    var rules = {
      emailId: 'required|min:8',   //email
      password: 'required|min:8'
    };
    var validation = new Validator(data, rules);
    if (validation.passes()) {
      callback(true);
    } else {
      callback(false);
    }
  }

  mainLogin(data, callback) {

    apiCaller(login.login, 'POST', data).then(response => {
      if (response?.data && response.data.hasOwnProperty('isValidLogin') && response.data.isValidLogin &&
        response.data.hasOwnProperty('userLoginToken') && response.data.userLoginToken && response.data.userLoginToken.length > 0 &&
        response.data.hasOwnProperty('userId') && response.data.userId && response.data.userId > 0 && response.data.roleId == 4 &&
        response.data.hasOwnProperty('userName') && response.data.userName && response.data.userName.length > 0 &&
        response.data.hasOwnProperty('userExposure') &&
        response.data.hasOwnProperty('userAccountBalance')
      ) {
        cookie.save("UTocken", response.data.userLoginToken, { path: '/' });
        var userInfo = {
          userId: response.data.userId,
          userName: response.data.userName,
          userAccountBalance: response.data.userAccountBalance,
          userExposure: response.data.userExposure,
        }
        this.userInfo = userInfo;
        cookie.save("userInfo", userInfo, { path: '/' });
        // window.location.reload();
        this.getUserMenu();
        this.isLoggedIn = true;
        this.hideSignup();
        data.msg = "";
        callback(true);
      } else if (!response?.description?.toLowerCase().match('ok')) {
        data.msg = response?.description;
        callback(false);
      }
      else {
        data.msg = "Invalid email or password";
        callback(false);
      }
    });
  }

  /**
 * This method is used to save the bets Profit and loss.
 */
  saveProfitLoss() {
    var params = {
      "userToken": cookie.load('UTocken'),
      "profitLossDetails": []
    }
    //  debugger;
    for (var bet of this.betsProfitLoss) {
      if (typeof (bet.homeProfitLoss) === "number") {
        params.profitLossDetails.push({
          "eventId": bet.eventId,
          "marketId": bet.marketId,
          "homeTeamProfitLoss": bet.homeProfitLoss,
          "awayTeamProfitLoss": bet.awayProfitLoss,
          "drawTeamProfitLoss": bet.drawProfitLoss,
          "providerId": bet.providerId,
          "isFancy": bet.isFancy
        });
      }

    }
    apiCaller(masterPage.saveProfitLoss, 'POST', params).then(response => {
      if (response?.data?.detailsUpdated) {
        //this.betsProfitLoss = [];
        this.getBetsProfitLoss();
      }
    });
  }
  /**
  * This method is used to get the bet profit loss of the user. 
  */
  getBetsProfitLoss(emptyAll) {
    var token = cookie.load('UTocken');
    var self = this;
    if (token && token.length > 0) {
      apiCaller(masterPage.getProfitLoss + "?userToken=" + cookie.load('UTocken'), 'GET').then(response => {
        if ( response?.data?.profitLossDetails?.length > 0) {
          //this.betsProfitLoss = response.data.profitLossDetails;
          var betsProfitLoss = [];
          for (var bet of response.data.profitLossDetails) {
            betsProfitLoss.push({
              eventId: bet.eventId,
              marketId: bet.marketId,
              providerId: bet.providerId,
              subMarket: bet.subMarket,
              odds: 0,
              isFancy: bet.isFancy,
              homeTeamProfitLoss: bet.homeTeamProfitLoss,
              awayTeamProfitLoss: bet.awayTeamProfitLoss,
              drawTeamProfitLoss: bet.drawTeamProfitLoss,
              homeProfit: null,
              homeProfitStake: null,
              drawProfit: null,
              drawProfitStake: null,
              awayProfit: null,
              awayProfitStake: null,
              homeLoss: null,
              homeLossStake: null,
              drawLoss: null,
              drawLossStake: null,
              awayLoss: null,
              awayLossStake: null,
            })
          }
          if (!emptyAll) {
            for (var localBet of self.betsProfitLoss) {
              var isFound = false;
              for (var i = 0; i < betsProfitLoss.length; i++) {
                if (localBet.eventId === betsProfitLoss[i]['eventId'] &&
                  localBet.marketId === betsProfitLoss[i]['marketId'] &&
                  localBet.providerId === betsProfitLoss[i]['providerId'] &&
                  localBet.subMarket === betsProfitLoss[i]['subMarket']) {
                  isFound = true;
                  // betsProfitLoss[i]['homeTeamProfitLoss']=localBet.homeTeamProfitLoss;
                  // betsProfitLoss[i]['awayTeamProfitLoss']= localBet.awayTeamProfitLoss;
                  // betsProfitLoss[i]['drawTeamProfitLoss']= localBet.drawTeamProfitLoss;

                  betsProfitLoss[i]['homeProfit'] = localBet.homeProfit;
                  betsProfitLoss[i]['homeProfitStake'] = localBet.homeProfitStake;

                  betsProfitLoss[i]['drawProfit'] = localBet.drawProfit;
                  betsProfitLoss[i]['drawProfitStake'] = localBet.drawProfitStake;

                  betsProfitLoss[i]['awayProfit'] = localBet.awayProfit;
                  betsProfitLoss[i]['awayProfitStake'] = localBet.awayProfitStake;

                  betsProfitLoss[i]['homeLoss'] = localBet.homeLoss;
                  betsProfitLoss[i]['homeLossStake'] = localBet.homeLossStake;

                  betsProfitLoss[i]['drawLoss'] = localBet.drawLoss;
                  betsProfitLoss[i]['drawLossStake'] = localBet.drawLossStake;

                  betsProfitLoss[i]['awayLoss'] = localBet.drawLoss;
                  betsProfitLoss[i]['awayLossStake'] = localBet.drawLossStake;

                  betsProfitLoss[i]['homeProfitLoss'] = localBet.homeProfitLoss;
                  betsProfitLoss[i]['awayProfitLoss'] = localBet.awayProfitLoss;
                  betsProfitLoss[i]['drawProfitLoss'] = localBet.drawProfitLoss;

                }
              }
              if (!isFound) {
                betsProfitLoss.push(localBet)
              }
            }
          }

          self.betsProfitLoss = betsProfitLoss;
          self.pLStatusChanged = !self.pLStatusChanged;
        }
      });
    }
  }
  /**
  * This method is used to get the user menu data.
  *
  */
  getUserMenu() {
    apiCaller(masterPage.userMenu, 'GET').then(response => {
      if (response?.data?.userMenu?.length > 0) {
        this.userMenu = response.data.userMenu;
      }
    });
  }

  /**
  * This mentod is used to logout user
  *
  */
  logout() {
    var param = {
      "loginToken": cookie.load('UTocken')
    };
    apiCaller(masterPage.logout, 'POST', param).then(response => {
      if (response?.data?.userLogoutStatus) {
        cookie.remove('UTocken', { path: '/' });
        cookie.remove('oneConfirm', { path: '/' });
        cookie.remove('selectedStake', { path: '/' });
        cookie.remove('showOneClick', { path: '/' });
        cookie.remove('userInfo', { path: '/' });
        localStorage.removeItem('defaultStake');
        this.isLoggedIn = false;
        window.location.href = '/';
      }
    });
  }
  /**
  * This method is called when user selects the user menu.
  * @param {menuId} - number, this number contains user menu id.
  */
  userMenuClick(menuId) {
    if (menuId === 8) {
      this.logout();
    }
  }

  /**
  * This method is used to save the event pin or unpin.
  * @param {eventId} - number- contains the eventId to be pinned
  */
  saveEventPin(eventId, marketId, subMarket, providerId, isPinned, callback) {
    if (this.isLoggedIn) {
      if (eventId && eventId > 0) {
        var param = {
          "userToken": cookie.load('UTocken'),
          "eventId": eventId,
          "marketGroupId": marketId,
          "subMarket": subMarket,
          "providerId": providerId
        }
        var serviceLink = !isPinned ? masterPage.savePin : masterPage.removePin
        apiCaller(serviceLink, 'POST', param).then(response => {
          if (response?.data && (response.data.hasOwnProperty('pinDetailsSaved') || response.data.hasOwnProperty('pinDetailsDeleted'))) {
            var serviceSuccess = isPinned ? response.data.pinDetailsDeleted : response.data.pinDetailsSaved
            callback(serviceSuccess);
          } else {
            callback(false);
          }
        });
      }
    } else {
      this.showLogin();
    }

  }

  showhideLeftMenu() {
    //if (this.isLoggedIn == true) {
    //this.getUserMenu();
    this.showLeftMenuItems = !this.showLeftMenuItems;
    //} else {
    //this.showLogin();
    //}
  }
  showhideRightMenu() {
    if (this.isLoggedIn == true) {
      this.getUserMenu();
      this.showRightMenuItems = !this.showRightMenuItems;
    } else {
      this.showLogin();
    }

  }

  enableSearchBox() {
    this.enableSearch = !this.enableSearch;
  }

  handleInputChange(event) {
    // this.setState({ searchLoading: true })
    this.searchLoading = true;
    const searchString = event.target.value;
    this.searchValue = searchString;
    // this.setState({ searchValue: searchString });
    this.searchSuggestions = [];
    if (searchString.length > 0) {
      this.getSearchData(searchString);
    }
    // this.setState({ loading: false });
    this.loading = false;
  }

  /**
 * This method used to get the search data based on user input.
 * @param {data} - string - contains the search string.
 */
  getSearchData(searchString) {
    if (searchString.length > 2) {
      var params = {
        "name": searchString
      }
      apiCaller(masterPage.search, 'POST', params).then(response => {
        if (response?.data?.event?.length > 0) {
          this.searchSuggestions = response.data.event;
        }
      });
    }

  }

  clearSearch() {
    this.searchValue = '';
    this.searchSuggestions = [];
    this.enableSearch = false;
  }

  showHideBetSlipSettings(val, type) {
    if (val === this.footerSelItem && type !== 'addbetslip' && val!=='oneclickbet') {
      val = '';
    }
    if (val === 'settings' || val==='oneclickbet') {
      
      if(val==='oneclickbet'){
       this.toggleOneClick();
      }else{
        this.toggleSettings();
      }
      if (this.isLoggedIn) {
        this.footerSelItem = val;
      } else {
        this.showLogin();
      }
    } else {
      this.footerSelItem = val;
    }
  }

  selectEventView(props, item) {
    this.eventViewId = item.id;
    this.sportId = item.sportId;
    this.sportName = item.sportName;
    props.history.go(props.history.push('/sports/' + item.sportName.toLowerCase() + '/' + item.sportId + '/event/' + item.id));
  }

  /**
  *
  */
  getUserBalanceDetails() {
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(masterPage.getBalance + '?loginToken=' + token, 'GET').then(response => {
      if (response?.data &&
        response?.data?.hasOwnProperty('exposure') &&
        response?.data?.hasOwnProperty('userBalance')
      ) {
        var userInfo = cookie.load('userInfo') ? cookie.load('userInfo') : {};
        userInfo.userAccountBalance = response.data.userBalance;
        userInfo.userExposure = response.data.exposure;
        this.userInfo = userInfo;
        cookie.save("userInfo", userInfo, { path: '/' });
      } else {
        this.showLogoutPopup = true;
      }
    });
  }

  showhideDepositPopup() {
    this.showDeposit = !this.showDeposit;
  }

  clearBetslip() {
    if (!this.isBetEditMode) {
      this.selectedOutcomeUniqueId = 0;
      var betSlipItems = JSON.parse(localStorage.getItem("betslip"));
      for (var item in betSlipItems) {
        if (betSlipItems[item].length > 0) {
          for (var subItem of betSlipItems[item]) {
            this.addToBetSlip(subItem);
          }
        }
      }
    }
  }
  toggleOneClick(){
    this.showOneClick = !this.showOneClick;
    cookie.save("showOneClick",this.showOneClick);
  }
  toggleSettings(){
    this.showSettings=!this.showSettings
  }

  showMessage(msg, msgType) {
    this.msgInfo = msg;
    this.msgType = msgType;
    var self = this;
     setTimeout(function () { self.hideMessage() }, 10 * 1000);
  }
  hideMessage() {
    this.placedBetInfo = {
      type: '',
      stake: '',
      odds: ''
    }
    this.msgInfo = '';
    this.msgType = '';
  }
  

}


export default AppStore