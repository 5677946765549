import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Highlights from './highlights/highlights';
import HighlightsStore from './highlights/highlightsStore';

import Upcoming from './upcoming/upcoming';
import UpcomingStore from './upcoming/upcomingStore';

const highlightsStore = new HighlightsStore();
const upcomingStore = new UpcomingStore();

class HighlightsUpcoming extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // this.props.highlightsUpcomingStore.getUpcomingOptions()
    }
    componentDidMount() {

    }

    componentWillUnmount() {
    }


    componentWillReceiveProps(nextProps) {
    }

    change(event) {
        this.props.highlightsUpcomingStore.defaultValue = event.target.value;
        this.props.highlightsUpcomingStore.defaultOption = event.target.value;
        upcomingStore.selectOption(event.target.value);
    }
    render() {
        const { appStore, highlightsUpcomingStore, betSlipStatusChange, isLoggedIn } = this.props;
        return (
            <div className="RDE-mainContent">
                <div className="RDE-upcomingHighlights">
                    <div className="RDE-sports-nav-tabs">
                        <ul className="RDE-sports-nav-tabs-menu">
                            <li onClick={highlightsUpcomingStore.toggleHighlightsUpcoming.bind(highlightsUpcomingStore, 'highlights')}>
                                <div className={highlightsUpcomingStore.showTab === 'highlights' ? "RDE-sports-nav-tabs-menu__item active" : "RDE-sports-nav-tabs-menu__item"}>
                                    <span className="RDE-skewTxt">
                                        <i className="RDE-icon-highlights"></i>Highlights</span>
                                </div>
                            </li>
                            <li onClick={highlightsUpcomingStore.toggleHighlightsUpcoming.bind(highlightsUpcomingStore, 'upcoming')}>
                                <div className={highlightsUpcomingStore.showTab === 'upcoming' ? "RDE-sports-nav-tabs-menu__item active" : "RDE-sports-nav-tabs-menu__item"}>
                                    <span className="RDE-skewTxt">
                                        <i className="RDE-icon-upcoming"></i> Upcoming</span>
                                </div>
                            </li>
                        </ul>

                        {highlightsUpcomingStore.showTab === 'upcoming' ?
                            <div className="RDE-defaultSelectDropdown">
                                <select onChange={this.change.bind(this)} value={highlightsUpcomingStore.defaultValue}>
                                    {/* <option key={highlightsUpcomingStore.upcomingOptions.all.id} value={highlightsUpcomingStore.upcomingOptions.all.id}>{highlightsUpcomingStore.upcomingOptions.all.unitMeasure}</option>
                                {
                                    highlightsUpcomingStore.upcomingOptions.hr.map((option, index) => {
                                        return (
                                            <option key={option.id} value={option.id}>
                                                {option.frequency + ' ' + option.unitMeasure}
                                            </option>
                                        )
                                    })
                                }
                                <option key={highlightsUpcomingStore.upcomingOptions.today.id} value={highlightsUpcomingStore.upcomingOptions.today.id}>{highlightsUpcomingStore.upcomingOptions.today.unitMeasure}</option>
                                {
                                    highlightsUpcomingStore.upcomingOptions.day.map((option, index) => {
                                        return (
                                            <option key={option.id} value={option.id}>
                                                {option.day + ' ' + option.month}
                                            </option>
                                        )
                                    })
                                } */}
                                    {
                                        highlightsUpcomingStore.upcomingOptionsArray.map((option, index) => {
                                            return (
                                                <option key={option.id} value={option.id}>
                                                    {option.hasOwnProperty('day') ? option.day : option.frequency !== '0' ? option.frequency : ''} {option.hasOwnProperty('month') ? option.month : option.unitMeasure === 'All' ? '' : option.unitMeasure}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div> : ''}
                    </div>
                    {highlightsUpcomingStore.showTab === 'highlights' ?
                        <Highlights appStore={appStore} highlightsStore={highlightsStore} pageId={1} isLoggedIn={isLoggedIn}
                            componentType="highlights"
                            betSlipStatusChange={betSlipStatusChange} />
                        :
                        <Upcoming upcomingStore={upcomingStore}
                            sports={highlightsUpcomingStore.upcomingSports}
                            defaultSport={highlightsUpcomingStore.defaultSport}
                            defaultOption={highlightsUpcomingStore.defaultOption}
                            appStore={appStore}
                            isLoggedIn={isLoggedIn}
                            betSlipStatusChange={betSlipStatusChange}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default observer(HighlightsUpcoming);
