import React, { Component } from 'react';
import { Collapse } from "react-bootstrap";
import { observer } from 'mobx-react';

class LeftMenu extends Component {
    constructor(props) {
        super();
        this.navigateEventView = this.navigateEventView.bind(this);
    }
    componentDidMount() {
        this.props.leftmenuStore.getSportsInfo();
    }
    navigateEventView(sportId, sportName, eventId) {

        this.props.appStore.showhideLeftMenu();
        this.props.appStore.sportId = sportId;
        this.props.appStore.sportName = sportName;
        this.props.appStore.eventViewId = eventId;
        this.props.history.push('/sports/' + sportName.toLowerCase() + '/' + sportId + '/event/' + eventId);
    }
    render() {
        const { appStore, leftmenuStore } = this.props;
        var self = this;
        return (
            <div className="BCM-modal-content">
                <div className="RDE-leftSideMenu_mainHeader">
                    <div className="RDE-leftSideMenu-userTitle">Sports</div>
                    <button type="button" className="btn RDE-modalClose" onClick={() => appStore.showhideLeftMenu()}>
                        <i className="RDE-icon-close cursor-pointer"></i>
                    </button>
                </div>
                <div className="RDE-leftSideMenu_mainContent">
                    <div className="">
                        <ul className="RDE-leftSideMenu-mainNav">
                            {leftmenuStore.sportsInfo.map(function (sport, sportIndex) {
                                return (
                                    <li key={sportIndex}>
                                        <a className={leftmenuStore.sportAccordionIndex.indexOf(sportIndex) === -1 ? "collapsed" : "active"}
                                            onClick={leftmenuStore.openAccordion.bind(leftmenuStore, sportIndex, "sport", sport.id)} >{sport.name}
                                            <i className="RDE-icon-down-arrow"></i></a>
                                        <Collapse in={leftmenuStore.sportAccordionIndex.indexOf(sportIndex) !== -1}>
                                            <ul className="RDE-leftSideMenu-subNav">
                                                {leftmenuStore.competitionsInfo.map(function (comp, compIndex) {
                                                    return (
                                                        <li key={compIndex}>
                                                            <a className={leftmenuStore.compAccordionIndex.indexOf(compIndex) === -1 ? "collapsed" : ""} onClick={leftmenuStore.openAccordion.bind(leftmenuStore, compIndex, "comp", comp.leagueId)}>{comp.leagueName}</a>
                                                            <Collapse in={leftmenuStore.compAccordionIndex.indexOf(compIndex) !== -1}>
                                                                <ul className="RDE-leftSideMenu-subNav-sub">
                                                                    {leftmenuStore.eventsInfo.map(function (event, eventIndex) {
                                                                        return (
                                                                            <li key={eventIndex}>
                                                                                <a onClick={() => self.navigateEventView(sport.id, sport.name, event.eventId)}>{event.eventName}</a>
                                                                            </li>
                                                                        )
                                                                    })}

                                                                </ul>
                                                            </Collapse>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Collapse>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(LeftMenu);