import { extendObservable } from "mobx";
import cookie from 'react-cookies';
import { sportsPage } from "../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../sharedFiles/apiStore';

class SportsLiveNowStore {
  constructor(props) {
    extendObservable(this, {
      liveEvents:[],
      sportId:"",
      sportName:"",
      showLoader:true,
      showErrorMsg:false
    });
  }
  /**
  * This method is get live events data,
  */
  getLiveEvents(){
    this.showLoader=true;
    this.showErrorMsg = false;
    var token="";
    var cookeTocken = cookie.load('UTocken')
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(sportsPage.getLiveEvents+"?sportId="+this.sportId+"&userToken="+token,'GET').then(response => {
          this.showLoader=false;
          if(response.data && response.data.hasOwnProperty('liveEventDetails') &&
             response.data.liveEventDetails && response.data.liveEventDetails.length>0){
              this.liveEvents = response.data.liveEventDetails;
          }else{
              this.showErrorMsg = true;
          }
    });
  }
  /**
  * This method is called when user clicks button in the banner
  * @param buttonType- {number} - this variables contains button type ids
  * Buttontype =1 is registration and 2 for internal sports navigation
  */
  bannerButtonClick(buttonType){
      // code for sport navigation
  }
  /**
  * This method is called when user clicks banner image.
  *
  */
  clickBanner(){
   // console.log("banner click")
  }
}

export default SportsLiveNowStore;
