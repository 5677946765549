import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../../sharedFiles/apiStore';

class BetHistoryStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      fromDate:new Date(),
      betStatus:"Open bets",
      toDate:new Date(),
      userBetDetails:[],
      dateErrMsg:"",
      showErrorMsg:false,
      showLoader:false,
      selectedOption: "today",
      accordionOpenIndex:[]
    });
  }
  /**
  *	This method is called when user selects the from date.
  */
  selectFromDate(selectedDate){
  	this.fromDate = new Date(selectedDate);
    this.dateErrMsg="";
  }
   /**
  * This method is called to  reset the fields.
  */
 resetFields(){
   this.fromDate=new Date();
   this.toDate=new Date();
   this.dateErrMsg='';
   this.selectedOption='today';
   this.getBetHistory();
 }

  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate){
    this.toDate = new Date(selectedDate);
    this.dateErrMsg="";
  }
  /**
  * This method is called when user selects bet status
  * @param {string} status - contains the user selected bet setatus.
  */
  selectBetStatus(status){
      this.betStatus = status;
      this.getBetHistory();
  }
  /**
  * This method is used to get the bet history data based on from and to date.
  */
  getHistory(){
    this.selectedOption="";
    this.getBetHistory();
  }
  /**
  * This method is used to get the bet history data based on from and to date.
  *
  */
  getBetHistory(){
    this.showLoader=true;
    this.showErrorMsg=false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    
    var fDate = fromDate.getFullYear()+"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate();
    var tDate = toDate.getFullYear()+"-"+(toDate.getMonth()+1)+"-"+toDate.getDate()
    if(new Date(fDate) > new Date(tDate)){
      //console.log("show error message");
      this.dateErrMsg="From date should less than to date"
      this.showLoader=false;
      this.showErrorMsg=true;
      return;
    }
    var token="";
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    if(token.length>0){
      var param = {
              "betSlipStatus": this.betStatus === "Open bets"?"placed":this.betStatus === "Settled bets"?"settled":"void",
              "fromDate": fDate,
              "toDate": tDate,
              "transactionType": 0,
              "userToken": token
            }
    //  console.log("param==",param)
      apiCaller(profile.getBetHistory,'POST',param).then(response => {
        this.showLoader=false;
        if(response && response.data &&
           response.data.hasOwnProperty("userBetDetails") && 
           response.data.userBetDetails &&
           response.data.userBetDetails.length>0){
            this.userBetDetails = response.data.userBetDetails;
        }else{
          this.showErrorMsg = true;
        }
      });
    }

  }
  /**
  * This method is called when user select today or yesterday
  *
  */
  selectTodayYesterday(option){
   // console.log("option===",option)
    this.selectedOption = option;
    this.dateErrMsg="";
    if(option === "today"){
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getBetHistory();
    }else if(option === "yesterday"){
      var yDate = new Date();
      var yDay = yDate.getDate()-1;
      var yMonth = yDate.getMonth()+1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth+"/"+yDay+"/"+yYear);
      this.toDate = new Date();
      this.getBetHistory();
    }
  }
}

export default BetHistoryStore;
