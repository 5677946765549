import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import SportsHighlights from "../sportshighlights/sportsHighlights";
import SportsHighlightsStore from "../sportshighlights/sportsHighlightsStore";
import SportsLiveNow from "../sportslivenow/sportsLiveNow";
import SportsLiveNowStore from "../sportslivenow/sportsLiveNowStore";
import SportsAllEvents from "../sportsallevents/sportsAllEvents";
import SportsAllEventsStore from "../sportsallevents/sportsAllEventsStore";

const sportsHighlightsStore = new SportsHighlightsStore();
const sportsLiveNowStore = new SportsLiveNowStore();
const sportsAllEventsStore = new SportsAllEventsStore();
class SportsPageComponent extends Component {
    componentWillMount() {
        if (this.props.match) {
            if (this.props.match.params.sportId &&
                this.props.match.params.sportId !== '' &&
                this.props.match.params.sportId !== null &&
                this.props.appStore.sportId !== this.props.match.params.sportId) {
                this.props.appStore.sportId = this.props.match.params.sportId;
                this.props.appStore.sportName = this.props.match.params.sportName;
                this.props.sportsPageComponentStore.selectedTab = 'highlights';
            }
            if (this.props.match.params.sportId !== null) {
                switch (this.props.match.params.tab) {
                    case 'all':
                        this.props.sportsPageComponentStore.selectedTab = "allevents";
                        break;
                    case 'livenow':
                        this.props.sportsPageComponentStore.selectedTab = "livenow";
                        break;
                    default:
                        this.props.sportsPageComponentStore.selectedTab = "highlights";
                }

            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props) {
            if (nextProps.sportId &&
                nextProps.sportId !== '' &&
                nextProps.sportId !== null &&
                nextProps.sportId !== this.props.sportId && !this.props.match.params.tab) {

                this.props.sportsPageComponentStore.selectedTab = 'highlights';
            }
        }
    }
    componentDidUpdate(nextProps) { }
    render() {
        const { sportsPageComponentStore, appStore } = this.props;
        return (
            <div className="RDE-mainContent">
                <div className="RDE-sportsPage">
                    {/* <div className="RDE-sportsPage RDE-soccer"> */}
                    <div className="RDE-sports-nav-tabs">
                        <ul className="RDE-sports-nav-tabs-menu">
                            <li>
                                <div className={"RDE-sports-nav-tabs-menu__item" + (sportsPageComponentStore.selectedTab === "highlights" ? " active" : "")} onClick={sportsPageComponentStore.tabSelect.bind(sportsPageComponentStore, 'highlights')}>
                                    <span className="RDE-skewTxt">Highlights</span>
                                </div>
                            </li>
                            <li>
                                <div className={"RDE-sports-nav-tabs-menu__item" + (sportsPageComponentStore.selectedTab === "livenow" ? " active" : "")} onClick={sportsPageComponentStore.tabSelect.bind(sportsPageComponentStore, 'livenow')}>
                                    <span className="RDE-skewTxt">Live now</span>
                                </div>
                            </li>
                            <li>
                                <div className={"RDE-sports-nav-tabs-menu__item" + (sportsPageComponentStore.selectedTab === "allevents" ? " active" : "")} onClick={sportsPageComponentStore.tabSelect.bind(sportsPageComponentStore, 'allevents')}>
                                    <span className="RDE-skewTxt">All {this.props.appStore.sportName}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="RDE-sportsPage-content">
                        {sportsPageComponentStore.selectedTab === "highlights" ? <Route render={(props) => <SportsHighlights {...props} sportsHighlightsStore={sportsHighlightsStore}
                            appStore={appStore}
                            sportId={appStore.sportId}
                            sportName={appStore.sportName}
                        />} />
                            : sportsPageComponentStore.selectedTab === "livenow" ? <Route render={(props) => <SportsLiveNow {...props} sportsLiveNowStore={sportsLiveNowStore}
                                appStore={appStore}
                                sportId={appStore.sportId}
                                sportName={appStore.sportName}
                            />} /> : <Route render={(props) => <SportsAllEvents {...props} appStore={appStore}
                                sportsAllEventsStore={sportsAllEventsStore}
                                sportId={appStore.sportId}
                                sportName={appStore.sportName}
                            />} />}
    
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(SportsPageComponent);
