import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { apiCaller } from '../../sharedFiles/apiStore';
import {getAuthenticationNumber} from "../../sharedFiles/helper"

const re = /^[0-9\b]+$/;
const history = createBrowserHistory();

class MainHeaderStore {
 	constructor(){
 		extendObservable(this,{
 			showSignupPopup:false,
 			isLoggedIn:false,
 			headerData:[],
            userMenu:[],
 			authNumber:getAuthenticationNumber(),
            email:"",
            password:"",
            reAuthNumber:'',
            errorMsg:'',
            leftMenuData:[],
            showSignupBlock:true,
            searchValue:'',
            searchSuggestions:[],
            searchResults:[],
            userInfo:{},
            betSlipStore:{},
            currentPath:"",
            history: createBrowserHistory(),
            sportName:"",
            sportId:"",
            appProps:{},
            showSignupPopup : false,
            showSignupBlock:true
 		});
    
        if(!betSlipItems){
            var betSlipItems = {
                backBets:[],
                layBets:[]
            }
            localStorage.setItem("betslip",JSON.stringify(betSlipItems));
        }
    }
    
    
  
  
  
  
  
  
 
 }

 export default MainHeaderStore