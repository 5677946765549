import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { eventView } from "../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../sharedFiles/apiStore';

class ElectionComponentStore {
  constructor(props) {
    extendObservable(this, {
      accordionOpenIndex:[0],
      commonEvents:[],
      showLoader:true,
      showErrorMsg:false,
      mounted:true,
    });
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
  /**
  * This method is used to get the common event data data.
  *
  */
  getCommonEventView(){
    this.showLoader = true;
    this.showErrorMsg = false;
    var token="";
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(eventView.getEvent+"?sportId=3&userToken="+token,'GET').then(response => {
          this.showLoader=false;
          if(response && response.hasOwnProperty('data') && 
            response.data && 
            response.data.hasOwnProperty('leagues') &&
            response.data.leagues &&
            response.data.leagues.length>0){
            this.commonEvents = response.data.leagues;
          }else{
              this.showErrorMsg = true;
          }
    });
  }
}

export default ElectionComponentStore;
