import React, { Component } from 'react';
import { observer } from 'mobx-react';

class PlacedBeteventDetails extends Component {
    componentDidMount() {
        this.props.placedBetEventDetailsStore.component = this.props.component;
        this.props.placedBetEventDetailsStore.getDetails(this.props.selectedEvent);
    }
    handleEventClick() {
        this.props.balanceOverviewTableStore.showEventDetails = false;
    }
    render() {
        const { selectedEvent, component, placedBetEventDetailsStore, selectedSport } = this.props;
        return (
            <div className="RDE-accordion RDE-myBets">
                <div className="RDE-sportsPage-breadcrumb mt-5">
                    <span onClick={() => this.handleEventClick()}>{component === "AS" ? "Account Satatement" : selectedSport.sportName}</span>&nbsp;/&nbsp;<span>{selectedEvent.eventName}</span>
                </div>
                <div className='RDE-accordion-header RDE-no-arrow'>
                    <div className="RDE-myBets__headerDetails">
                        <div className="RDE-myBets__headerContent RDE-myBets__headerLeft">
                            <span className="RDE-labelRight-text">{selectedEvent.eventName}</span>
                        </div>
                    </div>
                </div>
                <div className="RDE-accordion-body">
                    <div className="RDE-myBets__content">
                        {placedBetEventDetailsStore.data.map(function (item, index) {
                            return (
                                <div className="RDE-myBets__contentBox">
                                    <div className="RDE-myBets__contentBox-left">
                                        <div className="RDE-label">Selection: <span>{item.selectName ? item.selectName : "-"}</span></div>
                                        <div className="RDE-label">Bet ID: <span className="">{item.betId ? item.betId : "-"}</span></div>
                                        <div className="RDE-label">Placed: <span className="">{item.transactionDate ? item.transactionDate : "-"}</span></div>
                                    </div>
                                    <div className="RDE-myBets__contentBox-right text-right">
                                        <div className="RDE-label">Status: <span className={"RDE-DB-txtHighlight " + (item.betStatus.toLowerCase() === "won" || item.betStatus.toLowerCase() === "win" ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.betStatus ? item.betStatus : "-"}</span></div>
                                        <div className="RDE-label">Profit/Loss: <span className={"RDE-DB-txtHighlight " + (item.profitLossAmount > 0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.profitLossAmount ? item.profitLossAmount : "-"}</span></div>
                                    </div>
                                    <div className="RDE-myBets__contentBox__footer">
                                        <div className="RDE-label">Type: <span>{item.oddType ? item.oddType : "-"}</span></div>
                                        <div className="RDE-label">Odds: <span className="">{item.odds ? item.odds : "-"}</span></div>
                                        <div className="RDE-label">Stake: <span className="">{item.betStake ? item.betStake : "-"}</span></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div class="grid-noGutter">
                    <div class="col-12 text-right">
                        <table class="table RDE-DB-table-marketSummary">
                            <tbody>
                                <tr>
                                    <td>Back subtotal:</td>
                                    <td>{placedBetEventDetailsStore.backSubTotal}</td>
                                </tr>
                                <tr>
                                    <td>Lay subtotal:</td>
                                    <td>{placedBetEventDetailsStore.laySubTotal}</td>
                                </tr>
                                <tr>
                                    <td>Market subtotal:</td>
                                    <td>{placedBetEventDetailsStore.marketSubTotal}</td>
                                </tr>
                                <tr>
                                    <td>Commission:</td>
                                    <td>{placedBetEventDetailsStore.commission}</td>
                                </tr>
                                <tr>
                                    <th>Net Market Total:</th>
                                    <th>{placedBetEventDetailsStore.netTotal}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(PlacedBeteventDetails);