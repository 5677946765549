import { extendObservable } from "mobx";

class MarketOutcomesStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore:{},
      accordionOpenIndex:[0],
      parentStore:{},
      component:""
    });
  }
  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, marketId, isPinned,providerId,eventProps){
    this.appStore.saveEventPin(eventId, marketId, '', providerId, isPinned, (success)=>{
      eventProps.data.isPinned = !eventProps.data.isPinned;
      this.parentStore.updateData();
    })
  }

    /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
}

export default MarketOutcomesStore;
