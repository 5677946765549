import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { masterPage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';
import { validateCoins } from '../../sharedFiles/helper';

const re = /[0-9\b]+$/;

class SettingsStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showOneClick:false,
      appStore:{},
      subHeaderStore:{},
      defaultStake:"",
      isOddsHighlight:false,
      userCoins:[],
      isEdit:false,
      selectedCoinCount:0,
      maxSelections:6,
      validCoinsErr:"",
      coinCountErr:""
    });
  }
  editMode(){
    this.isEdit = true;
  }
  /**
  * This method is used to get the sub header data
  *
  */
  getSettingsValue(){
    this.selectedCoinCount = 0;
   	apiCaller(masterPage.getUserCoin+"?userToken="+cookie.load('UTocken'),'GET').then(response => {
  	  if(response.data && response.data.hasOwnProperty('stake') && Number(response.data.stake) >0){
  	      this.defaultStake = response.data.stake;
          localStorage.setItem("defaultStake",this.defaultStake);
  	  }else{
        if(localStorage && localStorage.getItem("defaultStake")){
         localStorage.removeItem('defaultStake');
        }
      }
      if(response.data && response.data.hasOwnProperty('isHighlight') && (response.data.isHighlight === 0 || response.data.isHighlight === 1)){
          this.isOddsHighlight = response.data.isHighlight===0?false:true;
          cookie.save('oddsHighights',this.isOddsHighlight);
      }
      if(response.data && response.data.hasOwnProperty('userCoinsDetailsList') && response.data.userCoinsDetailsList.length>0){
          this.userCoins = this.sortCoins(response.data.userCoinsDetailsList);
          var selectedCoins = []
           for(var coin of this.userCoins){
                if(coin.isSelected){
                  this.selectedCoinCount=this.selectedCoinCount+1;
                  selectedCoins.push(coin)
                }
            }
        //  console.log("selectedCoins==",selectedCoins)
          var tempCoins = {
            coins:selectedCoins
          }
          localStorage.setItem("stakeCoins",JSON.stringify(tempCoins))
          this.appStore.coinValuesChanged= !this.appStore.coinValuesChanged;
      }
    });
  }
  /**
  * This method is called when user enters some thing in the edit mode of coins.
  * @param {coinId} - number - contains the coin Id to change the value.
  * @param {e} - JS object - contains the changed fild instans to get the value.
  */
  changeValue(coinId,e){
    if((e.target.value && re.test(e.target.value) && e.target.value >= 1 && e.target.value <= 100000) || e.target.value===""){
      this.validCoinsErr = "";
      for(var coin of this.userCoins){
        if(coin.coinId===coinId){
          coin.coinValue= re.test(e.target.value)?parseInt(e.target.value):"";
        }
      }
    }
  }
  /**
  * This method is used to select and de-select the coin values.
  * @param {coinId} - number - cointains coin id to select or de-select.
  */
  selectCoin(coinId){
    for(var coin of this.userCoins){
      if(coin.coinId===coinId){
        if(coin.isSelected){
          coin.isSelected=0;
          this.selectedCoinCount=this.selectedCoinCount-1;
        }else{
          if(this.selectedCoinCount < this.maxSelections){
            coin.isSelected=1;
            this.selectedCoinCount=this.selectedCoinCount+1;
          }else{
            var found = false;
            for(var value of this.userCoins){
              if(value.isSelected){
                value.isSelected = 0;
                found= true;
                break;
              }
            }
            if(found){
              coin.isSelected=1;
            }
          }
          
        }
      }
    }
  }
  /**
  * this method is used to update the coins.
  *
  */
  updateCoins(){
    if(validateCoins(this.userCoins)){
        var params ={
          userToken:cookie.load('UTocken'),
          userCoinsDetailsList:this.userCoins
        }
        apiCaller(masterPage.updateUserCoins,'POST',params).then(response => {
          if(response.success){
              this.isEdit=false;
              this.getSettingsValue();
          }
        });
    }else{
      this.validCoinsErr = "Stake value should not be empty";
    }
  }
  /**
  * This method is called when user changes(on change event) the default stake.
  * @param {event}- JS object- this method is used to get the target element value.
  */
  defaultStakeChange(event){
    if((event.target.value && re.test(event.target.value) && event.target.value>=1 && event.target.value<=100000) || event.target.value === ""){
      this.defaultStake = event.target.value;
    }
  }
  /**
  * This method is called when user changes(on change event) the odds change highlight.
  * @param {event}- JS object- this method is used to get the target element value.
  */
  oddsHighlightsChange(event){
    this.isOddsHighlight = event.target.checked;
  }
    /**
  * This method is show and hide the settings.
  *
  */
  toggleSettings(){
    // this.showSettings = !this.showSettings;
    this.getSettingsValue(this.settingsStore);
    this.isEdit = false;
    this.validCoinsErr = "";
    this.coinCountErr = "";
  }
  /**
  * This method is used to save the settings.
  *
  */
  saveSettings(){
  //  console.log(this.selectedCoinCount)
    if(this.selectedCoinCount===6){
      this.coinCountErr="";
      var defaultStake = parseInt(this.defaultStake);
      if(defaultStake==="" || defaultStake<1){
        this.defaultStake ="";
        if(localStorage && localStorage.getItem("defaultStake")){
         cookie.remove('defaultStake');
        }
      }
      var params = { "userToken":cookie.load('UTocken'),
                     "stake": defaultStake>0?defaultStake:"",
                     "isHighlight": this.isOddsHighlight?1:0,
                     "userCoinsDetailsList":this.userCoins
                    };
      apiCaller(masterPage.saveSettings,'POST',params).then(response => {
        if(response.success){
          this.toggleSettings();
          this.getSettingsValue();
        //  this.appStore.showBetSlipSettings = '';
          this.appStore.footerSelItem = '';
        }
      });
    }else{
      this.coinCountErr = "Please select 6 coins."
    }

  }
  /**
  * This method is used to sort the coins.
  *
  */
  sortCoins(coins){
    //  console.log(coins);
      coins.sort(this.GetSortOrder("coinValue"));
      return coins;
  }
  GetSortOrder(prop) {  
      return function(a, b) {  
          if (a[prop] > b[prop]) {  
              return 1;  
          } else if (a[prop] < b[prop]) {  
              return -1;  
          }  
          return 0;  
      }  
  }
}

export default SettingsStore;
