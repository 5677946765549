import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { extendObservable } from "mobx";
import { games } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

import cookie from 'react-cookies';

class Games extends Component {

  constructor(props) {
    super(props);
        extendObservable(this, {
          token:"",
          showGames:false,
          operatorId:10372001
        });
  }
  componentWillMount() {
      this.props.appStore.currentPath = 'games';
      var token="";
      var cookeTocken = cookie.load('UTocken');
      if(cookeTocken && cookeTocken.length>0){
        apiCaller(games.getEzugiToken + "?userToken=" + cookeTocken, 'GET').then(response => {
          if ( response?.data?.ezugiToken?.length > 0) {
            this.token = response.data.ezugiToken;
            this.showGames=true;
          }
        });
      }
  }
  render() {
    return (   
        <>{this.showGames?<iframe src={"https://play.livetables.io/auth/?token="+this.token+"&operatorId="+this.operatorId+"&language=en"} width="100%" height="620px"></iframe>:<div style={{"height":"620px"}}></div>}</>
    );
  }
}

export default observer(Games);
