import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { upcomingHighlights } from "../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../sharedFiles/apiStore';
import { selectSelection } from '../../../sharedFiles/helper';
import { getTimeZoneOffset } from '../../../sharedFiles/helper';

class HighlightsUpcomingStore {
  constructor() {
    extendObservable(this, {
      appStore: {},
      showTab: 'highlights',
      upcomingSports: [],
      defaultSport: {},
      defaultOption: 8,
      upcomingOptions: {
        "all": {},
        "hr": [],
        "today": {},
        "day": []
      },
      upcomingOptionsArray: [],
      defaultValue: 0
    })
  }

  /**
  * This method is used to toggle between highlights and upcoming.
  * @param show - {string} this variable contains the 'highlights' or 'upcoming', based on which we will show
  */
  toggleHighlightsUpcoming(show) {
    this.showTab = show;
    if (show === 'upcoming') {
      this.getUpcomingOptions();
    } else {

    }
  }

  /**
  * This method is called from component will mount when component is loaded,
  * in this method we will get the upcoming options and sports data.
  */
  getUpcomingOptions() {
    this.upcomingOptionsArray = [];
    this.upcomingOptions = {
      "all": {},
      "hr": [],
      "today": {},
      "day": []
    }
    apiCaller(upcomingHighlights.getUpcomingOptions + "?timeInterval=" + getTimeZoneOffset(), 'GET').then(response => {
      if ( response?.data?.buttons?.length > 0) {
        this.upcomingSports = response.data.buttons;
        this.defaultSport = this.upcomingSports[0];

      }
      if ( response?.data?.options?.length > 0) {

        for (var option of response.data.options) {
          if (option.unitMeasure === "HR") {
            if (option.frequency === "8") {
              // this.defaultOption = option.id;
              // this.defaultValue = option.id;
            }
            this.upcomingOptions.hr.push(option);
          } else if (option.unitMeasure === "Day") {
            this.upcomingOptions.day.push(this.calculateDayOption(option));
          } else if (option.unitMeasure === "All") {
            this.upcomingOptions.all = option;
            // this.defaultOption = option;
            this.defaultOption = option.id;
              this.defaultValue = option.id;
          } else if (option.unitMeasure === "Today") {
            this.upcomingOptions.today = option;
          }
        }
        var self = this;
        this.upcomingOptionsArray.push(this.upcomingOptions.all);
        for (var option of this.upcomingOptions.hr) {
          this.upcomingOptionsArray.push(option);
        }
        this.upcomingOptionsArray.push(this.upcomingOptions.today);
        for (var option1 of this.upcomingOptions.day) {
          this.upcomingOptionsArray.push(option1);
        }

      }
    });
  }

  /**
  * This method is used to calculate the date based on the frequency provided in service
  * @param option-{object} contains the option
  */
  calculateDayOption(option) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    if (option.hasOwnProperty('frequency') && parseInt(option.frequency) > 0) {
      var d = new Date();
      d.setDate(d.getDate() + parseInt(option.frequency));
      option.day = d.getDate();
      option.month = monthNames[d.getMonth()];
      return option
    }
  }

}

export default HighlightsUpcomingStore;