import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../../sharedFiles/apiStore';

class ProfitLossStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      fromDate:new Date(),
      betStatus:"Open bets",
      toDate:new Date(),
      profitLossData:[],
      dateErrMsg:'',
      showLoader:false,
      showErrorMsg:false,
      accordionOpenIndex:[0],
      totalPL:0,
      selectedOption: "today",
      selectedSport: {},
      sports:[],
    });
  }
  /**
  *	This method is called when user selects the from date.
  */
  selectFromDate(selectedDate){
  	this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate){
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects bet status
  * @param {string} status - contains the user selected bet setatus.
  */
  selectBetStatus(status){
      this.betStatus = status;
      this.getProfitLoss();
  }
  /**
  * This method is used to get the bet history data based on from and to date.
  */
  getPL(){
    this.selectedOption="";
    this.getProfitLoss();
    this.getSportsPL();
  }

  resetFields(){
    this.fromDate=new Date();
    this.toDate=new Date();
    this.dateErrMsg='';
    this.betStatus="Open bets";
    this.selectedOption='today';
    this.getSportsPL();
  }
  /**
  * This method is used to get the bet history data based on from and to date.
  *
  */
  getProfitLoss(){
    //this.totalPL =0;
    
    this.showErrorMsg= false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    var fDate = fromDate.getFullYear()+"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate();
    var tDate = toDate.getFullYear()+"-"+(toDate.getMonth()+1)+"-"+toDate.getDate();
    if(new Date(fDate) > new Date(tDate)){
      this.dateErrMsg="From date should less than to date";
     // this.showLoader=false;
     this.showErrorMsg=true;
      return;
    }
    var token="";
    this.showLoader = true;
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    if(token.length>0){
      var param = {
              "startDate": fDate,
              "endDate": tDate,
              "userToken": token
            }
     // apiCaller(profile.getProfitLoss,'POST',param).then(response => {
      apiCaller(profile.getProfitLoss+"?userToken="+token+"&transactionType=pl&fromDate="+fDate+"&toDate="+tDate+"&sportId="+this.selectedSport.sportId,'GET').then(response => {
        this.showLoader = false;
        // if(response && response.data &&
        //    response.data.hasOwnProperty("selectionList") &&
        //    response.data.selectionList && 
        //   response.data.selectionList.length > 0)
        if(response && response.data && response.data.hasOwnProperty('userBalance')){
          this.balance = response.data.userBalance;
        }
        if(response && response.data &&
          response.data.hasOwnProperty("userTransList") &&
          response.data.userTransList && 
          response.data.userTransList.length>0){
        
            this.profitLossData = response.data.userTransList;
            var accInd=[]
            for(var i =0;i< this.profitLossData.length;i++){
              accInd.push(i)
            }
            this.accordionOpenIndex = accInd;
          // for (var data of this.profitLossData) {
          //     if(data.hasOwnProperty('amount') && !isNaN(data.amount)){
          //       if(data.hasOwnProperty('isProfit') && data.isProfit){
          //         this.totalPL = this.totalPL + data.amount;
          //       }else{
          //         this.totalPL = this.totalPL - data.amount;
          //       }
          //     }
              
          //   }
        }else{
          this.showErrorMsg = true
        }
      });
    }

  }
  /**
  * This method is called when user select today or yesterday
  *
  */
  selectTodayYesterday(option){
   // console.log("option===",option)
    this.selectedOption = option;
    this.dateErrMsg="";
    if(option === "today"){
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getProfitLoss();
    }else if(option === "yesterday"){
      var yDate = new Date();
      var yDay = yDate.getDate()-1;
      var yMonth = yDate.getMonth()+1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth+"/"+yDay+"/"+yYear);
      this.toDate = new Date();
      this.getProfitLoss();
    }
  }

  getSportsPL(){
    this.totalPL = 0;
   
    this.showErrorMsg= false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    var fDate = fromDate.getFullYear()+"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate();
    var tDate = toDate.getFullYear()+"-"+(toDate.getMonth()+1)+"-"+toDate.getDate();
    if(new Date(fDate) > new Date(tDate)){
      this.dateErrMsg="From date should less than to date"
      this.showErrorMsg=true
      return;
    }
    var token="";
    this.showLoader = true;
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    if(token.length>0){
      apiCaller(profile.getSports+"?userToken="+token+"&fromDate="+fDate+"&toDate="+tDate,'GET').then(response => {
        this.showLoader = false;
        if(response && response.hasOwnProperty('data') && Array.isArray(response.data) &&
           response.data.length>0){
            this.sports = response.data;
            this.totalPL=0;
            for(var sport of this.sports){
              this.totalPL = this.totalPL+sport.profitLossAmount;
            }
            this.selectedSport = this.sports[0];
            this.getProfitLoss();
        }else{
          this.sports=[];
          this.showErrorMsg = true
        }
      });
    }
  }
  selectSport(obj){
    this.selectedSport = obj;
    this.getProfitLoss();
  }
}

export default ProfitLossStore;
