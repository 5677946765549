import { extendObservable } from 'mobx';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'
import { apiCaller } from '../../sharedFiles/apiStore';
import { masterPage,upcomingHighlights } from "../../sharedFiles/EndpointConfig";

const re = /^[0-9\b]+$/;
const history = createBrowserHistory();

class SportsHeaderStore {
 	constructor(){
 		extendObservable(this,{
			 selectedSportName : "",
			 selectedSportId : "",
			 sportId:"",
			 pageId:"",
			 leftMenuData:[],
			 sportsData:[]
 		});
	}
	
	/**
  * This method is used to get the Main header sports.
  *
  */
  getMainHeaderSportsData(){
	apiCaller(masterPage.getLeftMenu,'GET').then(response => {
		if(response?.data?.categories?.length>0){
			this.leftMenuData = response.data.categories;
			this.sportsData = response.data.categories[0].Sport;
	  for(var cat of this.leftMenuData){
		for(var sport of cat.Sport){
		  if(this.selectedSportId === "" || this.selectedSportId === null){
			  this.selectedSportId = sport.id;
			  this.selectedSportName= sport.name;
		  }
		  break;
		}
		if(this.selectedSportId!=="" && this.sportName!==""){
		  break
		}
	  }
	  // this.sportId = this.leftMenuData[0].Sport.length>0?this.leftMenuData[0].Sport[0].id;
	  // this.sportName = this.leftMenuData[0].Sport.length>0?this.leftMenuData[0].Sport[0].name;
		}
		else{
			this.leftMenuData=[];
			this.sportsData=[];
		}
	});
}
	 
	selectSport(sportName,sportId){
		this.selectedSportName = sportName;
		this.selectedSportId = sportId;
	  }
 }

 export default SportsHeaderStore