import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Collapse } from "react-bootstrap";

import imageUrls from '../../../../../sharedFiles/imageUrls';
import { getLocalDate } from '../../../../../sharedFiles/helper';
import BalanceOverviewTable from '../../balanceoverviewtable/balanceOverviewTable';
import BalanceOverviewStore from '../../balanceoverviewtable/balanceOverviewTableStore';
import { sportsPage } from '../../../../../sharedFiles/EndpointConfig';

const balanceOverviewStore = new BalanceOverviewStore();

class ProfitLoss extends Component {
  componentWillMount() {
    //this.props.profitLossStore.getProfitLoss();
    this.props.profitLossStore.resetFields();
  }
  componentWillReceiveProps(nextProps) { }
  handleIconClick(index) {
    if (index >= 0) {
      var eventIndex = this.props.profitLossStore.accordionOpenIndex.indexOf(index);
      if (eventIndex === -1) {
        this.props.profitLossStore.accordionOpenIndex.push(index);
      } else {
        this.props.profitLossStore.accordionOpenIndex.splice(eventIndex, 1);
      }
    }
  }
  render() {
    const { profitLossStore } = this.props;
    var self = this;
    return (

      <div className="RDE-DB-myBets__profitLoss">
        <div className="RDE-sports-nav-tabs">
          <ul className="RDE-sports-nav-tabs-menu">
            <li>
              <div className="RDE-sports-nav-tabs-menu__item active">
                <span className="RDE-skewTxt">Exchange</span>
              </div>
            </li>
            {/* <li>
              <div className="RDE-sports-nav-tabs-menu__item">
                <span className="RDE-skewTxt">Casino</span>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="space-5"></div>
       
        {!balanceOverviewStore.showEventDetails && <> <div className="RDE-DB-myBets-filters">
          


          <div className="grid">
            {/* <div className="col-12 mb-10">
              <span className="RDE-DB-myBets-filters__label">Period:&nbsp;</span>
            </div> */}
            <div className="col-12">
              <div className="RDE-DB-dateBox-container mb-5">
                <div className="RDE-DB-dateBox">
                  <span className="RDE-DB-myBets-filters__label">From:&nbsp;</span>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker selected={profitLossStore.fromDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-start"
                        onChange={profitLossStore.selectFromDate.bind(profitLossStore)}
                        className="RDE-DB-datePickerInput"
                        maxDate={new Date()} />
                    </div>
                  </div>
                </div>
                <div className="RDE-DB-dateBox">
                  <span className="RDE-DB-myBets-filters__label">To:&nbsp;</span>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      <DatePicker selected={profitLossStore.toDate}
                        selectsEnd
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                        onChange={profitLossStore.selectToDate.bind(profitLossStore)}
                        className="RDE-DB-datePickerInput"
                        maxDate={new Date()} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="RDE-DB-datePickerErrorMessage text-center">{profitLossStore.dateErrMsg}</div>

              <div className="space-2"></div>
              <div className="grid-noGutter-middle">
                <div className="col-12 text-center">
                  <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={profitLossStore.getPL.bind(profitLossStore)}>Get P&amp;L</button>
                </div>
              </div>
            </div>
          </div>
          <div className="RDE-hr"></div>
          <div className="grid-noGutter">
            <div className="col-12">
              <div className="RDE-DB-myBets-filter__dropdown">
                <span className="RDE-DB-myBets-filters__label">Sport Name:&nbsp;</span>
                <Dropdown className="RDE-DB-dropdownDefault dropdown">
                  <Dropdown.Toggle variant="dropdown-toggle btn btn-success" id="dropdown-basic">
                    {profitLossStore.selectedSport.sportName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul>
                      {profitLossStore.sports.map(function (sport, sportIndex) {
                        return (
                          <li><Dropdown.Item onClick={() => profitLossStore.selectSport(sport)} eventKey={"sportdrpdwn" + sportIndex}>{sport.sportName}</Dropdown.Item></li>
                        )
                      })}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>


        </div>
        
        <div className="space-5"></div>
        {!profitLossStore.showErrorMsg && !profitLossStore.dateErrMsg&& !profitLossStore.showLoader ? <div className="grid-middle-noGutter">
          <div className="col-6">
              <span><b>Total P/L: PH</b> <span className={"RDE-DB-txtHighlight" + (profitLossStore.selectedSport.profitLossAmount && profitLossStore.selectedSport.profitLossAmount < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>
                <b>({profitLossStore.selectedSport.profitLossAmount ? Math.abs(profitLossStore.selectedSport.profitLossAmount.toFixed(2)) : ''})</b></span></span>
          </div>
          {/* <div className="col-6 text-right">
            <span className={"RDE-DB-txtHighlight" + (profitLossStore.totalPL < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}><b>({Math.abs(profitLossStore.totalPL.toFixed(2))})</b></span>
          </div> */}
        </div> : ''}
                 </> }
        {/* {!profitLossStore.showErrorMsg && !profitLossStore.showLoader ?
          <div className="RDE-DB-table d-none">
        <table className="RDE-DB-table-default">
            <thead>
              <tr>
                <th>Market</th>
                <th>Selection</th>
                <th>Start time</th>
                <th className="text-right">Settled date</th>
                <th className="text-right">Profit/Loss</th>
              </tr>
            </thead>
            {
              profitLossStore.profitLossData.map((item, index) => {
                return <tbody onClick={profitLossStore.openAccordion.bind(profitLossStore, index)} key={"profitloss_" + index}>
                  <tr>
                    <td>{item.sportName} > {item.eventName}</td>
                    <td>{item.marketName}</td>
                    <td>{item.startTime && item.startTime.length > 0 ? getLocalDate(item.startTime) : "-"}</td>
                    <td className="text-right">{item.settledDate && item.settledDate.length > 0 ? getLocalDate(item.settledDate) : '-'}</td>
                    <td className="text-right"> <span className={"RDE-DB-txtHighlight" + (!item.isProfit ? " RDE-DB-loss" : " RDE-DB-profit")}>({item.amount})</span></td>
                  </tr>
                  <Collapse in={profitLossStore.accordionOpenIndex.indexOf(index) !== -1}>
                    <tr>
                      <td colspan="5">
                        <div className="RDE-DB-table-accordianBody">
                          <div className="RDE-DB-table">
                            <table className="RDE-DB-table-default">
                              <thead>
                                <tr>
                                  <td width="10%">&nbsp;</td>
                                  <th>Bet ID</th>
                                  <th>Selection</th>
                                  <th>Odds</th>
                                  <th>Stake</th>
                                  <th>Type</th>
                                  <th className="RDE-DB-dateTimeColumn text-right">Placed</th>
                                  <th className="text-right">Profit/Loss</th>
                                </tr>
                              </thead>
                              {
                                item.bets.map((bet, betIndex) => {
                                  return <tbody><tr>
                                    <td width="10%">&nbsp;</td>
                                    <td>{bet.betId}</td>
                                    <td>{bet.selection && bet.selection.length > 0 ? bet.selection : '--'}</td>
                                    <td>{bet.odds > 0 ? bet.odds : '--'}</td>
                                    <td>{bet.stake > 0 ? bet.stake : '--'}</td>
                                    <td>{bet.type && bet.type.length > 0 ? bet.type : '--'}</td>
                                    <td className="text-right">{bet.betPlaced}</td>
                                    <td className="text-right"><span className={"RDE-DB-txtHighlight" + (!item.isProfit ? " RDE-DB-loss" : " RDE-DB-profit")}>({bet.amount})</span></td>
                                  </tr>
                                    <tr>
                                      <td width="10%">&nbsp;</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">
                                        <div>Total Stakes</div>
                                        <div><b>{bet.type}</b> subtotal</div>
                                        <div>Market subtotal</div>
                                      </td>
                                      <td className="text-right">
                                        <div>{bet.totalStake}</div>
                                        <div><span className={"RDE-DB-txtHighlight" + (!item.isProfit ? " RDE-DB-loss" : " RDE-DB-profit")}>({bet.amount})</span></div>
                                        <div><span className={"RDE-DB-txtHighlight" + (!item.isProfit ? " RDE-DB-loss" : " RDE-DB-profit")}>({bet.amount})</span></div>
                                      </td>
                                    </tr></tbody>
                                })
                              }
                            </table>
                          </div>
                          </div>
                              </td>
                              </tr>
                              </Collapse>
                              </tbody>
                              })
                              }
                              </table>
          </div> : ''} */}

        <BalanceOverviewTable data={profitLossStore.profitLossData} component="PL" store={profitLossStore}
          balanceOverviewStore={balanceOverviewStore} selectedSport={profitLossStore.selectedSport}/>

        {/* {profitLossStore.profitLossData.map((item, index) => {
          return (
            <div className={"RDE-accordion RDE-myBets "+(profitLossStore.accordionOpenIndex.indexOf(index) === -1 ?"collapsed":"") }>
            <div className="RDE-accordion-header" onClick={() => self.handleIconClick(index)}>  
                <div className="RDE-myBets__headerDetails">
                    <div className="RDE-myBets__headerContent RDE-myBets__headerfullWidth">
                        <span className="RDE-headingText">Sport/Event:</span>
                        <span className="">{item.sportName}/{item.eventName}</span>
                    </div>
                    
                </div>
            </div>
              <div className="RDE-accordion-body collapse show">
                {item.bets.map((bet, betIndex) => {
                  return (
                    <Collapse in={profitLossStore.accordionOpenIndex.indexOf(index)!==-1}>
                    <div className="RDE-myBets__content">
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-left">
                          <span className="RDE-label">Market:</span>
                          <span className="RDE-labelRight-text">{item.marketName}</span>
                        </div>
                        <div className="RDE-myBets__contentBox-right text-right">
                          <span className="RDE-label">Start time:</span>
                          <span className="RDE-labelRight-text">{item.startTime && item.startTime.length > 0 ? getLocalDate(item.startTime) : "-"}</span>
                        </div>
                      </div>
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-left">
                          <span className="RDE-label">Settled date:</span>
                          <span className="RDE-labelRight-text">{item.settledDate && item.settledDate.length > 0 ? getLocalDate(item.settledDate) : '-'}</span>
                        </div>
                        <div className="RDE-myBets__contentBox-right text-right">
                          <span className="RDE-label">Profit/Loss:</span>
                          <span className={"RDE-labelRight-textt" + (!item.isProfit ? " RDE-DB-loss" : " RDE-DB-profit")}>({item.amount})</span>
                        </div>
                      </div>
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-left">
                          <span className="RDE-label">Bet ID:</span>
                          <span className="RDE-labelRight-text">{bet.betId}</span>
                        </div>
                        <div className="RDE-myBets__contentBox-right text-right">
                          <span className="RDE-label">Odds:</span>
                          <span className="RDE-labelRight-text">{bet.odds > 0 ? bet.odds : '--'}</span>
                        </div>
                      </div>
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-left">
                          <span className="RDE-label">Stake:</span>
                          <span className="RDE-labelRight-text">{bet.stake > 0 ? bet.stake : '--'}</span>
                        </div>
                        <div className="RDE-myBets__contentBox-right text-right">
                          <span className="RDE-label">Type:</span>
                          <span className="RDE-labelRight-text">{bet.type && bet.type.length > 0 ? bet.type : '--'}</span>
                        </div>
                      </div>
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-text">
                          <span className="RDE-label">Placed:</span>
                          <span className="RDE-labelRight-text">{bet.betPlaced}</span>
                        </div>
                      </div>
                      <div className="RDE-myBets__contentBox">
                        <div className="RDE-myBets__contentBox-text">
                          <span className="RDE-label">Selection:</span>
                          <span className="RDE-labelRight-text">{bet.selection && bet.selection.length > 0 ? bet.selection : '--'}</span>
                        </div>
                      </div>
                    </div>
                    </Collapse>
                  )
                    })}
                              
              </div>
            
        </div>
          )
        })} */}
         {/* <div className="RDE-DB-datePickerErrorMessage text-center">{profitLossStore.dateErrMsg}</div> */}

        {profitLossStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for your selection.
                          </div> : ''}
        {profitLossStore.showLoader ? <div className="RDE-loader">
          <img src={imageUrls.loaderIcon} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(ProfitLoss);
