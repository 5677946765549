import React, { Component } from 'react';
import { observer } from 'mobx-react';
import imageURLs from '../../sharedFiles/imageUrls';
import { NavLink,BrowserRouter as Router } from 'react-router-dom';

class SportsHeader extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if(window.location.pathname.match('inplay')) {
      this.props.inplayHighlightsStore.pageId = 2;
      this.props.inplayHighlightsStore.getHighlightsSports();
    } else {
      this.props.appStore.getMainHeaderSportsData();
    }
  }

  selectSport(sportName, sportId) {
    // this.props.appStore.getMainHeaderSportsData();
    if(! window.location.pathname.match('inplay')){
      this.props.appStore.selectSport(sportName, sportId);
      this.props.history.push('/sports/'+sportName.replace(' ','-').toLowerCase()+'/'+sportId);}
  }
  selectGames(){
    this.props.history.push('/inplay')
  }
  render() {
    const { appStore,sportsHeaderStore,inplayHighlightsStore } = this.props;
    var self = this;
    var sportsData = [];
    if(window.location.pathname.match("inplay")){
      sportsData = inplayHighlightsStore.sports;
    }else{
      sportsData = appStore.sportsData;
    }
    return (
      <div className="RDE-topSportsHeader">
        <div className={window.location.pathname.match('inplay')? "RDE-sportsList RDE-inplaySportsList":"RDE-sportsList"}>
          <ul className="">
            {sportsData.map(function (sport, sportIndex) {
              return (
              <li key={'sport_' + sport.id} onClick={window.location.pathname.match('inplay') ? inplayHighlightsStore.selectSport.bind(inplayHighlightsStore,sport) : self.selectSport.bind(self, sport.name, sport.id)}>
                <div className={((! window.location.pathname.match('inplay') && window.location.pathname.match(sport.name.toLowerCase())) ||
                  (window.location.pathname.match('inplay') && sport.name === inplayHighlightsStore.selectedSport.name)) ? "RDE-sportsList-item active" : "RDE-sportsList-item"}>
                  <i className={sport.name === "Football" ? "RDE-icon-football" : sport.name === "Tennis" ? "RDE-icon-tennis" : sport.name === "Cricket" ? "RDE-icon-cricket" : "RDE-icon-horse-racing"}></i>
                  <div className="RDE-sportsList-item__details">
                    <span className="RDE-sportsList-item__sportName">{sport.name}</span>
                  </div>
                  {window.location.pathname.match('inplay') ? <span className="RDE-liveLabel"><img src={imageURLs.liveIcon} /></span>:''}
                </div>
              </li>)
            })}
            <li onClick={self.selectGames.bind(self)} className={!appStore.isLoggedIn?"RDE-menuItem-disabled ":""}>
                <div className={window.location.pathname.match('inplay') ? "RDE-sportsList-item active" : "RDE-sportsList-item"}>
                <i className="RDE-icon-inplay"></i>
                  <div className="RDE-sportsList-item__details">
                      <span className="RDE-sportsList-item__sportName">Inplay</span>
                  </div>
                </div>
                {/* <li className={(window.location.pathname.match('inplay') && appStore.footerSelItem === "inplay") ? "active" : ''}>
						<div className="RDE-mobileFooter__item" onClick={self.navigatePath.bind(self, 'inplay')}>
							<i className="RDE-icon-inplay"></i>
							<div>Inplay</div>
						</div>
					</li> */}
              </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default observer(SportsHeader);
