import { extendObservable } from 'mobx';
import cookie from 'react-cookies';


class RightMenuStore {
    constructor() {
        extendObservable(this, {
            appStore:{},
            accordionOpenIndex:[0],
        });
    }

  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
}

export default RightMenuStore;