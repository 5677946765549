import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";
import imageUrls from '../../../../sharedFiles/imageUrls';
import { preMatchRefreshTime } from '../../../../sharedFiles/helper';
import EventComponent from '../../../../sharedComponents/eventcomponent/eventComponent';
import EventComponentStore from '../../../../sharedComponents/eventcomponent/eventComponentStore';

const eventComponentStore = new EventComponentStore();

class Upcoming extends Component {

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.upcomingStore.selectedSport = this.props.defaultSport;
		this.props.upcomingStore.selectedOption = this.props.defaultOption;
		this.props.upcomingStore.mounted = true;
		this.props.upcomingStore.getUpcomingEventData();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.defaultSport.id !== nextProps.defaultSport.id) {
			this.props.upcomingStore.selectedSport = nextProps.defaultSport;
			this.props.upcomingStore.getUpcomingEventData();
		}
		if (this.props.defaultOption !== nextProps.defaultOption) {
			this.props.upcomingStore.selectedOption = nextProps.defaultOption;
			this.props.upcomingStore.getUpcomingEventData();
		}
		if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
			this.props.upcomingStore.getUpcomingEventData();
		}
	}
	componentDidMount() {
		var self = this;
		setTimeout(function reCallService() {
			if (self.props.upcomingStore.mounted) {
				self.props.upcomingStore.getUpcomingEventData();
				setTimeout(reCallService, preMatchRefreshTime);
			}
		}, preMatchRefreshTime);
	}
	componentWillUnmount() {
		this.props.upcomingStore.mounted = false;
	}
	render() {
		const { appStore, upcomingStore, sports } = this.props;
		return (
			<div className="RDE-upcomingHighlights-content">
				<div className="RDE-highlightsSection">
					<div className="RDE-nav-pills mt-5 mb-5">
						<ul className="RDE-nav-pills-menu">
							{
								sports.map((sport, index) => {
									return (<li key={"up_sport_" + index} onClick={upcomingStore.selectSport.bind(upcomingStore, sport)}>
										<div className={upcomingStore.selectedSport.id === sport.id ? "RDE-nav-pills-menu-item active" : "RDE-nav-pills-menu-item"}>
											{sport.name}
										</div>
									</li>)
								})
							}
						</ul>
					</div>
				</div>
				<div className="RDE-eventItemGroup">
					<div className="RDE-accordion-group">
						{
							upcomingStore.eventData.map(function (event, eventIndex) {
								if (eventIndex < upcomingStore.itemsShow) {
									return (<Route key={"up_sport_" + eventIndex} render={(props) => <EventComponent {...props} appStore={appStore} event={event}
										eventComponentStore={eventComponentStore} eventIndex={eventIndex} upcomingStore={upcomingStore}
										componentType="upcoming"
										sportId={upcomingStore.selectedSport.id}
										sportName={upcomingStore.selectedSport.name} />} />)
								}
							})
						}
					</div>
				</div>
				{upcomingStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
					Currently data is not available for this selection.
				</div> : ''}
				{upcomingStore.showLoader ? <div className="RDE-loader">
					<img src={imageUrls.loaderIcon} />
				</div> : ''}

				{upcomingStore.eventData.length > upcomingStore.initialItemShow ? <div className="RDE-showMore">
					{upcomingStore.itemsShow < upcomingStore.eventData.length ? <div onClick={upcomingStore.showMore.bind(upcomingStore)}><span>Show more </span><i className="RDE-icon-down-arrow"></i></div> :
						<div onClick={upcomingStore.showLess.bind(upcomingStore)}><span>Show less </span><i className="RDE-icon-up-arrow"></i></div>}
				</div> : ''}
			</div>
		);
	}
}

export default observer(Upcoming);