import { extendObservable } from "mobx";
import { homePage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';
import cookie from 'react-cookies';

class BannerStore {
  constructor(props) {
    extendObservable(this, {
      bannerData: [],
      appStore:{},
      pageId:""
    });
  }
  /**
  * This method is called from component will mount when component is loaded,
  * in this method we will get the banners data.
  */
  getBannerData() {  
    this.bannerData = [];
    var userToken = cookie.load("UTocken") && !!cookie.load("UTocken") ? cookie.load("UTocken") : null;
    var paramString = "";
    if (userToken) {
      paramString = "pageId=" + this.pageId + " &&userToken=" + userToken+'&isDesktop='+false;
    }
    else {
        paramString = "pageId=" + this.pageId;
    }
    apiCaller(homePage.getBanners+"?"+paramString,'GET').then(response => {
          if( response?.data?.bannersList?.length>0){
              this.bannerData = response.data.bannersList;
          }
    });
  }
  
  /**
  * This method is called when user clicks button in the banner
  * @param buttonType- {number} - this variables contains button type ids
  * Buttontype =1 is registration and 2 for internal sports navigation
  */
  bannerButtonClick(button){
      // code for sport navigation
      if(button.buttonText === "Tennis"){
        this.props.histoty.push('path')
      }else if(button.buttonText === "Football"){
        this.props.histoty.push('path')
      }else if(button.buttonText === "Horse Racing"){
        this.props.histoty.push('path')
      }
  }
  /**
  * This method is called when user clicks banner image.
  *
  */
  clickBanner(){
   // console.log("banner click")
  }
}

export default BannerStore;
