import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import { Route } from "react-router-dom";
import { selectSelection, accoridonTimeFormat } from '../../../sharedFiles/helper';
import EventComponent from '../../../sharedComponents/eventcomponent/eventComponent';
import EventComponentStore from '../../../sharedComponents/eventcomponent/eventComponentStore';

const eventComponentStore = new EventComponentStore();
class HighlightEvents extends Component {
  componentWillMount() { }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { events, componentType, appStore, highlightEventsStore, sportId, sportName } = this.props;
    return (
      <div className="RDE-accordion-group">
        {
          events.map((day, index) => {

            var selections = [];
            if (day && day.events && day.events.length > 0) {
              selections = selectSelection(day.events[0]);
            }
            return <div className={"RDE-accordion" + (highlightEventsStore.accordionOpenIndex.indexOf(index) === -1 ? " collapsed" : "")} key={"sports_" + componentType + "_day_" + index}>
              <div className="RDE-accordion-header RDE-singleLineEventHeader" onClick={highlightEventsStore.openAccordion.bind(highlightEventsStore, index)}>
                <div className="RDE-singleLineEventHeader__left">
                  <div className="RDE-singleLineEventHeader-title">
                    {componentType === "sportsLiveNow" ? day.leagueName + " (" + day.countryName + ")" : accoridonTimeFormat(day.date)}
                  </div>
                </div>
              </div>
              <Collapse in={highlightEventsStore.accordionOpenIndex.indexOf(index) !== -1}>
                <div className="RDE-accordion-body">
                  <div className="RDE-eventItemGroup">
                    {
                      day && day.events && day.events.map((event, eventIndex) => {
                        return <Route key={"sports_" + componentType + "_event_" + eventIndex} render={(props) => <EventComponent {...props} event={event}
                          appStore={appStore}
                          eventComponentStore={eventComponentStore}
                          componentType={componentType}
                          sportId={sportId}
                          sportName={sportName} eventIndex={eventIndex} />} />
                      })
                    }
                  </div>
                </div>
              </Collapse>
            </div>
          })
        }
      </div>
    )
  }
}
export default observer(HighlightEvents);
