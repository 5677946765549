import React, { Component } from 'react';
import { observer } from 'mobx-react';
import OddsComponent from '../oddscomponent/oddsComponent';
import { formatDate } from '../../sharedFiles/helper';
import OutcomeComponent from '../outcomescomponent/outcomeComponent';
import OutcomeComponentStore from '../outcomescomponent/outcomeComponentStore';

const outcomeComponentStore = new OutcomeComponentStore();

class MarketOutcomes extends Component {
    componentWillMount() {
        this.props.marketOutcomesStore.appStore = this.props.appStore;
        this.props.marketOutcomesStore.component = this.props.component;
        this.props.marketOutcomesStore.parentStore = this.props.parentStore;
    }
    componentWillReceiveProps(nextProps) {

    }
    render() {
        const { data,
            eventSuspended,
            eventBlocked,
            appStore,
            eventName,
            eventId,
            marketOutcomesStore,
            component,
            homeTeam,
            awayTeam,
            homeTeamId,
            awayTeamId,
            kickOffTime,
            profitLoss,
            isLive,
            appStoreProfitLoss,
            parentStore} = this.props;
        return (

            <>
                <div className="RDE-singleLineOutcomeMarketHeader">
                    <div className="RDE-singleLineOutcomeMarketHeader__leftContainer">
                        <div className="RDE-sports-nav-tabs">
                            <ul className="RDE-sports-nav-tabs-menu">
                                <li>
                                    <div className={"RDE-sports-nav-tabs-menu__item RDE-pin" + (data.isPinned ? " active" : "")} onClick={marketOutcomesStore.savePin.bind(marketOutcomesStore, eventId, data.marketGroupId, data.isPinned, data.providerId, this.props)}>
                                        <span className="RDE-skewTxt">
                                            <i className="RDE-icon-pin"></i>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className="RDE-sports-nav-tabs-menu__item active">
                                        <span className="RDE-skewTxt">
                                            {/* Match Odds */}
                                            {data.marketName}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="RDE-singleLineOutcomeMarketHeader__rightContainer">
                        <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll">Back</div>
                        <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll">Lay</div>
                    </div>
                </div>
                <OutcomeComponent outcomes={data.outcomes ? data.outcomes : []}
                    eventSuspended={eventSuspended}
                    marketSuspended={data.marketSuspended}
                    eventBlocked={eventBlocked}
                    marketBlocked={data.marketBlocked}
                    eventName={eventName}
                    eventId={eventId}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    isLive={isLive}
                    marketName={data.marketName}
                    marketId={data.marketGroupId}
                    minStake={data.minStake}
                    maxStake={data.maxStake}
                    component={component}
                    clMarketId={data.clMarketId}
                    providerId={data.providerId}
                    appStore={appStore}
                  //  providerId={data.providerId}
                    marketOutcomesStore={marketOutcomesStore}
                    profitLoss={profitLoss}
                    outcomeComponentStore={outcomeComponentStore}
                    appStoreProfitLoss={appStoreProfitLoss}
                    parentStore={parentStore}/>
            </>
        )
    }
}
export default observer(MarketOutcomes);
