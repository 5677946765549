import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import InplayHighlights from './inplayhighlights/inplayHighlights';
// import InplayHighlightsStore from './inplayhighlights/inplayHighlightsStore';
// import Highlights from '../homepage/highlightsupcoming/highlights/highlights';
// import HighlightsStore from '../homepage/highlightsupcoming/highlights/highlightsStore';
import EventView from '../../sharedComponents/eventview/eventView';
import EventViewStore from '../../sharedComponents/eventview/eventViewStore';

const eventViewStore = new EventViewStore();
// const inplayHighlightsStore = new InplayHighlightsStore();

class InplaypageLayout extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.appStore.currentPath = "inplay";
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, isLoggedIn, inplayHighlightsStore } = this.props;
    return (
      <div className="RDE-mainContent">
        <Switch>
          {/* <Route path="/inplay" exact render={(props)=><Highlights  {...props} appStore={appStore}
                                                                          highlightsStore={highlightsStore}
                                                                          isLoggedIn={isLoggedIn} 
                                                                          pageId={2}/>} /> */}
          <Route path="/inplay" exact render={(props) => <InplayHighlights {...props} appStore={appStore} inplayhighlightsStore={inplayHighlightsStore}
            pageId={2} componentType="inplay" isLoggedIn={appStore.isLoggedIn}
            betSlipStatusChange={appStore.betSlipStatusChange} />} />
          <Route path="/inplay/:sportName/:sportId/event/:eventId" exact render={(props) => <EventView {...props} appStore={appStore} eventViewStore={eventViewStore} isLoggedIn={appStore.isLoggedIn}
            betSlipStatusChange={appStore.betSlipStatusChange} />} />
        </Switch>
      </div>
    );
  }
}

export default observer(InplaypageLayout);
