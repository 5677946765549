import { extendObservable } from 'mobx';
import cookie from 'react-cookies';

import { upcomingHighlights } from "../../../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../../../sharedFiles/apiStore';
import { selectSelection } from '../../../../sharedFiles/helper';

class HighlightsStore {
    constructor() {
        extendObservable(this, {
            highlightsData: [],
            selectedSport: "",
            showErrorMsg: false,
            showLoader: false,
            sports: [],
            selections: [],
            appStore: {},
            itemsPerClick: 20,
            itemsShow: 20,
            initialItemShow: 20,
            pageId: "",
            isInitialLoad: true,
            mounted: true,
        })
    }

    /**
      * This method is used to get the list of sports in highlights.
      *
      */
    getHighlightsSports() {
        apiCaller(upcomingHighlights.getHighlightsSports + "?pageId=" + this.pageId, 'GET').then(response => {

            if (response?.data?.sportDetailsList?.length > 0) {
                this.sports = response.data.sportDetailsList;
                if (!this.selectedSport) {
                    this.selectedSport = this.sports[0];
                    this.getHighlightsEventData();
                }
            }
        });
    }

    selectSport(sport) {
        if (sport && sport.hasOwnProperty('name')) {
            this.selectedSport = sport;
            this.getHighlightsEventData();
        }
    }

    /**
    * This method is used to get the highlights event data.
    *
    */
    getHighlightsEventData() {
        if (this.selectedSport.id !== null && this.selectedSport.id > 0) {
            this.showErrorMsg = false;
            this.showLoader = true;
           // this.highlightsData = [];
            var token = "";
            var cookeTocken = cookie.load('UTocken');
            if (cookeTocken && cookeTocken.length > 0) {
                token = cookeTocken;
            }
            apiCaller(upcomingHighlights.getHighlightsEvents + "?sportId=" + this.selectedSport.id + "&userToken=" + token + "&pageId=" + this.pageId, 'GET').then(response => {
                this.showLoader = false;
                if (response?.data?.events?.length > 0) {
                    this.highlightsData = response.data.events;
                    this.selections = selectSelection(this.highlightsData[0]);
                } else {
                    this.showErrorMsg = true;
                    this.highlightsData = [];
                }
            });
        } else {
            this.showErrorMsg = true;
            this.highlightsData = [];
        }
    }

    /**
     * This method is used to pin the event
     * @param {eventId} - (number) - contains the event id to pin.
     */
    savePin(eventId, isPinned) {
        this.appStore.saveEventPin(eventId, isPinned, (success) => {
           // console.log("success===", success);
        })
    }
    /**
    * This method is used to show more events
    */
    showMore() {
        this.itemsShow += this.itemsPerClick;
    }
    /**
    * This method is used to show less events
    */
    showLess() {
        this.itemsShow = this.initialItemShow;
    }
    resetData() {
        this.selectedSport= "";
        this.sports= [];
       this.highlightsData= [];
        this.showErrorMsg= false;
        this.showLoader= false;
        this.selections= [];
        this.appStore= {};
        this.itemsPerClick= 20;
        this.itemsShow= 20;
        this.initialItemShow=20;
        this.pageId= "";
        this.isInitialLoad= true;
      }
}

export default HighlightsStore;