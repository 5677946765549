import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import Banner from "../../sharedComponents/banner/Banner";
import BannerStore from "../../sharedComponents/banner/BannerStore";

import SportsPageComponent from "./sportspagecomponent/sportsPageComponent";
import SportsPageComponentStore from "./sportspagecomponent/sportsPageComponentStore";

const bannerStore = new BannerStore();
const sportsPageComponentStore = new SportsPageComponentStore();
class Sportspage extends Component {

  constructor(props) {
    super(props);
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, isLoggedIn } = this.props;
    return (
      <div>
        <Route render={(props)=><Banner bannerStore={bannerStore} appStore={appStore} pageId={1}  isLoggedIn={isLoggedIn}/>}/>
        <Route render={(props)=><SportsPageComponent {...props} sportId={appStore.sportId} 
                                                                    sportName={appStore.sportName} 
                                                                    sportsPageComponentStore={sportsPageComponentStore} 
                                                                    appStore={appStore}/>}/>            
      </div>
    );
  }
}

export default observer(Sportspage);
