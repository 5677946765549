import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import cookie from 'react-cookies';

import MyProfile from './myprofile/myProfile';
import MyProfileStore from './myprofile/myProfileStore';
import ChangePassword from './changepassword/changePassword';
import ChangePasswordStore from './changepassword/changePasswordStore';
import BalanceOverview from './balanceoverview/balanceOverview';
import BalanceOverviewStore from './balanceoverview/balanceOverviewStore';
import AccountStatement from './accountstatement/accountStatement';
import AccountStatementStore from './accountstatement/accountStatementStore';
import MyBets from './mybets/myBets';
import MyBetsStore from './mybets/myBetsStore';

const myProfileStore = new MyProfileStore();
const changePasswordStore = new ChangePasswordStore();
const balanceOverviewStore = new BalanceOverviewStore();
const accountStatementStore = new AccountStatementStore();
const myBetsStore = new MyBetsStore();

class Account extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    componentWillMount() {
        this.props.appStore.currentPath = 'profile';
        if (!this.props.appStore.isLoggedIn || cookie.load('UTocken') === null) {
            window.location.href = '/';
        }
    }
    componentWillReceiveProps(nextProps) { }
    goBack() {
        this.props.history.goBack();
        // this.props.history.go(-1);
        // this.props.history.push(this.props.history.go(-1));
    }
    render() {
        const { appStore } = this.props
        return (
            <div>
                {/* <div className="RDE-dashboard-container"> */}
                <div className="RDE-dashboard-header">
                    <div className="grid-middle-noGutter">
                        <div className="col-12 text-center">
                            <div className="RDE-backBtn" onClick={this.goBack}>
                                <i className="RDE-icon-go-back"></i>
                            </div>
                            <div className="RDE-dashboard-header__title truncate">
                                {window.location.pathname.match('/myaccount/myprofile') ? "My Profile" :
                                    window.location.pathname.match('/myaccount/changepassword') ? "Change Password" :
                                        window.location.pathname.match('/myaccount/balance') ? "Balance Overview" :
                                            window.location.pathname.match('/myaccount/account') ? "Account Statement" :
                                                window.location.pathname.match('/myaccount/mybets') ? "My Bets" : ""}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="RDE-dashboard-content">
                    <div className="RDE-dashboard-mainContent">
                        <Switch>
                            <Route exact path="/myaccount/myprofile" render={(props) => <MyProfile {...props}
                                appStore={appStore}
                                myProfileStore={myProfileStore} />} />
                            <Route exact path="/myaccount/changepassword" render={(props) => <ChangePassword {...props}
                                appStore={appStore}
                                changePasswordStore={changePasswordStore} />} />
                            <Route exact path="/myaccount/balance" render={(props) => <BalanceOverview {...props}
                                appStore={appStore}
                                balanceOverviewStore={balanceOverviewStore} />} />
                            <Route exact path="/myaccount/account" render={(props) => <AccountStatement {...props}
                                appStore={appStore}
                                accountStatementStore={accountStatementStore} />} />
                            <Route path="/myaccount/mybets" render={(props) => <MyBets {...props}
                                appStore={appStore}
                                myBetsStore={myBetsStore} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(Account);
