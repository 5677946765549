import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { masterPage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from "../../sharedFiles/apiStore";

const re = /^[0-9\b]+$/;
class OneClickBetStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      mainStakes:[],
      showConfirmation:true,
      isEdit:false,
      tockenValue: 500,
      selectedStake:{},
    });
  }
  /**
  *	This method is used to hide the one click bet confirmation. 
  */
  clickConfirmation(){
  	this.showConfirmation = false;
  	cookie.save("oneConfirm",false);
  }
  /**
  *	this method is used to show one click in edit mode
  */
  clickEdit(){
  	this.isEdit = true;
  }
  toggleOneClick(){
    this.showOneClick = !this.showOneClick;
    cookie.save("showOneClick",this.showOneClick);
  }
  /**
  * This method is called when user enters some thing in the edit mode of coins.
  * @param {coinId} - number - contains the coin Id to change the value.
  * @param {e} - JS object - contains the changed fild instans to get the value.
  */
  changeValue(coinId,e){
    if((re.test(e.target.value) && e.target.value >= 1 && e.target.value <= 100000)|| e.target.value===""){
      for(var coin of this.stakes){
        if(coin.coinId===coinId){
          coin.coinValue= e.target.value!==""?parseInt(e.target.value):'';
        }
      }
    }
  }
  /**
  * This method is used to update the one click coin values
  *
  */
  updateCoins(){
    var validCoins = true;
    for(var coin of this.stakes){
        if(coin.coinValue === "" || coin.coinValue === null || coin.coinValue < 1){
          validCoins = false;
        }
    }
    if(validCoins){
      var params = {
        "userToken": cookie.load("UTocken"),
        "userCoinsDetailsList":this.stakes
      }
      apiCaller(masterPage.updateOneClick,'POST',params).then(response => {
        if(response.success){
            this.isEdit=false;
            this.getOneClickValues();
        }
      });
    }else{
        this.isEdit = false;
        // console.log("main stakes==",this.mainStakes);
        // var tempStake = [];
        // for(var i =0; i<this.mainStakes.length;i++){
        //   tempStake.push(this.mainStakes[i]);
        // }
        // this.stakes = tempStake.slice();
        this.getOneClickValues();
    }
    
   
  }
  /**
  * This method is used to get the one click stack values.
  * +cookie.load("UTocken")
  */
  getOneClickValues(){
    apiCaller(masterPage.getOneClick+"?userToken="+cookie.load("UTocken"),'GET').then(response => {
      if(response.data && response.data.hasOwnProperty('oneClickCoinDetailsList') &&
         response.data.oneClickCoinDetailsList && response.data.oneClickCoinDetailsList.length>0){
         this.stakes = response.data.oneClickCoinDetailsList;
         this.mainStakes = response.data.oneClickCoinDetailsList;
         // this.stakes =this.mainStakes.slice();
          if(cookie.load("selectedStake")){
            this.selectedStake = cookie.load("selectedStake");
            var isFound=false;
            for(var stake of this.mainStakes){
              if(stake.coinId===this.selectedStake.coinId && stake.coinValue !== this.selectedStake.coinValue){
                this.selectedStake.coinValue = stake.coinValue;
                cookie.save("selectedStake",JSON.stringify(this.selectedStake));
              }
            }

          }else{
            this.selectedStake = this.stakes[0];
            cookie.save("selectedStake",JSON.stringify(this.selectedStake));
          }
      }
    });
  }
  stakeSelected(stake){
  //  console.log("stack select")
    if(stake.coinId && stake.coinId!=="" && stake.coinValue && stake.coinValue!==""){
      this.selectedStake = stake;
      cookie.save("selectedStake",JSON.stringify(stake));
    }
  }
}

export default OneClickBetStore;
