import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import Banner from "../../sharedComponents/banner/Banner";
import BannerStore from "../../sharedComponents/banner/BannerStore";
// import HighlightsUpcoming from "./highlightsupcoming/HighlightsUpcoming";
// import HighlightsUpcomingStore from "./highlightsupcoming/HighlightsUpcomingStore";

import HighlightsUpcoming from "./highlightsupcoming/highlightsUpcoming";
import HighlightsUpcomingStore from './highlightsupcoming/highlightsUpcomingStore';
const bannerStore = new BannerStore();
const highlightsUpcomingStore = new HighlightsUpcomingStore();

class HomepageLayout extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.appStore.currentPath = "home";
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, isLoggedIn } = this.props;
    return (
      <div>
        {/* <Banner bannerStore={bannerStore} appStore={appStore} pageId={1} /> */}
        <Route render={(props) => <Banner {...props} bannerStore={bannerStore} appStore={appStore} pageId={1} isLoggedIn={appStore.isLoggedIn} />} />
        <HighlightsUpcoming appStore={appStore} highlightsUpcomingStore={highlightsUpcomingStore} isLoggedIn={appStore.isLoggedIn}
          betSlipStatusChange={appStore.betSlipStatusChange} />
      </div>
    );
  }
}

export default observer(HomepageLayout);
