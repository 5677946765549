import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { extendObservable } from "mobx";
import { masterPage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

import EventViewOddsComponent from '../eventviewoddscomponent/eventViewOddsComponent';
import EventViewOddsComponentStore from '../eventviewoddscomponent/eventViewOddsComponentStore';

import { Collapse } from 'react-bootstrap';
import BetSlipComponent from '../betslipcomponent/BetSlipComponent';

const eventViewOddsComponentStore = new EventViewOddsComponentStore();
class Popular extends Component {
  constructor(props) {
    super(props)
    this.setOutcomeAccordionIndex = this.setOutcomeAccordionIndex.bind(this);
    extendObservable(this, {
      isMounted: true,
      showOdds: false
    });
  }
  componentWillMount() {
    // this.props.popularStore.appStore = this.props.appStore;
    this.props.popularStore.appStore = this.props.appStore;
    this.props.popularStore.component = this.props.component;
    this.props.popularStore.parentStore = this.props.parentStore;

    var self = this;
    setTimeout(function reCallService() {
      if (self.isMounted) {
        self.getOdds();
        setTimeout(reCallService, 1000);
      }
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {

  }
  setOutcomeAccordionIndex(id, isAdd) {
    this.props.appStore.selectedOutcomeUniqueId = 0;
    if (isAdd) {
      this.props.appStore.selectedOutcomeUniqueId = id
    }
  }
  getOdds() {
    //+this.props.data['marktInd']['clMarketId']
    var self = this;
    for (var marktInd = 0; marktInd < this.props.data.length; marktInd++) {
      self.checkUpdatedOdds(marktInd, self);
    }


  }
  checkUpdatedOdds(marktInd, self) {
    //var self =this;
    apiCaller(masterPage.getUpdatedOdds + "?marketId=" + self.props.data[marktInd]['clMarketId'], 'GET').then(response => {
      //console.log("update test popular==="+marktInd)
      if (response && response.data) {
        var market = response.data;
        this.props.appStore.betSlipStore.updateBetSlipOdds(response.data);
        if (self.props.data[marktInd] && market.marketId === self.props.data[marktInd]['clMarketId']) {
          //console.log("value=====", market.totalMatched);
          self.props.data[marktInd]["matchedValue"] = market.totalMatched;
          if (market.status.toLowerCase() === 'suspended') {
            self.props.data[marktInd]["marketSuspended"] = 1;
          } else {
            self.props.data[marktInd]["marketSuspended"] = 0;
          }
          self.props.data[marktInd]["showOdds"] = true;
          for (var outcome of market.runners) {
            for (var i = 0; i < self.props.data[marktInd].outcomes.length; i++) {
              if (outcome.selectionId === Number(self.props.data[marktInd].outcomes[i]['clOutcomeId'])) {
                var backOddsIndex = 0;
                for (var backSelection of outcome.ex.availableToBack) {
                  if (backOddsIndex < 1) {
                    self.props.data[marktInd].outcomes[i]['backOdds'][backOddsIndex]['odds'] = backSelection.price
                    self.props.data[marktInd].outcomes[i]['backOdds'][backOddsIndex]['size'] = Math.round(backSelection.size);
                  }
                  backOddsIndex++;
                }
                if (outcome.ex.availableToBack.length === 0) {
                  self.props.data[marktInd].outcomes[i]['backOdds'][backOddsIndex]['odds'] = '';
                  self.props.data[marktInd].outcomes[i]['backOdds'][backOddsIndex]['size'] = '';
                }
                var layOddsIndex = 0
                for (var laySelection of outcome.ex.availableToLay) {
                  if (layOddsIndex < 1) {
                    self.props.data[marktInd].outcomes[i]['layOdds'][layOddsIndex]['odds'] = laySelection.price
                    self.props.data[marktInd].outcomes[i]['layOdds'][layOddsIndex]['size'] = Math.round(laySelection.size);
                  }
                  layOddsIndex++;
                }
                if (outcome.ex.availableToLay.length === 0) {
                  self.props.data[marktInd].outcomes[i]['layOdds'][layOddsIndex]['odds'] = ''
                  self.props.data[marktInd].outcomes[i]['layOdds'][layOddsIndex]['size'] = ''
                }
              }
            }

          }
        }

      }
    });
  }
  render() {
    const { data,
      eventSuspended,
      appStore,
      eventName,
      eventId,
      popularStore,
      parentStore,
      homeTeam,
      awayTeam,
      isLive } = this.props;
    return (
      <div className="RDE-matchBox-group">
        {
          data.map((market, index) => {
            var profitLoss = parentStore.getMarketPL(market.marketGroupId, market.providerId, market.subMarket ? market.subMarket : null);
            // console.log("profitLoss=== ",profitLoss);
            return <div className="RDE-matchBox" key={"eventview_popular_" + index}>
              <div className="RDE-matchBox__header">
                <div className={"RDE-pin RDE-skew " + (market.isPinned ? " active" : "")} onClick={popularStore.savePin.bind(popularStore, eventId, market.marketGroupId, market.subMarket, index, market.providerId, market.isPinned, this.props)}><span className="RDE-skewTxt"><i className="RDE-icon-pin"></i></span></div>
                <div className="RDE-matchBox__marketname">{market.marketName}</div>
              </div>
              <div className="RDE-matchBox__content">
                <div className="RDE-matchBox__backAndLayHeader RDE-matchBox__outcomeItem">
                  <div className="RDE-matchBox__outcomeItem-left">
                    <span>Matched: {market.currency} {market.matchedValue}</span>
                  </div>
                  <div className="RDE-matchBox__outcomeItem-right">
                    <ul className="RDE-matchBox__backAndLayList">
                      <li>
                        Back
                      </li>
                      <li>
                        Lay
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="RDE-matchBox__outcomeList">
                  {
                    market.outcomes.map((outcome, OIndex) => {
                      console.log(appStore.selectedOutcomeUniqueId,"uniqueId", eventId+"_"+market.marketGroupId+"_"+outcome.id)
                      //console.log("popularPL===",profitLoss, " == ",outcome)
                      return <li key={'market_outcomes_' + OIndex}>
                        <div className="RDE-matchBox__outcomeItem">
                          <div className="RDE-matchBox__outcomeItem-left">
                            <i className="RDE-icon-stats"></i>
                            <span>{outcome.name}</span>
                            {homeTeam === outcome.name || outcome.name.toLowerCase().includes('yes') || (outcome.name.toLowerCase().includes('under') && market.subMarket === profitLoss.subMarket) ? <div className="RDE-singleLineOutcomeItem__result">{profitLoss.homeTeamProfitLoss ? <div className={profitLoss.homeTeamProfitLoss <= 0 ? 'RDE-singleLineOutcomeItem__result loss' : 'RDE-singleLineOutcomeItem__result profit'}>{profitLoss && typeof (profitLoss.homeTeamProfitLoss === "number") && Math.abs(profitLoss.homeTeamProfitLoss).toFixed(2)}</div> : ""}
                              {(typeof (profitLoss.homeTeamProfitLoss) === "number" && typeof (profitLoss.homeProfitLoss) === "number") ? <i className="RDE-icon-right-arrow"></i> : ''}
                              <div className={profitLoss.homeProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && typeof (profitLoss.homeProfitLoss) === "number" && Math.abs(profitLoss.homeProfitLoss).toFixed(2)}</div>
                            </div> :
                              awayTeam === outcome.name || outcome.name.toLowerCase().includes('no') || (outcome.name.toLowerCase().includes('over') && market.subMarket === profitLoss.subMarket) ?
                                <div className="RDE-singleLineOutcomeItem__result">
                                  {profitLoss.awayTeamProfitLoss ?
                                    <div className={profitLoss.awayTeamProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && typeof (profitLoss.awayTeamProfitLoss) === "number" && Math.abs(profitLoss.awayTeamProfitLoss).toFixed(2)}</div> :
                                    ""}
                                  {(typeof (profitLoss.awayTeamProfitLoss) === "number" && typeof (profitLoss.awayProfitLoss) === "number") ? <i className="RDE-icon-right-arrow"></i> : ''}
                                  <div className={profitLoss.awayProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && typeof (profitLoss.awayProfitLoss) === "number" && Math.abs(profitLoss.awayProfitLoss).toFixed(2)}</div>
                                </div> :
                                outcome.name.toLowerCase().includes('draw') ?
                                  <div className="RDE-singleLineOutcomeItem__result">{profitLoss.drawTeamProfitLoss ?
                                    <div className={profitLoss.drawTeamProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && typeof (profitLoss.drawTeamProfitLoss) === "number" && Math.abs(profitLoss.drawTeamProfitLoss).toFixed(2)}</div> :
                                    ''}
                                    {(typeof (profitLoss.drawTeamProfitLoss) === "number" && typeof (profitLoss.drawProfitLoss) === "number") ? <i className="RDE-icon-right-arrow"></i> : ''}
                                    <div className={profitLoss.drawProfitLoss <= 0 ? 'loss' : 'profit'}>{profitLoss && profitLoss.drawProfitLoss && Math.abs(profitLoss.drawProfitLoss).toFixed(2)}</div>
                                  </div> : ""}
                          </div>
                          <div className="RDE-matchBox__outcomeItem-right">
                            <ul className="RDE-matchBox__backAndLayList">
                              <li>
                                {!eventSuspended && !market.marketSuspended ?
                                  outcome.backOdds.map((odds, OddsIndex) => {
                                    return <EventViewOddsComponent
                                      key={'eventview_backodds_' + OddsIndex}
                                      eventSuspended={eventSuspended}
                                      marketSuspended={market.marketSuspended}
                                      marketBlocked={market.marketBlocked}
                                      appStore={appStore}
                                      betSlipStatusChange={appStore.betSlipStatusChange}
                                      outcome={odds}
                                      outcomes={outcome}
                                      eventViewOddsComponentStore={eventViewOddsComponentStore}
                                      eventName={eventName}
                                      eventId={eventId}
                                      marketName={market.marketName}
                                      marketId={market.marketGroupId}
                                      clMarketId={market.clMarketId}
                                      providerId={market.providerId}
                                      isLive={isLive}
                                      oddsType='BO'
                                      //    betSlipStatusChange={appStore.betSlipStatusChange}
                                      subMarket={market.subMarket}
                                      showOdds={market.showOdds}
                                      component='popular'
                                      setOutcomeAccordionIndex={this.setOutcomeAccordionIndex} />
                                  })
                                  : <div className="RDE-btnOddsSuspended">
                                    <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button>
                                  </div>}
                              </li>
                              <li>
                                {!eventSuspended && !market.marketSuspended ?
                                  outcome.layOdds.map((odds, OddsIndex) => {
                                    return <EventViewOddsComponent
                                      key={'eventview_layOdds_' + OddsIndex}
                                      eventSuspended={eventSuspended}
                                      marketSuspended={market.marketSuspended}
                                      marketBlocked={market.marketBlocked}
                                      appStore={appStore}
                                      outcome={odds}
                                      outcomes={outcome}
                                      eventViewOddsComponentStore={eventViewOddsComponentStore}
                                      eventName={eventName}
                                      eventId={eventId}
                                      clMarketId={market.clMarketId}
                                      providerId={market.providerId}
                                      isLive={isLive}
                                      marketName={market.marketName}
                                      marketId={market.marketGroupId}
                                      oddsType='LO'
                                      betSlipStatusChange={appStore.betSlipStatusChange}
                                      subMarket={market.subMarket}
                                      showOdds={market.showOdds}
                                      component='popular'
                                      setOutcomeAccordionIndex={this.setOutcomeAccordionIndex} />
                                  })
                                  : <div className="">
                                    {/* <button className="btn RDE-btnOdds"><i className="RDE-icon-lock"></i> Suspended</button> */}
                                  </div>}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <Collapse in={appStore.selectedOutcomeUniqueId === eventId + "_" + market.marketGroupId + "_" + outcome.id}>
                          <div>
                            <BetSlipComponent betslipComponentStore={appStore.betSlipStore} appStore={appStore}
                              coinValueChange={appStore.coinValuesChanged} />
                          </div>
                        </Collapse>

                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
          })
        }
      </div>
    )
  }
}
export default observer(Popular);
