import { extendObservable } from 'mobx';
// import Validator from 'validatorjs';
// import cookie from 'react-cookies';
// import createBrowserHistory from 'history/createBrowserHistory'

// import { upcomingHighlights } from "../../sharedFiles/EndpointConfig";
// import { apiCaller } from '../../sharedFiles/apiStore';
// import { selectSelection } from '../../sharedFiles/helper';
// import { getTimeZoneOffset } from '../../sharedFiles/helper';

class EventComponentStore {
    constructor() {
        extendObservable(this, {
            accordionOpenIndex:[],
            appStore: {},
            //outcomeAccordionIndex:0
        })
     }
    
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }

  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, isPinned, eventProps){
    this.appStore.saveEventPin(eventId, '','','',isPinned, (success)=>{
        eventProps.event.isPinned = !eventProps.event.isPinned;
      })
  }

 }

export default EventComponentStore;