import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";
import ImageUrls from '../../../sharedFiles/imageUrls';

import EventComponent from '../../../sharedComponents/eventcomponent/eventComponent';
import EventComponentStore from '../../../sharedComponents/eventcomponent/eventComponentStore';
import { liveMatchRefreshTime } from '../../../sharedFiles/helper';

const eventComponentStore = new EventComponentStore();

class InplayHighlights extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.inplayhighlightsStore.pageId = this.props.pageId;
        this.props.inplayhighlightsStore.selectedSport = "";
        this.props.inplayhighlightsStore.getHighlightsSports();
        this.props.inplayhighlightsStore.getHighlightsEventData();
        this.props.inplayhighlightsStore.mounted = true;
    }
    componentDidMount() {
        var self = this;
        setTimeout(function reCallService() {
            if (self.props.inplayhighlightsStore.mounted) {
                self.props.inplayhighlightsStore.getHighlightsSports();
                setTimeout(reCallService, liveMatchRefreshTime);
            }
        }, liveMatchRefreshTime);
    }

    componentWillUnmount() {
    }

    onInitialFetch(groups) { }

    componentWillReceiveProps(nextProps) {
        if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
            this.props.inplayhighlightsStore.getHighlightsSports();

        }
    }

    render() {
        const { appStore, inplayhighlightsStore, betSlipStatusChange, componentType } = this.props;
        return (
            <div className="RDE-inplay">
                <div className="RDE-accordion-group">
                    {/* {highlightsStore.sports} */}
                    <div className="RDE-accordion">
                        {/* <div className="RDE-singleLineEventHeader">
                            <div className="RDE-singleLineEventHeader__left">
                                <div className="RDE-singleLineEventHeader-title">
                                    {inplayhighlightsStore.selectedSport.name}
								</div>
                            </div>
                        </div> */}
                        <div className="RDE-eventItemGroup">
                            <div className="RDE-accordion-group">
                                {
                                    inplayhighlightsStore.highlightsData.map(function (event, eventIndex) {
                                        return (<Route key={"inplay_sport_" + eventIndex} render={(props) => <EventComponent {...props} key={"inplay_sport_" + eventIndex} appStore={appStore} event={event} eventIndex={eventIndex} inplayhighlightsStore={inplayhighlightsStore}
                                            eventComponentStore={eventComponentStore}
                                            componentType={componentType}
                                            sportId={inplayhighlightsStore.selectedSport.id}
                                            sportName={inplayhighlightsStore.selectedSport.name}
                                            betSlipStatusChange={betSlipStatusChange} />} />)
                                    })
                                }
                            </div>
                        </div>
                        {inplayhighlightsStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
                            Currently data is not available for this Sport
                        </div> : ''}
                        {inplayhighlightsStore.showLoader ? <div className="RDE-loader">
                            <img src={ImageUrls.loaderIcon} alt="loading...!" />
                        </div> : ''}
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(InplayHighlights);
