import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import LeaguePageComponent from './leaguepagecomponent/leaguePageComponent';
import LeaguePageComponentStore from './leaguepagecomponent/leaguePageComponentStore';

const leaguePageComponentStore = new LeaguePageComponentStore();
class Leaguepage extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount(){
    
  }
  componentDidMount() {
  
  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) {}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const {appStore, isLoggedIn} = this.props;
    return (   
        // <div className="RDE-mainContent">   
          <Route render={(props)=><LeaguePageComponent {...props} leaguePageComponentStore={leaguePageComponentStore} 
                                                                  appStore={appStore}
                                                                  sportId={appStore.sportId}
                                                                  sportName={appStore.sportName}/>}/>
        // </div>
    );
  }
}

export default observer(Leaguepage);
