import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { masterPage } from "../../sharedFiles/EndpointConfig";
import { apiCaller } from '../../sharedFiles/apiStore';

//const re = /[0-9\b]+$/;
const re = /^\d+\.?\d{0,2}$/;
class BetSlipComponentStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showOneClick: false,
      appStore: {},
      showSettings: false,
      settingsStore: {},
      betSlipItems: { backBets: [], layBets: [] },
      stakeCoins: [],
      totalStake: "0.00",
      totalReturns: "0.00",
      showConfirm: false,
      confirmBets: false,
      betSlipError: "",
      isValidBets: false,
      acceptOddsChange: false,
      showConfirmedBets: false,
      showLoader: false,
      betsSaved: false,
      countDownText: "",
      countDown: 5,
      isStakeEdited: true,
      isOddsEdited: true,
      isBetUpdating: false
    });
  }
  /**
  * This method is used to add a item to betslip.
  * @param {Object} selection - cointains the event information to add to betslip
  */
  addBetSlipItem(selection) {
    var isAdded = false;
    var defaultStake = localStorage.getItem('defaultStake');
    let stake = selection.userStake && !!selection.userStake ? selection.userStake : defaultStake ? defaultStake : 1000;
    if (selection && selection.hasOwnProperty('betType')) {
      selection.userOdds = selection.odds;
      selection.userStake = stake;
      selection.returns = stake ? selection.isFancy ? this.calculateFancyReturns(selection) : (stake * selection.odds).toFixed(2) : "0.00"
      if (selection.betType === 'BO') {
        var betIndex = this.findBetIndex(this.betSlipItems.backBets, selection);
        if (betIndex >= 0) {
          if (this.betSlipItems.backBets[betIndex].odds !== selection.odds) {
            this.betSlipItems.backBets[betIndex] = selection;
            isAdded = true;
          } else {
            this.appStore.removePL(this.betSlipItems.backBets[betIndex]);
            this.betSlipItems.backBets.splice(betIndex, 1);
            isAdded = false;
          }

        } else {
          /** Always only one bet needs to display in betslip i.e. recently added bet. */
          if (this.betSlipItems.backBets.length > 0) {
            this.appStore.removePL(this.betSlipItems.backBets[0]);
          } else if (this.betSlipItems.layBets.length > 0) {
            this.appStore.removePL(this.betSlipItems.layBets[0]);
          }

          this.betSlipItems = { backBets: [], layBets: [] };
          this.betSlipItems.backBets.push(selection);
          isAdded = true;
        }
      } else {
        if (selection.betType === 'LO') {

          var betIndex = this.findBetIndex(this.betSlipItems.layBets, selection)
          if (betIndex >= 0) {
            if (this.betSlipItems.layBets[betIndex].odds !== selection.odds) {
              this.betSlipItems.layBets[betIndex] = selection;
              isAdded = true;
            } else {
              this.appStore.removePL(this.betSlipItems.layBets[betIndex]);
              this.betSlipItems.layBets.splice(betIndex, 1);
              isAdded = false;
            }
          } else {
            /** Always only one bet needs to display in betslip i.e. recently added bet. */
            this.betSlipItems = { backBets: [], layBets: [] };
            this.betSlipItems.layBets.push(selection);
            isAdded = true;
          }
        }
      }
    }
    this.validateBets();
    this.calStakeReturns();
    this.appStore.calProfitLoss();
    return isAdded;
  }
  /**
  *
  *
  */
  findBetIndex(bets, selection) {
    var index = 0;
    var betIndex = -1;
    for (var bet of bets) {
      if (bet.eventId === selection.eventId &&
        bet.marketId === selection.marketId &&
        bet.selectionId === selection.selectionId) {
        betIndex = index;
        break;
      }
      index++;
    }
    return betIndex;
  }
  /**
  * This method is used to calculate the total stake and returns.
  *
  */
  calStakeReturns() {
    var tStake = "0.00";
    var tReturns = "0.00"
    if (this.betSlipItems.hasOwnProperty('backBets') && this.betSlipItems.backBets.length > 0) {
      for (var bet of this.betSlipItems.backBets) {
        tStake = (Number(tStake) + Number(bet.userStake)).toFixed(2);
        tReturns = (Number(tReturns) + Number(bet.returns)).toFixed(2);
      }
      if (!isNaN(tStake)) {
        this.totalStake = tStake;
      }
      if (!isNaN(tReturns)) {
        this.totalReturns = tReturns;
      }
    }
    if (this.betSlipItems.hasOwnProperty('layBets') && this.betSlipItems.layBets.length > 0) {
      for (var bet of this.betSlipItems.layBets) {
        tStake = (Number(tStake) + Number(bet.userStake)).toFixed(2);
        tReturns = (Number(tReturns) + Number(bet.returns)).toFixed(2);
      }
      if (!isNaN(tStake)) {
        this.totalStake = tStake;
      }
      if (!isNaN(tReturns)) {
        this.totalReturns = tReturns;
      }
    }
    this.validateBets();
  }
  /**
  * This method is used to remove the bet from the betslip.
  * @param {number} betIndex- contains the bet index to remove.
  * @param {string} betType- contains the bet type string, 'BO' for back bet and 'LO' for lay bet. 
  */
  removeBet(betIndex, betType) {
    if (betIndex !== '' && betIndex >= 0 && (betType === 'BO' || betType === 'LO')) {
      var removedBet = {};
      var betSlipBets = [];
      if (betType === "BO") {
        betSlipBets = this.betSlipItems.backBets;
      } else if (betType === "LO") {
        betSlipBets = this.betSlipItems.layBets;
      }
      if (betSlipBets.length > 0 && betIndex < betSlipBets.length) {
        removedBet = betSlipBets.splice(betIndex, 1);
        if (removedBet && removedBet.length > 0) {
          this.removeBetLocal(removedBet[0], betType);
          this.appStore.removePL(removedBet[0]);
        }
      }
      if (betType === "BO") {
        this.betSlipItems.backBets = betSlipBets;
      } else if (betType === "LO") {
        this.betSlipItems.layBets = betSlipBets;
      }
    }
    this.validateBets();
    this.calStakeReturns();
    this.appStore.calProfitLoss();
  }
  /**
  * This method is used to remove the bet from local storage.
  * @param {Object} removeBet- contains bet information to remove.
  * @param {string} betType- contains the bet type string, 'BO' for back bet and 'LO' for lay bet 
  */
  removeBetLocal(removeBet, betType) {
    // console.log("removeBet==",removeBet,"==betType==",betType)
    if (localStorage && removeBet) {
      var localBets = JSON.parse(localStorage.getItem('betslip'));
      if (betType === 'BO' && localBets.hasOwnProperty('backBets')) {
        var newBackBets = this.filterLocalBets(localBets.backBets, removeBet);
        if (newBackBets) {
          localBets.backBets = newBackBets;
          localStorage.setItem('betslip', JSON.stringify(localBets));
        }
      }
      if (betType === 'LO' && localBets.hasOwnProperty('layBets')) {
        var newLayBets = this.filterLocalBets(localBets.layBets, removeBet);
        if (newLayBets) {
          localBets.layBets = newLayBets;
          localStorage.setItem('betslip', JSON.stringify(localBets));
        }
      }
      this.appStore.updateBetSlipStatus();
    }
  }
  /**
  * This method is used to filter the removed item from local bets
  * @param {Array} bets - contains the localbets.
  * @param {Object} removeBet - contains the bet information to remove.
  */
  filterLocalBets(bets, removeBet) {
    var newBackBets = [];
    for (var bet of bets) {
      if (bet.eventId === removeBet.eventId &&
        bet.marketId === removeBet.marketId &&
        bet.selectionId === removeBet.selectionId) {

      } else {
        newBackBets.push(bet)
      }
    }
    return newBackBets;
  }
  /**
  * This method is used to check and uncheck the confirm betslip
  *
  */
  confirmBetSlip(event) {
    this.showConfirm = event.target.checked;
  }
  /**
  * This method is used to place the bet.
  * if user selects the show confirm then it will open confirmation window.
  */
  placeBet(appStore) {
    if (!this.appStore.isLoggedIn) {
      this.appStore.showLogin()
    } else //if (!this.confirmBets) {

    {
      this.validateBets();
      if (this.isValidBets) {
        if (this.showConfirm) {
          this.confirmBets = true;
        } else {
          this.saveBets(appStore);
        }
      }
    }
    // else {
    //   this.showConfirmedBets = true;
    // }
  }
  /**
  * This method is used to place the bet for one click.
  */
  placeOneClickBet(bet) {
    if (this.appStore.userInfo.userAccountBalance > this.totalStake) {
      var userStake = {};
      if (cookie.load("selectedStake")) {
        userStake = cookie.load("selectedStake");
      }
      var param = {
        "betDetails": [],
        "userToken": cookie.load('UTocken'),
        "oddsChangeAcceptanceFlag": 0,
        "totalReturns": Number((userStake.coinValue * bet.odds).toFixed(2)),
        "totalStake": userStake.coinValue
      }
      var obj = {
        "eventId": bet.eventId,
        "outcomeId": bet.selectionId,
        "marketId": bet.marketId,
        "odds": bet.odds,
        "oddType": bet.betType === 'BO' ? 'B' : 'L',
        "homeTeamId": bet.homeTeamId,
        "awayTeamId": bet.awayTeamId,
        "stakeAmount": userStake.coinValue,
        "returns": Number((userStake.coinValue * bet.odds).toFixed(2)),
        "oddsManualChangeFlag": 0,
        "isLive": bet.isLive,
        "clientMarketId": bet.clMarketId,
        "selectionId": bet.clOutcomeId,
        "providerId": bet.providerId,
        "originalOdds": bet.odds
      }
      param.betDetails.push(obj);
      this.callSaveBet(param)
    } else {
      this.appStore.showMessage("Insufficient funds in your account", "error");
    }
  }
  /**
  * this method is used to save the bets.
  */
  saveBets(appStore) {
    //cookie.load('UTocken')
    this.betsSaved = false;
    this.showLoader = true;
    var isBetFair = false;

    // if (this.appStore.userInfo.userAccountBalance >= this.totalStake) {
    if (this.isValidBets) {
      var param = {
        "betDetails": [],
        "userToken": cookie.load('UTocken'),
        "oddsChangeAcceptanceFlag": this.acceptOddsChange ? 1 : 0,
        "totalReturns": Number(this.totalReturns),
        "totalStake": Number(this.totalStake)
      }
      for (var bet of this.betSlipItems.backBets) {
        isBetFair = bet.isBetFair;
        var obj = {
          "eventId": bet.eventId,
          "outcomeId": bet.selectionId,
          "marketId": bet.marketId,
          "odds": bet.userOdds,
          "oddType": 'B',
          "stakeAmount": bet.userStake,
          "returns": bet.returns,
          "oddsManualChangeFlag": bet.userOdds === bet.odds ? 0 : 1,
          "isLive": bet.isLive,
          "clientMarketId": bet.clMarketId,
          "selectionId": bet.clOutcomeId,
          "providerId": bet.providerId,
          "originalOdds": bet.odds
        }
        param.betDetails.push(obj);
      }
      for (var bet of this.betSlipItems.layBets) {
        isBetFair = bet.isBetFair;
        var obj = {
          "eventId": bet.eventId,
          "outcomeId": bet.selectionId,
          "marketId": bet.marketId,
          "odds": bet.userOdds,
          "oddType": 'L',
          "stakeAmount": bet.userStake,
          "returns": bet.returns,
          "oddsManualChangeFlag": bet.userOdds === bet.odds ? 0 : 1,
          "isLive": bet.isLive,
          "clientMarketId": bet.clMarketId,
          "selectionId": bet.clOutcomeId,
          "providerId": bet.providerId,
          "originalOdds": bet.odds
        }
        param.betDetails.push(obj);
      }
      this.countDown = isBetFair ? 3 : 0;
      this.countDownText = isBetFair ? "Please wait for " : "Please wait...";
      var countDownTimer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          this.countDownText = "Please wait...";
        }
        if (this.countDown <= 0 && this.betsSaved) {
          this.showLoader = false;
          clearInterval(countDownTimer);
        }
      }, 1000);
      this.callSaveBet(param, appStore);
    }
    // } else {
    //   this.showLoader = false;
    //  // this.betSlipError = "Insufficient funds in your account";
    //   this.appStore.showMessage("Insufficient funds in your account", "error");
    // }
  }
  callSaveBet(param, appStore) {
    // storing the info for displaying after the place bet.
    var bet = param.betDetails[0];

    apiCaller(masterPage.saveBets, 'POST', param).then(response => {
      this.betsSaved = true;
      if (response && response.hasOwnProperty('data')) {
        if (response && response.hasOwnProperty('data') &&
          response.data &&
          response.data.hasOwnProperty('isBetPlaced') &&
          response.data.isBetPlaced) {
          //this.appStore.saveProfitLoss();
          this.appStore.getBetsProfitLoss(true);
          this.clearBetslip();

          if (response.data.hasOwnProperty('remainingBalance') && response.data.remainingBalance !== null) {
            var userInfo = cookie.load("userInfo");
            userInfo.userAccountBalance = response.data.remainingBalance;
            cookie.save("userInfo", userInfo);
            this.appStore.userInfo = userInfo;
          }
          this.appStore.selectedOutcomeUniqueId = 0;
          let placedBetInfo = {
            type: bet.oddType,
            stake: bet.stakeAmount,
            odds: response.data.betSlipResponseList[0].odds
          }


          //this.betSlipError = "bets placed successfully";
          let bettype = response.data.betSlipResponseList[0].matched ? "Matched" : "Un-Matched";
          placedBetInfo.isMatched = response.data.betSlipResponseList[0].matched;
          this.appStore.placedBetInfo = placedBetInfo
          let betslipMsg = "Your bets have been placed and " + bettype;
          this.appStore.showMessage(betslipMsg, "success");
          this.appStore.getUserBalanceDetails();
        } else if (response && response.hasOwnProperty('data') &&
          response.data &&
          response.data.hasOwnProperty('reasonForFailure') &&
          response.data.reasonForFailure.length > 0) {
          //this.betSlipError = response.data.reasonForFailure;
          this.appStore.showMessage(response.data.reasonForFailure, "error");
        } else {

          var betSlipError = "";
          //((a||{}).b||{}).c
          if (((response || {}).data || {}).betPlaced) {
            betSlipError = response.data.betSlipResponseList[0].betStatus;
          } else if (response && response.hasOwnProperty("data") && response.data &&
            response.data.hasOwnProperty("betSlipResponseList") && response.data.betSlipResponseList.length > 0) {
            betSlipError = response.data.betSlipResponseList[0].errorCode;
          } else if (response && !response.data && response.description) {
            betSlipError = response.description
          }
          else {
            betSlipError = "bet(s) not placed";
          }
          this.appStore.showMessage(betSlipError, 'error')
        }
        //  // this.betSlipError = "bet(s) not placed";
        //  this.appStore.showMessage("bet(s) not placed", "error");
        // }
      }
    });
  }
  /**
* this method is used to clear betslip.
*/
  clearBetslip() {
    var betSlipItems = {
      backBets: [],
      layBets: []
    }
    this.betSlipItems = betSlipItems;
    localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    this.acceptOddsChange = false;
    this.showConfirm = false;
    this.confirmBets = false;
    // this.showConfirmedBets = false;
    this.betSlipError = "";
    this.appStore.updateBetSlipStatus();
    this.appStore.getBetsProfitLoss(true);
    //this.appStore.calProfitLoss();
  }
  /**
  * This method is used to go back to bet betslip.
  */
  goBackToBetSlip() {
    this.confirmBets = false;
    this.showConfirmedBets = false;
  }
  /**
  * This method is used to set accept odds change.
  * @param {Object} event - contains the element value
  */
  changeAcceptOddsChange(event) {
    //   this.acceptOddsChange = event.target.checked;

  }
  /**
  * this method is used to get the bets from service using local storage information.
  */
  getBets() {
    // var param = {
    //   "backBets": [],
    //   "layBets": []
    // }
    // var defaultStake = localStorage.getItem('defaultStake');
    // if (localStorage) {
    //   var betSlipItems = JSON.parse(localStorage.getItem('betslip'));
    //   if (betSlipItems && betSlipItems.hasOwnProperty('backBets')) {
    //     for (var bet of betSlipItems.backBets) {
    //       var obj = {
    //         "eventId": bet.eventId,
    //         "marketId": bet.marketId,
    //         "outcomeId": bet.selectionId,
    //         "oddType": bet.betType === 'BO' ? 'b' : 'l'
    //       }
    //       param.backBets.push(obj)
    //     }
    //   }
    //   if (betSlipItems && betSlipItems.hasOwnProperty('layBets')) {
    //     for (var bet of betSlipItems.layBets) {
    //       var obj = {
    //         "eventId": bet.eventId,
    //         "marketId": bet.marketId,
    //         "outcomeId": bet.selectionId,
    //         "oddType": bet.betType === 'BO' ? 'b' : 'l'
    //       }
    //       param.layBets.push(obj)
    //     }
    //   }
    //   if (param.backBets.length > 0 || param.layBets.length > 0) {
    //     apiCaller(masterPage.getBets, 'POST', param).then(response => {
    //       if (response && response.hasOwnProperty('data') && response.data) {
    //         if (response.data.hasOwnProperty('backBets') && response.data.backBets) {
    //           for (var bet of response.data.backBets) {
    //             var obj = {
    //               eventId: bet.eventId,
    //               marketId: bet.marketId,
    //               selectionId: bet.outcomeId,
    //               eventName: bet.eventName,
    //               marketName: bet.marketName,
    //               odds: bet.odds,
    //               userOdds: bet.odds,
    //               userStake: defaultStake ? defaultStake : "",
    //               returns: defaultStake ? bet.isFancy ? this.calculateFancyReturns(bet) : (defaultStake * bet.odds).toFixed(2) : "0.00",
    //               selectionName: bet.outcomeName,
    //               betType: 'BO',
    //               isFancy: bet.providerId === 1 || bet.providerId === 3 ? true : false,
    //               isBetFair: bet.providerId === 2 ? true : false,
    //               providerId: bet.providerId,
    //               isLive: bet.isLive,
    //               clMarketId: bet.clientMarketId,
    //               clOutcomeId: bet.selectionId,
    //               minStake: bet.minStake,
    //               maxStake: bet.maxStake,
    //               stakeError: ""
    //             }
    //             this.betSlipItems.backBets.push(obj);
    //           }
    //         }
    //         if (response.data.hasOwnProperty('layBets') && response.data.layBets) {
    //           for (var bet of response.data.layBets) {
    //             var obj = {
    //               eventId: bet.eventId,
    //               marketId: bet.marketId,
    //               selectionId: bet.outcomeId,
    //               eventName: bet.eventName,
    //               marketName: bet.marketName,
    //               odds: bet.odds,
    //               userOdds: bet.odds,
    //               userStake: defaultStake ? defaultStake : "",
    //               selectionName: bet.outcomeName,
    //               returns: defaultStake ? bet.isFancy ? this.calculateFancyReturns(bet) : (defaultStake * bet.odds).toFixed(2) : "0.00",
    //               betType: 'LO',
    //               isFancy: bet.providerId === 1 || bet.providerId === 3 ? true : false,
    //               isBetFair: bet.providerId === 2 ? true : false,
    //               providerId: bet.providerId,
    //               isLive: bet.isLive,
    //               clMarketId: bet.clientMarketId,
    //               clOutcomeId: bet.selectionId,
    //               minStake: bet.minStake,
    //               maxStake: bet.maxStake,
    //               stakeError: ""
    //             }
    //             this.betSlipItems.layBets.push(obj);
    //           }
    //         }
    //         this.validateBets();
    //         if (response.data.hasOwnProperty('userComments') &&
    //           response.data.userComments &&
    //           response.data.userComments.length > 0) {
    //             this.appStore.showMessage( response.data.userComments, "error");
    //           //this.betSlipError = response.data.userComments;

    //           this.validateLocalBets();
    //         }
    //       } else {
    //         localStorage.setItem('betslip', JSON.stringify({ backBets: [], layBets: [] }))
    //       }
    //     });
    //   }

    // }
  }
  /**
  * This method is used to calculate the fancy bets returns
  *
  */
  calculateFancyReturns(selection) {
    var defaultStake = localStorage.getItem('defaultStake');
    if (selection.odds && selection.odds > 0 && defaultStake) {
      var returns = (Number(selection.odds) * Number(defaultStake)) / 100;
      var totalReturns = returns + Number(defaultStake);
      return totalReturns.toFixed(2);
    }
    return "0.00"
  }
  /**
  * This method is used to check bets are validate or not
  *
  */
  validateBets() {

    var validBets = true;
    if (this.betSlipItems.hasOwnProperty('backBets') &&
      this.betSlipItems.backBets.length > 0 &&
      this.betSlipItems.hasOwnProperty('layBets') &&
      this.betSlipItems.layBets.length > 0) {
      for (var backBet of this.betSlipItems.backBets) {
        for (var layBet of this.betSlipItems.layBets) {
          if (backBet.eventId === layBet.eventId &&
            backBet.marketId === layBet.marketId &&
            backBet.selectionId === layBet.selectionId &&
            backBet.providerId !== 1 &&
            backBet.providerId !== 3 &&
            layBet.providerId !== 1 &&
            layBet.providerId !== 3 &&
            backBet.userOdds < layBet.userOdds) {
            //this.betSlipError = "Your back odds should not less then your lay odds for " + backBet.eventName + " ," + backBet.marketName;
            this.appStore.showMessage("Your back odds should not less then your lay odds for " + backBet.eventName + " ," + backBet.marketName, "error");
            validBets = false;
            break;
          }
        }

        if (validBets) {
          validBets = true;
          //this.betSlipError = "";
          this.appStore.hideMessage();
        } else {
          validBets = false;
        }
      }
    } else {
      validBets = true;
      // this.betSlipError = ""
      this.appStore.hideMessage();
    }
    // check stake
    if (validBets) {
      // this.betSlipError = "";
      this.appStore.hideMessage();
      if (this.betSlipItems.hasOwnProperty('backBets') && this.betSlipItems.backBets.length > 0) {
        for (var bet of this.betSlipItems.backBets) {
          var minStake = bet.minStake > 0 ? bet.minStake : 1;
          var maxStake = bet.maxStake > bet.minStake && bet.maxStake > 0 ? bet.maxStake : 10000000; //100000;
          if ((bet.userStake < minStake && bet.userStake > maxStake) || bet.userStake === "") {
            validBets = false;
            break;
          }
        }
      }
      if (this.betSlipItems.hasOwnProperty('layBets') && this.betSlipItems.layBets.length > 0) {
        for (var bet of this.betSlipItems.layBets) {
          var minStake = bet.minStake > 0 ? bet.minStake : 1;
          var maxStake = bet.maxStake > bet.minStake && bet.maxStake > 0 ? bet.maxStake : 10000000; //100000;
          if ((bet.userStake < minStake && bet.userStake > maxStake) || bet.userStake === "") {
            validBets = false;
            break;
          }
        }
      }
      if (validBets) {
        this.isValidBets = true;
      } else {
        this.isValidBets = false;
      }
    } else {
      this.isValidBets = false
    }
  }
  /**
  * this method is used to remove the invalid local bets.
  */
  validateLocalBets() {
    var newLocalItems = {
      backBets: [],
      layBets: []
    }
    var betSlipItems = JSON.parse(localStorage.getItem('betslip'));
    if (betSlipItems && betSlipItems.hasOwnProperty('backBets')) {
      for (var bet of betSlipItems.backBets) {
        var isFound = false;
        for (var bBet of this.betSlipItems.backBets) {
          if (bet.eventId === bBet.eventId &&
            bet.marketId === bBet.marketId &&
            bet.selectionId === bBet.selectionId) {
            isFound = true;
          }
        }
        if (isFound) {
          newLocalItems.backBets.push(bet)
        }
      }
    }
    if (betSlipItems && betSlipItems.hasOwnProperty('layBets')) {
      for (var bet of betSlipItems.layBets) {
        var isFound = false;
        for (var bBet of this.betSlipItems.backBets) {
          if (bet.eventId === bBet.eventId &&
            bet.marketId === bBet.marketId &&
            bet.selectionId === bBet.selectionId) {
            isFound = true;
          }
        }
        if (isFound) {
          newLocalItems.layBets.push(bet)
        }
      }
    }
    localStorage.setItem('betslip', JSON.stringify(newLocalItems));
  }


  updateOpenbets(appStore) {
    var token = cookie.load('UTocken')
    var params = {
      "userToken": cookie.load('UTocken'),
      "layBets": [],
      "backBets": []
    }
    this.isBetUpdating = true;
    for (var betType in this.betSlipItems) {
      if (this.betSlipItems[betType] && Array.isArray(this.betSlipItems[betType]) &&
        this.betSlipItems[betType].length > 0) {
        for (var bet of this.betSlipItems[betType]) {
          var objBet = {
            "betId": bet.betId,
            "odds": Number(bet.userOdds),
            "stake": Number(bet.userStake),
            "returns": Number(bet.returns),
            "betfairBetId": bet.betFairBetId,
            "clientMarketId": bet.clMarketId,
            "eventId": appStore.selectedOpenBetsEvent.eventId,
            "outcomeId": bet.selectionId
          }
          params[betType].push(objBet);
        }
      }
    }
    if (this.validateOpenBets(params)) {
      apiCaller(masterPage.updateOpenBets, 'POST', params).then(response => {
        if (response && response.data) {
          if (response.data[0].betUpdated) {
            appStore.selectedOutcomeUniqueId = 0;
            this.clearBetslip();
            appStore.getUserBalanceDetails();
          }
          else {
            let error = response.data[0].reasonForFailure;
            this.appStore.showMessage(error, "error");
            //this.betSlipError = response.data.reasonForFailure;
          }
        }
        this.isBetUpdating = false;
      }).catch(ex => {
        this.isBetUpdating = false;
      });
    }
  }

  validateOpenBets(bets) {
    for (var bet of bets.backBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    for (var bet of bets.layBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    return true;
  }
  updateBetSlipOdds(data) {
    var market = data;
    for (var betType in this.betSlipItems) {
      if (this.betSlipItems[betType] && this.betSlipItems[betType].length > 0) {
        for (var bet of this.betSlipItems[betType]) {
          if (bet.clMarketId === market.marketId) {
            if(market?.runners){
            for (var outcome of market.runners) {
              if (outcome.selectionId === Number(bet.clOutcomeId) && !bet.isOddsChange) {
                var changedBackOdds = 0;
                var backOddsIndex = 0
                if (bet.betType === "BO") {
                  if(outcome?.ex?.availableToBack){
                  for (var backSelection of outcome.ex.availableToBack) {
                    if (backOddsIndex < 1) {
                      if (backSelection.price > changedBackOdds) {
                        changedBackOdds = backSelection.price;
                      }
                    }
                    backOddsIndex++;
                  }
                }
                  
                  if (changedBackOdds > 1 && changedBackOdds > bet.userOdds) {
                    //  self.checkOddsUpdate(self.props.outcome.backOdds,changedBackOdds,'BO');
                    //  self.props.outcome.backOdds = changedBackOdds;
                    bet.userOdds = changedBackOdds;
                  } else if (changedBackOdds < 1) {
                    //  self.props.outcome.backOdds = '-';
                    bet.userOdds = '-';
                  }
                }
                else {
                  var changedLayOdds = 0;
                  var layOddsIndex = 0
                  if(outcome?.ex?.availableToLay){
                  for (var laySelection of outcome.ex.availableToLay) {
                    if (layOddsIndex < 1) {
                      if (layOddsIndex === 0) {
                        changedLayOdds = laySelection.price
                      } else if (laySelection.price < changedLayOdds) {
                        changedLayOdds = laySelection.price
                      }
                    }
                    layOddsIndex++;
                  }
                }
                  if (changedLayOdds > 1 && changedLayOdds < bet?.userOdds) {
                    //  self.checkOddsUpdate(self.props.outcome.layOdds,changedLayOdds,'LO');
                    //  self.props.outcome.layOdds = changedLayOdds;
                    bet.userOdds = changedLayOdds;
                  } else if (changedLayOdds < 1) {
                    //  self.props.outcome.layOdds = '-';
                    bet.userOdds = '-';
                  }
                }
              }
            }
          }
          }
        }
        localStorage.setItem("betslip", JSON.stringify(this.betSlipItems));
      }
    }
  }

}

export default BetSlipComponentStore;
