import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import Sportspage from './sportsPage';
import SportspageStore from './sportsPageStore';
import Leaguepage from './leaguePage';
import EventView from '../../sharedComponents/eventview/eventView';
import EventViewStore from '../../sharedComponents/eventview/eventViewStore';

const sportsPageStore = new SportspageStore();
const eventViewStore = new EventViewStore();
class SportspageLayout extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.appStore.currentPath = "sports";
        this.props.appStore.clearBetslip();
    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }

    onInitialFetch(groups) {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        const { appStore, isLoggedIn, pLStatusChanged } = this.props;
        var plStatus = pLStatusChanged;
        return (

            <Switch>
                <Route exact path="/sports/:sportName/:sportId/:tab?" render={(props) => <Sportspage {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    pageId={1} />} />
                <Route exact path="/sports/:sportName/:sportId/league/:leagueId/:leagueName" render={(props) => <Leaguepage {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn} />} />
                <Route exact path="/sports/:sportName/:sportId/event/:eventId" render={(props) => <EventView {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    eventViewStore={eventViewStore}
                    pLStatusChanged={plStatus}

                    eventId={appStore.eventViewId}/>} />
            </Switch>
        );
    }
}

export default observer(SportspageLayout);
